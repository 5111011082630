import { Component, forwardRef, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Country, State, City } from 'country-state-city';
// Import Interfaces`
import { ICountry, IState, ICity } from 'country-state-city';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  ControlContainer,
  NG_VALIDATORS
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface AddressFormValues {
  street: string;
  street2: string;
  zipCode: string;
  country: any;
  state: string;
  city: string;
}
@Component({
  selector: 'app-address-form',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})

export class AddressComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterContentInit {

  form: UntypedFormGroup;
  parentForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  countries: ICountry[];
  states: IState[];
  cities: ICity[];
  selectedCountry: ICountry;
  selectedState: IState;
  selectedCity: ICity;

  countrySelectionCtrl = new UntypedFormControl();
  stateSelectionCtrl = new UntypedFormControl();
  citySelectionCtrl = new UntypedFormControl();
  filteredCountries: Observable<any>;
  filteredStates: Observable<any>;
  filteredCities: Observable<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    this.countries = Country.getAllCountries().filter(country =>
      country.name.includes('United States') || country.name.includes('France'));
    this.countries.pop();
    this.parentForm = <UntypedFormGroup>this.controlContainer.control;
    this.form = this.createAddressForm();
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();

        // check init choice for edit (have a value and id not calculated)
        if (
          value.country !== '' && value.state !== '' && value.city !== '' &&
          !this.selectedCountry && !this.selectedState
        ) {
          this.selectedCountry = this.getCountryByName(value.country);
          if (this.selectedCountry) {
            this.selectedState = this.getStateByName(
              this.selectedCountry.isoCode,
              value.state
            );
          }
          if (this.selectedState) {
            this.cities = City.getCitiesOfState(
              this.selectedState.countryCode,
              this.selectedState.isoCode
            );
          }
        }
      })
    );

    this.filteredCountries = this.countrySelectionCtrl.valueChanges.pipe(
      startWith(''),
      map((country: string | null) => this._filterCountries(country),
      )
    );
  }

  ngAfterContentInit() {
    //load state list if country is already select 
    if(this.form.value.country != '' && this.value.state == ''){
      this.getCountrySelected(this.form.value.country);
    }
  }

  /**
   * Create form
   *
   * @returns {FormGroup}
   */
  createAddressForm(): UntypedFormGroup {
    return this.formBuilder.group({
      street: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð \',.-]*')]],
      street2: ['', Validators.pattern('[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð \',.-]*')],
      zipCode: ['', [Validators.required, Validators.maxLength(5), Validators.pattern('[0-9]*')]],
      country: [this.countrySelectionCtrl, Validators.required],
      state: [this.stateSelectionCtrl, Validators.required],
      city: [this.citySelectionCtrl, Validators.required]
    });
  }

  get street() {
    return this.form.get('street');
  }

  get street2() {
    return this.form.get('street2');
  }

  get zipCode() {
    return this.form.get('zipCode');
  }

  get country() {
    return this.form.get('country');
  }

  get state() {
    return this.form.get('state');
  }

  get city() {
    return this.form.get('city');
  }

  get value(): AddressFormValues {
    return this.form.value;
  }

  set value(value: AddressFormValues) {
    this.form.setValue(value);
    this.countrySelectionCtrl.setValue(value.country);
    this.stateSelectionCtrl.setValue(value.state);
    this.citySelectionCtrl.setValue(value.city);

    this.onChange(value);
    this.onTouched();
  }

  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  getCountryByName(name: string): ICountry {
    this.countries = Country.getAllCountries().filter(country =>
      country.name.includes('United States') || country.name.includes('France'));
    this.countries.pop();
    return this.countries.filter((country) => {
      return country.name === name;
    })[0];
  }

  getStateByName(countryCode: string, name: string): IState {
    this.states = State.getStatesOfCountry(countryCode).filter((state) => {
      return City.getCitiesOfState(
        state.countryCode,
        state.isoCode
      ).length > 0;
    });
    return this.states.filter((state) => {
      return state.name === name;
    })[0];
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { address: { valid: false } };
  }

  private _filterCountries(value: any): any[] {
    let val = value
    if (typeof (value) != 'string') {
      val = value[0];
    }
    const filterValue = val.toLowerCase();
    return this.countries.filter((country) => country.name.toLowerCase().includes(filterValue));
  }

  private _filterStates(value: any): any[] {
    let val = value
    if (typeof (value) != 'string') {
      val = value[0];
    }
    const filterValue = val.toLowerCase();
    return this.states.filter((state) => state.name.toLowerCase().includes(filterValue));
  }

  private _filterCities(value: any): any[] {
    let val = value
    if (typeof (value) != 'string') {
      val = value[0];
    }
    const filterValue = val.toLowerCase();
    return this.cities.filter((city) => city.name.toLowerCase().includes(filterValue));
  }

  getCountrySelected(countrySelected: string): void {
    this.stateSelectionCtrl.setValue(null);
    this.citySelectionCtrl.setValue(null);
    if (countrySelected) {
      this.filteredStates = this.stateSelectionCtrl.valueChanges.pipe(
        startWith(''),
        map((state: string | null) => this._filterStates(state),
        )
      );

      this.form.get('country').setValue(countrySelected);
      this.selectedCountry = this.getCountryByName(countrySelected);
      // filter state to avoid state with empty city list
      this.states = State.getStatesOfCountry(this.selectedCountry.isoCode).filter((state) => {
        return City.getCitiesOfState(
          state.countryCode,
          state.isoCode
        ).length > 0;
      });
      this.cities = [];

    }
  }

  getStateSelected(stateSelected: string): void {
    this.citySelectionCtrl.setValue(null);
    if (stateSelected) {
      this.filteredCities = this.citySelectionCtrl.valueChanges.pipe(
        startWith(''),
        map((city: string | null) => this._filterCities(city),
        )
      );

      this.form.get('state').setValue(stateSelected);
      this.selectedState = this.getStateByName(
        this.selectedCountry.isoCode,
        stateSelected
      );
      this.cities = City.getCitiesOfState(
        this.selectedState.countryCode,
        this.selectedState.isoCode
      );
    }
  }

  getCitySelected(citySelected: string): void {
    if (citySelected) {
      this.cities = City.getCitiesOfState(
        this.selectedState.countryCode,
        this.selectedState.isoCode
      );
    }
    this.form.get('city').setValue(citySelected);
  }

  onKey(event: any) { // without type info
    this.form.get('city').setValue(event.target.value);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
