import { DefaultThresholdService } from '../../../service/default-threshold.service';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SeverityService } from '../../../service/severity.service';
import { ActionCategoryService } from '../../../service/action-category.service';
import { PredefinedActionsService } from '../../../service/predefined-actions.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { DefaultThreshold } from 'app/shared/model/default-threshold';
import { ConnectorService } from '../../../../shared/auth/connector.service';

@Component({
  selector: 'app-physician-default-threshold',
  templateUrl: './physician-default-threshold.component.html',
  styleUrls: ['./physician-default-threshold.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PhysicianDefaultThresholdComponent implements OnInit {
  @Input() measurementType;
  @Input() currentUser: string;

  defaultThreshold: DefaultThreshold;
  defaultThresholdForm: UntypedFormGroup;

  userConnected;

  severities = [];
  actionCategories = [];
  predefinedActions: [];

  displaySaveBtn = false;
  displayCreateBtn = true;
  isExpanded = false;
  displayAddThresholdBtn = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private defaultThresholdService: DefaultThresholdService,
    private severityService: SeverityService,
    private connectorService: ConnectorService,
    private actionCategoryService: ActionCategoryService,
    private predefinedActionsService: PredefinedActionsService,
    private _formBuilder: UntypedFormBuilder,
    private _matSnackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.connectorService.getFullUser.subscribe(user => {
      this.userConnected = user;
    });
  }

  ngOnInit(): void {
    this.getSeverities();
    this.getActionCategory();
    this.getPredefinedAction(this.currentUser);
    this.defaultThresholdForm = this.createDefaultThresholdForm();
    this.displayDefaultThreshold();
  }

  createDefaultThresholdForm(): UntypedFormGroup {
    return this._formBuilder.group({
      defaultThresholds: this._formBuilder.array([])
    });
  }

  displayDefaultThreshold() {
    this.defaultThresholdService.getPhysicianDefaultThresholds()
      .then(defaultThresholds => {
        defaultThresholds.sort(this.sortThresholds)
        defaultThresholds.map(defaultThreshold => {
          // defaultThreshold.sort(this.sortThresholds);

          if (defaultThreshold.DefaultThreshold.MeasurementTypeId == this.measurementType.id) {
            const threshold = defaultThreshold.DefaultThreshold
            this.defaultThresholds().push(this.prepareForDisplayDefaultThreshold(threshold.limitLow, threshold.limitHigh, threshold.SeverityId, threshold.action, threshold.custom))
            this.displaySaveBtn = true;
            this.displayCreateBtn = false;
            this.toggleDisplayAddThresholdBtn();
          }
        });
      });
  }

  sortThresholds(a, b) {
    if (a.DefaultThreshold.limitLow < b.DefaultThreshold.limitLow) {
      return -1;
    }
    if (a.DefaultThreshold.limitLow > b.DefaultThreshold.limitLow) {
      return 1;
    }
    return 0;
  }

  /**
   * Add threshold
   */
  addDefaultThreshold(): void {
    const data = this.defaultThresholdForm.getRawValue();

    data.EntityId = this.userConnected.id;
    data.MeasurementTypeId = this.measurementType.id;
    data.type = 'physician';

    data.defaultThresholds.forEach((defaultThreshold) => {
      if (defaultThreshold.action == '') {
        defaultThreshold.action = null;
      }
      defaultThreshold.custom = true;
    });

    this.defaultThresholdService
      .addDefaultThreshold(data)
      .then(() => {
        // Trigger the subscription with new data
        this.defaultThresholdService.onDefaultThresholdChanged.next(data);

        // Show the success message
        this._matSnackBar.open('Default thresholds saved', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000)
      })
      .catch((error) => {
        // Show the success message
        this._matSnackBar.open('Default thresholds error', error.error.message, {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  getSeverities(): void {
    this.severityService.getSeverities().then((arg) => (this.severities = arg));
  }

  getActionCategory(): void {
    this.actionCategoryService.getActionCategories()
      .then(arg => {
        this.actionCategories = arg;
      });
  }

  getPredefinedAction(UserId): void {
    this.predefinedActionsService.getPredefinedActionsByUser(this.userConnected.id).then((arg) => {
      this.predefinedActions = arg;
    });
  }

  defaultThresholds(): UntypedFormArray {
    return this.defaultThresholdForm.get('defaultThresholds') as UntypedFormArray;
  }

  prepareForDisplayDefaultThreshold(limitLow, limitHigh, SeverityId, action, custom) {
    return this._formBuilder.group({
      limitLow: limitLow,
      limitHigh: limitHigh,
      SeverityId: SeverityId,
      action: action,
      custom: custom
    });
  }

  newDefaultThreshold(): UntypedFormGroup {
    return this._formBuilder.group({
      limitLow: '',
      limitHigh: '',
      SeverityId: '',
      action: '',
      custom: true
    });
  }

  createDefaultThreshold(): void {
    const nbThreshold = 5;
    for (let i = 0; i < nbThreshold; i++) {
      this.defaultThresholds().push(this._formBuilder.group({
        limitLow: '',
        limitHigh: '',
        SeverityId: '',
        action: '',
        custom: true
      }));
    }

    this.defaultThresholds().controls[0].get('SeverityId').setValue(4)
    this.defaultThresholds().controls[1].get('SeverityId').setValue(3)
    this.defaultThresholds().controls[2].get('SeverityId').setValue(2)
    this.defaultThresholds().controls[3].get('SeverityId').setValue(3)
    this.defaultThresholds().controls[4].get('SeverityId').setValue(4)

    this.displaySaveBtn = true;
    this.displayCreateBtn = false;
    this.isExpanded = true;

    this.toggleDisplayAddThresholdBtn();
  }

  addNewDefaultThreshold(): void {
    this.defaultThresholds().push(this.newDefaultThreshold());
    this.toggleDisplayAddThresholdBtn();
  }

  removeNewDefaultThreshold(i: number): void {
    this.defaultThresholds().removeAt(i);
    this.toggleDisplayAddThresholdBtn();
  }

  toggleDisplayAddThresholdBtn(): void {
    if (this.defaultThresholds().length == 5) {
      this.displayAddThresholdBtn = false;
    } else {
      this.displayAddThresholdBtn = true;
    }
  }
}
