import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Log } from '../model/log';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogService implements Resolve<any> {
  log: Log;
  logs: any[] = [];
  routeParams: any;
  onLogChanged: BehaviorSubject<any>;
  onLogsChanged: BehaviorSubject<any>;
  public pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  id;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    this.onLogsChanged = new BehaviorSubject({});
    this.onLogChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([]).then((response) => {
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Logs ---------------------------
  /**
   * Get Logs
   * @param logCategory
   * @returns {Promise<any>}
   */
  getLogs(logCategory: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.logUrl + 'oneM2M' + '/' + logCategory + '/' + env.env)
        .subscribe((response: any) => {
          const logs = response.map(res => {
            // remove unnecessary keys
            if (res.updatedAt) {
              delete res.updatedAt;
            }
            // convert init data to string
            if (res.hubId) {
              res.hubId = res.hubId.toString();
            }
            if (res.createdAt) {
              res.createdAt = new Date(res.createdAt);
            }
            if (res.sensorList || res.oneM2MHeaders || res.oneM2MUrl || res.heartbeatDate || res.jsonMeasurement || res.ciName || res.dataPushed || res.response) {
              res.extra = true;
            }
            if (res.status >= 100 && res.status < 300) {
              res.color = 'green';
            } else if (res.status >= 300 && res.status < 400) {
              res.color = '#00C1FF';
            } else if (res.status >= 400 && res.status < 500) {
              res.color = 'orange';
            } else {
              res.color = 'red';
            }
            res.status = res.status.toString();
            res.isExpanded = false;
            return res;
          });
          resolve(logs);
        }, reject);
    });
  }
}
