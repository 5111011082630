import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ActionService } from 'app/shared/service/action.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-stat-flat-chart-dashboard',
  templateUrl: './stat-flat-chart-dashboard.component.html',
  styleUrls: ['./stat-flat-chart-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class StatFlatChartDashboardComponent implements OnInit {
  @Input()
  action: any;

  @Output()
  filterByToParent = new EventEmitter<any>();

  tasks: boolean;
  task: boolean;
  outOfNorm: boolean;
  touchTime: boolean;
  normal: boolean;
  transmissionMissing: boolean;
  medicalStaff: boolean;
  filterBy;
  count = 0;
  title = '';

  constructor(
    private actionService: ActionService,
    private _matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.tasks = false;
    this.task = false;
    this.outOfNorm = true;
    this.normal = true;
    this.touchTime = true;
    this.transmissionMissing = true;
    this.medicalStaff = true;
    this.filterBy = {
      outOfNorm: this.outOfNorm,
      touchTime: this.touchTime,
      transmissionMissing: this.transmissionMissing,
      medicalStaff: this.medicalStaff,
      normal: this.normal,
      new: this.task,
      myTask: this.tasks,
    }
    this.getCount();
  }

  changeFilter(title : string) {
    switch (title) {
      case 'Abnormal':
        this.filterBy.outOfNorm = true;
        this.filterBy.transmissionMissing = false;
        this.filterBy.touchTime = false;
        this.filterBy.normal = false;
        this.filterBy.medicalStaff = false;
        this.filterByToParent.emit(this.filterBy);
        break;

      case 'Transmission Missing':
        this.filterBy.outOfNorm = false;
        this.filterBy.transmissionMissing = true;
        this.filterBy.touchTime = false;
        this.filterBy.normal = false;
        this.filterBy.medicalStaff = false;
        this.filterByToParent.emit(this.filterBy);
        break;

      case '20 min communication missing':
        this.filterBy.outOfNorm = false;
        this.filterBy.transmissionMissing = false;
        this.filterBy.touchTime = true;
        this.filterBy.normal = false;
        this.filterBy.medicalStaff = false;
        this.filterByToParent.emit(this.filterBy);
        break;

      case 'Normal':
        this.filterBy.outOfNorm = false;
        this.filterBy.transmissionMissing = false;
        this.filterBy.touchTime = false;
        this.filterBy.normal = true;
        this.filterBy.medicalStaff = false;
        this.filterByToParent.emit(this.filterBy);
        break;

      case 'From Medical Staff':
        this.filterBy.outOfNorm = false;
        this.filterBy.transmissionMissing = false;
        this.filterBy.touchTime = false;
        this.filterBy.normal = false;
        this.filterBy.medicalStaff = true;
        this.filterByToParent.emit(this.filterBy);
        break;

      default:
    }
  }

  getCount() {
    switch (this.action) {
      case 'outOfNorm':
        this.count = this.actionService.countOutOfNorm.value;
        // this.actionService.countOutOfNorm.subscribe(count => this.count = count);
        this.title = 'Abnormal';
        break;
      case 'transmissionMissing':
        this.count = this.actionService.countTransmissionMissing.value;
        // this.actionService.countTransmissionMissing.subscribe(count => this.count = count);
        this.title = 'Transmission Missing';
        break;
      case 'touchTime':
        this.count = this.actionService.countTouchTime.value;
        // this.actionService.countTouchTime.subscribe(count => this.count = count);
        this.title = '20 min communication missing';
        break;
      case 'normal':
        this.count = this.actionService.countNormal.value;
        // this.actionService.countNormal.subscribe(count => this.count = count);
        this.title = 'Normal';
        break;
      case 'medicalStaff':
        this.count = this.actionService.countMedicalStaff.value;
        // this.actionService.countMedicalStaff.subscribe(count => this.count = count);
        this.title = 'From Medical Staff';
        break;
    }
  }

}
