import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Measurement } from '../model/measurement';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class MeasurementService {
  measurement: Measurement;
  measurements: any[];
  routeParams: any;
  onMeasurementChanged: BehaviorSubject<any>;
  onMeasurementsChanged: BehaviorSubject<any>;
  id;
  onFilterChanged: Subject<any>;
  filterBy: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.onMeasurementsChanged = new BehaviorSubject({});
    this.onMeasurementChanged = new BehaviorSubject({});
    this.onFilterChanged = new Subject();
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param   {ActivatedRouteSnapshot} route
   * @param   {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getMeasurement(this.routeParams.id)]).then((response) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getMeasurement(this.routeParams.id);
          });
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getMeasurements()]).then((response) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getMeasurements();
          });
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Measurements ---------------------------
  /**
   * Get measurements
   *
   * @returns {Promise<any>}
   */
  getMeasurements(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'measurements').subscribe((response: any) => {
        this.measurements = response;
        if (this.filterBy && Array.isArray(this.filterBy) && this.filterBy.length > 0) {
          this.measurements = this.measurements.filter(data => this.filterBy.includes(data.MeasurementTypeId)
          );
        }

        let measurementSorted = [];
        this.measurements.map(measurementA => {
          if (measurementA.MeasurementTypeId == 2) {

            this.measurements.map(measurementB => {
              if (measurementB.MeasurementTypeId == 3
                && measurementA.dateTimeTaken == measurementB.dateTimeTaken
                && measurementA.UserId == measurementB.UserId
                && measurementA.hash == measurementB.hash
              )
                this.measurements.map(measurementC => {
                  if (measurementC.MeasurementTypeId == 4
                    && measurementA.dateTimeTaken == measurementC.dateTimeTaken
                    && measurementA.UserId == measurementC.UserId
                    && measurementA.hash == measurementC.hash
                  ) {

                    let severity: any;
                    let severityId: any;
                    if ((measurementA.SeverityId >= measurementB.SeverityId) && (measurementA.SeverityId >= measurementC.SeverityId)) {
                      severity = measurementA.Severity
                    } else if ((measurementB.SeverityId >= measurementA.SeverityId) && (measurementB.SeverityId >= measurementC.SeverityId)) {
                      severity = measurementB.Severity
                    } else if ((measurementC.SeverityId >= measurementA.SeverityId) && (measurementC.SeverityId >= measurementB.SeverityId)) {
                      severity = measurementC.Severity
                    }
                    severityId = measurementA.SeverityId + measurementB.SeverityId + measurementC.SeverityId;

                    measurementSorted.push({
                      MeasurementTypeId: 0,
                      Severity: severity,
                      SeverityId: severityId,
                      systolic: measurementA,
                      diastolic: measurementB,
                      pulse: measurementC,
                      dateTimeTaken: measurementA.dateTimeTaken
                    })
                  }
                })
            })
          } else if (measurementA.MeasurementTypeId == 6) {
            this.measurements.map(measurementB => {
              if (measurementB.MeasurementTypeId == 4
                && measurementA.dateTimeTaken == measurementB.dateTimeTaken
                && measurementA.UserId == measurementB.UserId
                && measurementA.hash == measurementB.hash
              ) {

                let severity: any;
                let severityId: any;
                if (measurementA.SeverityId >= measurementB.SeverityId) {
                  severity = measurementA.Severity
                } else {
                  severity = measurementB.Severity
                }
                severityId = (measurementA.SeverityId + measurementB.SeverityId) * 1.5;

                measurementSorted.push({
                  MeasurementTypeId: -1,
                  Severity: severity,
                  SeverityId: severityId,
                  spo2: measurementA,
                  pulse: measurementB,
                  dateTimeTaken: measurementA.dateTimeTaken
                })
              }
            })
          } else if (![2, 3, 4, 6].includes(measurementA.MeasurementTypeId)) {
            measurementA.SeverityId = (measurementA.SeverityId * 3) - 1;
            measurementSorted.push(
              measurementA
            )
          }
        })

        measurementSorted.map(measurement => {
          measurement.dateTime = moment.utc(measurement.dateTimeTaken).format('MM/DD/YY hh:mm:ss A');
        })

      

        this.measurements = measurementSorted;
        this.onMeasurementsChanged.next(this.measurements);

        resolve(this.measurements);
      }, reject);
    });
  }

  // ------------------------------------------------ Measurement ---------------------------
  /**
   * Get measurement for one patient
   *
   * @returns {Promise<any>}
   */
  getMeasurement(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'measurements/user/' + id)
        .subscribe((response: any) => {
          this.measurements = response;
          if (this.filterBy && Array.isArray(this.filterBy) && this.filterBy.length > 0) {
            this.measurements = this.measurements.filter(data => this.filterBy.includes(data.MeasurementTypeId)
            );
          }

          let measurementSorted = [];
          this.measurements.map(measurementA => {
            if (measurementA.MeasurementTypeId == 2) {

              this.measurements.map(measurementB => {
                if (measurementB.MeasurementTypeId == 3
                  && measurementA.dateTimeTaken == measurementB.dateTimeTaken
                  && measurementA.UserId == measurementB.UserId
                  && measurementA.hash == measurementB.hash
                )
                  this.measurements.map(measurementC => {
                    if (measurementC.MeasurementTypeId == 4
                      && measurementA.dateTimeTaken == measurementC.dateTimeTaken
                      && measurementA.UserId == measurementC.UserId
                      && measurementA.hash == measurementC.hash
                    ) {

                      let severity: any;
                      let severityId: any;
                      if ((measurementA.SeverityId >= measurementB.SeverityId) && (measurementA.SeverityId >= measurementC.SeverityId)) {
                        severity = measurementA.Severity
                      } else if ((measurementB.SeverityId >= measurementA.SeverityId) && (measurementB.SeverityId >= measurementC.SeverityId)) {
                        severity = measurementB.Severity
                      } else if ((measurementC.SeverityId >= measurementA.SeverityId) && (measurementC.SeverityId >= measurementB.SeverityId)) {
                        severity = measurementC.Severity
                      }
                      severityId = measurementA.SeverityId + measurementB.SeverityId + measurementC.SeverityId;

                      measurementSorted.push({
                        MeasurementTypeId: 0,
                        Severity: severity,
                        SeverityId: severityId,
                        systolic: measurementA,
                        diastolic: measurementB,
                        pulse: measurementC,
                        dateTimeTaken: measurementA.dateTimeTaken
                      })
                    }
                  })
              })
            } else if (measurementA.MeasurementTypeId == 6) {
              this.measurements.map(measurementB => {
                if (measurementB.MeasurementTypeId == 4
                  && measurementA.dateTimeTaken == measurementB.dateTimeTaken
                  && measurementA.UserId == measurementB.UserId
                  && measurementA.hash == measurementB.hash
                ) {

                  let severity: any;
                  let severityId: any;
                  if (measurementA.SeverityId >= measurementB.SeverityId) {
                    severity = measurementA.Severity
                  } else {
                    severity = measurementB.Severity
                  }
                  severityId = (measurementA.SeverityId + measurementB.SeverityId) * 1.5;

                  measurementSorted.push({
                    MeasurementTypeId: -1,
                    Severity: severity,
                    SeverityId: severityId,
                    spo2: measurementA,
                    pulse: measurementB,
                    dateTimeTaken: measurementA.dateTimeTaken
                  })
                }
              })
            } else if (![2, 3, 4, 6].includes(measurementA.MeasurementTypeId)) {
              measurementA.SeverityId = (measurementA.SeverityId * 3) - 1;
              measurementSorted.push(
                measurementA
              )
            }
          })

          measurementSorted.map(measurement => {
            measurement.dateTime = moment.utc(measurement.dateTimeTaken).format('MM/DD/YY hh:mm:ss A');
          })

          this.measurements = measurementSorted;
          this.onMeasurementsChanged.next(this.measurements);

          resolve(this.measurements);
        }, reject);
    });
  }

  /**
   * Get measurement for one patient and filtered by date
   *
   * @returns {Promise<any>}
   */
  getMeasurementFilteredByDate(days: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'measurements/user/filter/' + this.routeParams.id + '/' + days)
        .subscribe((response: any) => {
          this.measurements = response;
          if (this.filterBy && Array.isArray(this.filterBy) && this.filterBy.length > 0) {
            this.measurements = this.measurements.filter(data => this.filterBy.includes(data.MeasurementTypeId)
            );
          }

          let measurementSorted = [];
          this.measurements.map(measurementA => {
            if (measurementA.MeasurementTypeId == 2) {

              this.measurements.map(measurementB => {
                if (measurementB.MeasurementTypeId == 3
                  && measurementA.dateTimeTaken == measurementB.dateTimeTaken
                  && measurementA.UserId == measurementB.UserId
                  && measurementA.hash == measurementB.hash
                )
                  this.measurements.map(measurementC => {
                    if (measurementC.MeasurementTypeId == 4
                      && measurementA.dateTimeTaken == measurementC.dateTimeTaken
                      && measurementA.UserId == measurementC.UserId
                      && measurementA.hash == measurementC.hash
                    ) {

                      let severity: any;
                      let severityId: any;
                      if ((measurementA.SeverityId >= measurementB.SeverityId) && (measurementA.SeverityId >= measurementC.SeverityId)) {
                        severity = measurementA.Severity
                      } else if ((measurementB.SeverityId >= measurementA.SeverityId) && (measurementB.SeverityId >= measurementC.SeverityId)) {
                        severity = measurementB.Severity
                      } else if ((measurementC.SeverityId >= measurementA.SeverityId) && (measurementC.SeverityId >= measurementB.SeverityId)) {
                        severity = measurementC.Severity
                      }
                      severityId = measurementA.SeverityId + measurementB.SeverityId + measurementC.SeverityId;

                      measurementSorted.push({
                        MeasurementTypeId: 0,
                        Severity: severity,
                        SeverityId: severityId,
                        systolic: measurementA,
                        diastolic: measurementB,
                        pulse: measurementC,
                        dateTimeTaken: measurementA.dateTimeTaken
                      })
                    }
                  })
              })
            } else if (measurementA.MeasurementTypeId == 6) {
              this.measurements.map(measurementB => {
                if (measurementB.MeasurementTypeId == 4
                  && measurementA.dateTimeTaken == measurementB.dateTimeTaken
                  && measurementA.UserId == measurementB.UserId
                  && measurementA.hash == measurementB.hash
                ) {

                  let severity: any;
                  let severityId: any;
                  if (measurementA.SeverityId >= measurementB.SeverityId) {
                    severity = measurementA.Severity
                  } else {
                    severity = measurementB.Severity
                  }
                  severityId = (measurementA.SeverityId + measurementB.SeverityId) * 1.5;

                  measurementSorted.push({
                    MeasurementTypeId: -1,
                    Severity: severity,
                    SeverityId: severityId,
                    spo2: measurementA,
                    pulse: measurementB,
                    dateTimeTaken: measurementA.dateTimeTaken
                  })
                }
              })
            } else if (![2, 3, 4, 6].includes(measurementA.MeasurementTypeId)) {
              measurementA.SeverityId = (measurementA.SeverityId * 3) - 1;
              measurementSorted.push(
                measurementA
              )
            }
          })

          measurementSorted.map(measurement => {
            measurement.dateTime = moment.utc(measurement.dateTimeTaken).format('MM/DD/YY hh:mm:ss A');
          })

          this.measurements = measurementSorted;
          this.onMeasurementsChanged.next(this.measurements);

          resolve(this.measurements);
        }, reject);
    });
  }
}
