import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StatService } from '../../../service/stat.service';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-stat-measurement-by-patient',
  templateUrl: './stat-measurement-by-patient.component.html',
  styleUrls: ['./stat-measurement-by-patient.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class StatMeasurementByPatientComponent implements OnInit {
  jsonSerieArray: [{}];
  id: string;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  // showLegend = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  autoscale = true;

  colorScheme = {
    domain: [
      '#647c8a',
      '#3f51b5',
      '#2196f3',
      '#00b862',
      '#afdf0a',
      '#a7b61a',
      '#f3e562',
      '#ff9800',
      '#ff5722',
      '#ff4514'
    ]
  };

  durations = [
    { name: 'Last 7 days', value: 7 },
    { name: 'Last 15 days', value: 15 },
    { name: 'Last 30 days', value: 30 },
    { name: 'Current month', value: 99 }
  ];
  selectedDuration = { name: 'Last 7 days', value: 7 };
  form: UntypedFormGroup;

  constructor(private statService: StatService,
              private route: ActivatedRoute,
              private _matSnackBar: MatSnackBar
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.form = new UntypedFormGroup({
      selectedDuration: new UntypedFormControl(this.selectedDuration)
    });
  }

  ngOnInit(): void {
    this.getStatMeasurementByPatient(this.id, this.selectedDuration.value);
  }

  getStatMeasurementByPatient(id: string, days: number): void {
    this.statService
      .getStatsMeasurementByPatient(id, days)
      .then((response) => {
        this.jsonSerieArray = response;
      })
      .catch((error) => {
        this._matSnackBar.open("Cannot retrieve patient's statistics", 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  getDuration(duration: any): void {
    this.getStatMeasurementByPatient(this.id, duration.value);
  }

  compareWith(item: any, selected: any): boolean {
    if (item.name === '') {
      return false;
    }
    return item.name == selected.name;
  }

}
