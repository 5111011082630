import { Pipe, PipeTransform } from '@angular/core';

/**
 * used to transform text in plural upon value
 *
 */
@Pipe({
  name: 'yesNo'
})
export class YesNo implements PipeTransform {
  transform(value: any): any {
    return value ? 'yes' : 'no';
  }
}
