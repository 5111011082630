import { Pipe, PipeTransform } from '@angular/core';

/**
 * used to transform text and add : at the end
 *
 */
@Pipe({
  name: 'doubleDot'
})
export class doubleDotPipe implements PipeTransform {
  transform(text: string): any {   
      return text + ' :';
  }
}
