import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';

import { ConnectorService } from './connector.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.getToken()) {
      const tokenizedReq = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.getToken()
        }
      });

      return next.handle(tokenizedReq).pipe(
        catchError((httpError: HttpErrorResponse) => {
          if (httpError.status === 401 && httpError.error.message === 'Authentication KO - failed -- PROBLEM TOKEN') {
            return this.handle401Error(req, next); 
          }
          return throwError(httpError);
        }),
        finalize(() => {
        })
      ) as Observable<HttpEvent<any>>;
    } else {
      return next.handle(req).pipe(
        catchError((httpError: HttpErrorResponse) => {
          if (httpError.status === 401 && httpError.error.message === 'Authentication KO - failed -- PROBLEM TOKEN') {
            return this.handle401Error(req, next); 
          }
          return throwError(httpError);
        }),
        finalize(() => {
        })
      ) as Observable<HttpEvent<any>>;
    }
  }

  private asRefreshToken() {
    if (localStorage.getItem('refresh')) {
      return true
    } else {
      return false
    }
  }

  private refreshUserToken() {
    const refreshToken = localStorage.getItem('refresh');
    return this.http.post<any>(env.apiUrl + 'users/refreshToken', { 'refresh': refreshToken }).pipe(
      tap((token) => {
        localStorage.setItem('auth', token.token);
        localStorage.setItem('refresh', token.refresh);
      })
    )
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) { 
    if (!this.isRefreshing) { 
      this.isRefreshing = true; 
      if (this.asRefreshToken()) { 
        return this.refreshUserToken().pipe( 
          switchMap((data) => { 
            this.isRefreshing = false; 
            const newReq = request.clone({ 
              setHeaders: { 
                Authorization: 'Bearer ' + this.getToken() 
              } 
            }) 
              return next.handle(newReq); 
          }), 
          catchError((error) => { 
            this.isRefreshing = false; 
            localStorage.clear() 
            this.router.navigate(['/login']); 
 
            return throwError(() => error); 
          }) 
        ); 
      } 
    } 
 
    return next.handle(request); 
  } 

  private getToken() {
    if (!!localStorage.getItem('auth')) {
      return localStorage.getItem('auth');
    } else {
      return false;
    }
  }
}
