import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment as env } from '../../../environments/environment';
import { Reports } from '../model/reports';
import { ConnectorService } from '../auth/connector.service';

@Injectable({
  providedIn: 'root'
})

export class ReportsService implements Resolve<any> {

  report: Reports;
  reports: Reports[];
  routeParams: any;
  id: string;
  onReportsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  filterBy: any;
  roleUser;
  currentUser: any;

  constructor(
    private http: HttpClient,
    private connectorService: ConnectorService,
    private route: ActivatedRoute,
  ) {
    this.onReportsChanged = new BehaviorSubject({});
    this.onFilterChanged = new Subject();
    this.roleUser = connectorService.getRole();
    this.currentUser = connectorService.getUser();
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
 * Resolver
 *
 * @param {ActivatedRouteSnapshot} route
 * @param {RouterStateSnapshot} state
 * @returns {Observable<any> | Promise<any> | any}
 */

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (state.url.includes('patients') && this.routeParams.hasOwnProperty('id')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPatientReports(route.params.id)]).then((response) => {
          resolve(response);
        }, reject);
      })
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getReports()]).then((response) => {
          resolve(response);
        }, reject);
      })
    }
  };

  // ------------------------------------------------ Report ---------------------------
  /**
   * Get Reports
   *
   * @returns {Promise<any>}
   */
  getReports(): Promise<any> {
    let url = '';
    switch (this.roleUser) {
      case env.roleEdevice:
        url = env.apiUrl + 'reports/edevice'
        break;
      case env.roleCustomer:
        url = env.apiUrl + 'reports/customer/' + this.currentUser
        break;
      case env.roleHealthCareProvider:
        url = env.apiUrl + 'reports/healthCareProvider/' + this.currentUser
        break;
      case env.rolePhysician:
        url = env.apiUrl + 'reports/physician/' + this.currentUser
        break;
      default:
        break;
    }
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          this.reports = response;
          this.onReportsChanged.next(response);
          resolve(response);
        }, reject);
    })
  };

  // ------------------------------------------------ Report Patient ---------------------------
  /**
   * Get Reports for one patient
   *
   * @returns {Promise<any>}
   */
  getPatientReports(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'reports/patient/' + id)
        .subscribe((response: any) => {
          this.reports = response;
          this.onReportsChanged.next(response);
          resolve(response);
        })
    })
  };

  // ------------------------------------------------ Download User ---------------------------
  /**
   * Download A Report for one user
   *
   * @returns {Promise<any>}
   */
  getReport(url: String, name: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'reports/getReport/' + url + '/' + name)
        .subscribe((response: any) => {
          this.reports = response;
          this.onReportsChanged.next(response);
          resolve(response);
        })
    })
  };

  // ------------------------------------------------ Download User ---------------------------
  /**
   * Download All Reports for one user as Zip
   *
   * @returns {Promise<any>}
   */
  getAllReports(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'reports/getReports/')
        .subscribe((response: any) => {
          this.reports = response;
          this.onReportsChanged.next(response);
          resolve(response);
        })
    })
  };

  // ------------------------------------------------ Download User ---------------------------
  /**
   * Download All Reports for the patient as Zip
   *
   * @returns {Promise<any>}
   */
   getAllReportsPatient(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'reports/getRep/getPatientReports/' + id)
        .subscribe((response: any) => {
          this.reports = response;
          this.onReportsChanged.next(response);
          resolve(response);
        })
    })
  };

}
