import { SpecificRightService } from './../../service/specific-right.service';
import { DescriptionComponent } from './../../../main/apps/authorizations/authorizations/description/description.component';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import {DataSource} from '@angular/cdk/table';
import {FuseUtils} from '../../../../@fuse/utils';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-specific-right',
  templateUrl: './specific-right.component.html',
  styleUrls: ['./specific-right.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SpecificRightComponent implements OnInit {

  dataSource: FilesDataSource | null;
  displayedColumns = ['route', 'menu' , 'create', 'update', 'delete', 'archive', 'manageable', 'description'];
  @Input() id: string;
  @ViewChild(MatPaginator, {static: true})
  paginator: MatPaginator;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild('filter', {static: true})
  filter: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
      private _matDialog: MatDialog,
      private specificRightService: SpecificRightService,
      private _matSnackBar: MatSnackBar
  )
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void
    {
      this.dataSource = new FilesDataSource(this.specificRightService, this.paginator, this.sort);
    }

  // -----------------------------------------------------------------------------------------------------
  // @ Methods
  // -----------------------------------------------------------------------------------------------------
  onChange(event, method): void{ 
    // Split the name of checkbox group to get the route and the roleId
    const route = event.source.name.split('-')[0];
    const roleId = event.source.name.split('-')[1];

    const specificRight = { 'UserId': this.id, 'route': route, 'property': method, 'value': event.checked };
    this.specificRightService.saveSpecificRight(specificRight)
    .then(data => {
      this._matSnackBar.open(data, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    })
    .catch(err => {
      this._matSnackBar.open('Specific Right : ' + err.statusText, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    });
  }

    askDescription(description): void {
      this._matDialog.open(DescriptionComponent, {
        panelClass: 'description-dialog',
        data: { description: description}
      });
    }
}

export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {SpecificRightService} specificRightService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
      private specificRightService: SpecificRightService,
      private _matPaginator: MatPaginator,
      private _matSort: MatSort
  )
  {
    super();
    this.filteredData = this.specificRightService.specificRights;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
    const displayDataChanges = [
      this.specificRightService.onSpecificRightsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges)
        .pipe(
            map(() => {
                  let data = this.specificRightService.specificRights.slice();

                  data = this.filterData(data);

                  this.filteredData = [...data];

                  data = this.sortData(data);

                  // Grab the page's slice of data.
                  const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                  return data.splice(startIndex, this._matPaginator.pageSize);
                }
            ));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any
  {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any)
  {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string
  {
    return this._filterChange.value;
  }

  set filter(filter: string)
  {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any
  {
    if ( !this.filter )
    {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[]
  {
    if ( !this._matSort.active || this._matSort.direction === '' )
    {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch ( this._matSort.active )
      {
        case 'id':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'route':
          [propertyA, propertyB] = [a.route, b.route];
          break;

      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void { }

}


