import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';

import { ConnectorService } from '../../../shared/auth/connector.service';
import { Router } from '@angular/router';
import { UserService } from 'app/shared/service/user.service';
import { HealthCareProviderService } from '../../../shared/service/health-care-provider.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  profileButton: string;
  logoutButton: string;
  hcpId: string;
  hcpName: string;
  roleUser;
  roleCustomer = environment.roleCustomer;

  // Private
  private _unsubscribeAll: Subject<any>;

  userName: any;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private connectorService: ConnectorService,
    private router: Router,
    private userService: UserService,
    private healthCareProviderService: HealthCareProviderService
  ) {
    if (localStorage.getItem('auth')) { 
      this.connectorService.userName.subscribe((userName) => {
        this.userName = userName;
      });
      this.connectorService.getUserConnected().subscribe((user) => {
        this.hcpId = user.user.HealthCareProviderId;
        this.roleUser = user.user.RoleId;
        if (this.hcpId !== null) {
          this.getHcp();
        }
      });
    }


    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'fr',
        title: 'Francais',
        flag: 'fr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
    if (localStorage.getItem('auth')) {
      this.connectorService.getUserConnected()
        .subscribe(user => {
          // Set the selected language from default languages
          this.selectedLanguage = _.find(this.languages, {
            id: user.user.prefLanguage
          });
          this.setTranslationButton(this.selectedLanguage)
        });
    }
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {

    const id = localStorage.getItem('currentUser');
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;
    const data = { 'prefLanguage': lang.id };
    // Use the selected language for translations
    this.userService.saveUser(data, id).then((data) => {
      // Trigger the subscription with new data
      this.connectorService.refreshToken()
        .subscribe((data) => {
          window.location.reload();
        });
      this.router.navigate([this.router.url]);
    });
  }

  setTranslationButton(lang): void {
    switch (lang.id) {
      case 'fr':
        this.profileButton = 'Mon Profil';
        this.logoutButton = 'Déconnexion';
        break
      case 'en':
        this.profileButton = 'My Profile';
        this.logoutButton = 'Logout';
        break
      default:
        this.profileButton = 'My Profile';
        this.logoutButton = 'Logout';
        break
    }
  }

  /**
   * Disconnect
   */
  disconnect(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.connectorService.logout();
    this.router.navigateByUrl('/login');
    //window.location.reload();
  }

  getHcp(): void {
    this.healthCareProviderService.getHealthCareProviderById(this.hcpId).then((arg) => {
      this.hcpName = arg.name;
    });
  }
}
