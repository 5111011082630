import { History } from './../model/history';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HistoryService {
  history: History;
  histories: any[];
  routeParams: any;
  onHistoryChanged: BehaviorSubject<any>;
  onHistoriesChanged: BehaviorSubject<any>;
  id;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private _matSnackBar: MatSnackBar,
  ) {
    this.onHistoriesChanged = new BehaviorSubject({});
    this.onHistoryChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getHistory(this.routeParams.id)]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getHistories()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Histories ---------------------------
  /**
   * Get histories
   *
   * @returns {Promise<any>}
   */
  getHistories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'histories').subscribe((response: any) => {
        this.histories = response;
        this.onHistoriesChanged.next(this.histories);
        resolve(response);
      }, reject);
    });
  }

  /**
   * Stop history
   *
   * @param history
   * @returns {Promise<any>}
   */
  stopHistory(history): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'histories/add', history)
        .subscribe((response: any) => {
          this.getHistory(history.UserId)
            .then((histories) => {
              this.onHistoriesChanged.next(this.histories);
              resolve(response);
            })
            .catch((err) => {
              catchError(this.errorService.handleError('add_history'));
            });
        }, reject);
    });
  }

  /**
   * Save history
   *
   * @param history
   * @returns {Promise<any>}
   */
  saveHistory(history, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'histories/edit/' + id, history)
        .subscribe((response: any) => {
          this.getHistory(history.UserId)
            .then((histories) => {
              this.onHistoriesChanged.next(histories);
              resolve(response);
            })
            .catch((err) => {
              catchError(this.errorService.handleError('save_history'));
            });
        }, reject);
    });
  }

  /**
   * Get history for one patient
   *
   * @returns {Promise<any>}
   */
  getHistory(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'histories/user/' + id)
        .subscribe((response: any) => {
          this.histories = response;
          this.onHistoryChanged.next(this.histories);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Export History
   *
   * @param history
   * @returns {Promise<any>}
   */
  export(): Promise<any> {
    return new Promise((resolve, reject) =>
      this.http
        .get(env.apiUrl + 'histories/export')
        .subscribe((response: any) => {
          this.histories = response;
          this.onHistoryChanged.next(response);
          resolve(response);
        }, reject)
    );
  }

  /**
   * cumulated time this month
   *
   * @param history
   * @returns {Promise<any>}
   */
  cumulatedTimeThisMonth(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'histories/distributionThisMonth')
        .subscribe((response: any) => {
          this.histories = response;
          this.onHistoriesChanged.next(this.histories);
          resolve(response);
        }, reject);
    });
  }

  /**
   * delete history
   */
  deleteHistory(id: any): void {
    this.http.delete(env.apiUrl + 'histories/delete/' + id).subscribe(() => {
      this.histories = this.histories.filter((history) => history.id != id);
      this.onHistoriesChanged.next(this.histories);
    });
  }
}
