import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Device } from '../model/device';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  device: Device;
  devices: any[];
  routeParams: any;
  onDeviceChanged: BehaviorSubject<any>;
  onDevicesChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  id;
  freeDevices;
  filterBy: any;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute
  ) {
    this.onDevicesChanged = new BehaviorSubject({});
    this.onDeviceChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.onFilterChanged = new Subject();
  }
  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && state.url.includes('devices') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getOneDevice()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else if (state.url.includes('patients') && this.routeParams.hasOwnProperty('id')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getDevice()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getDevices()]).then(([]) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getDevices();
          });
          resolve(null);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Devices ---------------------------
  /**
   * Get devices
   *
   * @returns {Promise<any>}
   */
  getDevices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'devices').subscribe((response: any) => {
        // add a property isExpanded to manage view into table  devices
        this.freeDevices = response.filter(device => device.Kittings.length === 0).length;
        this.devices = response.map(obj => ({ ...obj, isExpanded: false }));
        if (this.filterBy && this.filterBy !== 'all') {
          this.devices = this.devices.filter(
            (data) => data.CustomerId === this.filterBy
          );
        }
        this.onDevicesChanged.next(this.devices);
        resolve(response);
      }, reject);
    });
  }

  /**
   * Export device list
   *
   * @param device
   * @returns {Promise<any>}
   */
  export(): Promise<any> {
    return new Promise((resolve, reject) =>
      this.http
        .get(env.apiUrl + 'devices/export/all')
        .subscribe((response: any) => {
          resolve(response);
        }, reject)
    );
  }

  /**
   * Get devices for Select Drop Down
   *
   *
   * @returns {Promise<any>}
   */
  getDevicesFree(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'devices/unassignedDevices')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ Device ---------------------------
  /**
   * Get device for one patient
   *
   * @returns {Promise<any>}
   */
  getDevice(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'devices/user/' + this.routeParams.id)
        .subscribe((response: any) => {
          this.devices = response;
          this.onDeviceChanged.next(this.devices);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get device
   *
   * @returns {Promise<any>}
   */
  getOneDevice(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'devices/' + this.routeParams.id)
        .subscribe((response: any) => {
          this.devices = response;
          this.onDeviceChanged.next(this.devices);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Save device
   *
   * @param device
   * @returns {Promise<any>}
   */
  saveDevice(device, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'devices/edit/' + id, device)
        .subscribe((response: any) => {
          this.onDevicesChanged.next(this.devices);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add device
   *
   * @param device
   * @returns {Promise<any>}
   */
  addDevice(device): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'devices/add', device)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * delete device
   *
   *  @returns {Promise<any>}
   */
  deleteDevice(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'devices/delete/' + id)
        .subscribe((response: any) => {
          if (response.message == "Device deleted") {
            this.devices = this.devices.filter((device) => device.id != id);
            this.onDevicesChanged.next(this.devices);
          }
          resolve(response);
        }, reject);
    });
  }

  /**
   * getMacAddressFromScan
   *
   *  @returns {Promise<any>}
   */
  getMacAddressFromScan(scanModel, indexStart, scanRealized): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'devices/scan/' + scanModel + '/' + indexStart + '/' + scanRealized)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get getUnassignedDevices
   *
   * @returns {Promise<any>}
   */
  getUnassignedDevices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'devices/unassignedDevices')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * setUnfollowdevices
   *
   *  @returns {Promise<any>}
   */
  setUnfollowdevices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.devices = [];
      this.onDevicesChanged.next(this.devices);
      resolve(true);
    });
  }

}
