import { ConfirmComponent } from './main/apps/physicians/physician/confirm/confirm.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginComponent } from './main/pages/authentication/login/login.component';
import { TwofactorComponent } from './main/pages/authentication/twofactor/twofactor.component';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { TokenInterceptorService } from './shared/auth/token-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetPasswordComponent } from './main/pages/authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './main/pages/authentication/forgot-password/forgot-password.component';
import { DescriptionComponent } from './main/apps/authorizations/authorizations/description/description.component';

import { MatPaginatorTranslate } from './shared/component/paginator/paginator-trad';

const appRoutes: Routes = [
  {
    path: 'apps',
    loadChildren: () =>
      import('./main/apps/apps.module').then((m) => m.AppsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./main/pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then((m) => m.UIModule)
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./main/apps/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      )
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'twofactor',
    component: TwofactorComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'password/create/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'password/reset/:id',
    component: ResetPasswordComponent
  },
  {
    path: '**',
    redirectTo: '/apps/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [AppComponent, LoginComponent, TwofactorComponent, ResetPasswordComponent, ForgotPasswordComponent, ConfirmComponent, DescriptionComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    AppStoreModule
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  providers: [
    TokenInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { 
      provide: MatPaginatorIntl, 
      useClass: MatPaginatorTranslate
    }
  ]
})
export class AppModule {}
