import { Injectable } from '@angular/core';
import { Kitting } from '../model/kitting';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class KittingService {
  kitting: Kitting;
  kittings: any[];
  routeParams: any;
  onKittingChanged: BehaviorSubject<any>;
  onKittingsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  id: any;
  hub;
  filterBy: any;

  constructor( private http: HttpClient ) {
    this.onKittingChanged = new BehaviorSubject({});
    this.onKittingsChanged = new BehaviorSubject({});
    this.onFilterChanged = new Subject();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getKitting(),
          this.getKittingDetails(this.routeParams.id)
        ]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getKittings(), this.getHubsList()]).then(([]) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getKittings();
            this.getHubsList();
          });
          resolve(null);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Kittings ---------------------------
  /**
   * Get kittings
   *
   * @returns {Promise<any>}
   */
  getKittings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'kittings')
        .subscribe((response: any) => {
          // add a property isExpanded to manage view into table authorized devices
          this.kittings = response.map(obj =>({...obj, isExpanded: false}) );
          if (this.filterBy && this.filterBy !== 'all' ) {
            this.kittings = this.kittings.filter(
              (data) => data.Hub.CustomerId === this.filterBy
            );
          }
          this.onKittingsChanged.next(this.kittings);
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ Kitting ---------------------------
  /**
   * Get kitting
   *
   * @returns {Promise<any>}
   */
  getKitting(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onKittingChanged.next(false);
        resolve(false);
      } else {
        this.http
          .get(env.apiUrl + 'kittings/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.kitting = response;
            this.onKittingChanged.next(this.kitting);
            resolve(response);
          }, reject);
      }
    });
  }

  /**
   * Export kit
   *
   * @param kit
   * @returns {Promise<any>}
   */
   export(): Promise<any> {
    return new Promise((resolve, reject) =>
      this.http
        .get(env.apiUrl + 'kittings/export/all')
        .subscribe((response: any) => {
          resolve(response);
        }, reject)
    );
  }

  /**
   * Add kitting
   *
   * @param kitting
   * @returns {Promise<any>}
   */
  addKitting(kitting): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'kittings/add', kitting)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get getUnassignedHubs
   *
   * @returns {Promise<any>}
   */
  getHubsList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'hubs').subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  /**
   * Get getHubListFilteredBySensorTypeLimit
   *
   * @returns {Promise<any>}
   */
  getHubListFilteredBySensorTypeLimit(sensorTypeId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          env.apiUrl +
            'kittings/HubListFilteredBySensorTypeLimit/' +
            sensorTypeId
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * delete Authorized Device
   *
   *  @returns {Promise<any>}
   */
  deleteKitting(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'kittings/delete/' + id)
        .subscribe((response: any) => {
          this.kittings = this.kittings.filter(
            (device) => device.id != id
          );
          this.onKittingsChanged.next(this.kittings);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get kittings details
   *
   * @returns {Promise<any>}
   */
  getKittingDetails(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'kittings/' + id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get Device By Kitted Hubs
   *
   * @returns {Promise<any>}
   */
  getDevicesByKittedHubs(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'kittings/devicesByKittedHubs/' + id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get hub detail
   *
   * @returns {Promise<any>}
   */
   getHubDetail(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'hubs/' + id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
