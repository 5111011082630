import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { ReportsService } from '../../../../shared/service/reports.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { FuseUtils } from '../../../../../@fuse/utils';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { LocalStorageService } from '../../../service/local-storage.service';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment as env } from '../../../../../environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ReportsComponent implements OnInit {
  dataSource: FilesDataSource | null;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  displayedColumns = ['date', 'action'];
  authorization;
  pageSize;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private reportsService: ReportsService,
    private localStorageService: LocalStorageService,
    private _matSnackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
  * On init
  */
  ngOnInit(): void {
    this.dataSource = new FilesDataSource(
      this.reportsService,
      this.paginator,
      this.sort
    );

    this.getPageSize();
  }

  getPageSize(): void {
    this.pageSize = this.localStorageService.get('pageSize');
  }

  setPageSize(pageEvent): void {
    const newPageSize = pageEvent.pageSize;
    this.localStorageService.set('pageSize', newPageSize );
  }

  downloadReport(url: String, name: String): void {
    this.reportsService.getReport(url, name).then((file) => {
      const urlFile = env.apiUrl + 'uploads/eDeviceReports/' + file;
      window.open(urlFile, '_blank');
    });
  };

}
export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {HubService} hubService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private reportsService: ReportsService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();
    this.filteredData = this.reportsService.reports;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.reportsService.onReportsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = [];
        data = this.reportsService.reports.slice();

        data = this.filterData(data);

        this.filteredData = [...data];

        data = this.sortData(data);
        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'name':
          [propertyA, propertyB] = [a.serialNumber, b.serialNumber];
          break;
        case 'date':
          [propertyA, propertyB] = [a.dateStart, b.dateStart];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void { }
}

