import { DefaultThresholdService } from '../../../service/default-threshold.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MeasurementTypeService } from '../../../service/measurement-type.service';
import { environment as env } from '../../../../../environments/environment';
import { ConnectorService } from '../../../../shared/auth/connector.service';

@Component({
  selector: 'app-physician-default-thresholds',
  templateUrl: './physician-default-thresholds.component.html',
  styleUrls: ['./physician-default-thresholds.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PhysicianDefaultThresholdsComponent implements OnInit {
  measurementTypes = [];
  AllMeasurementTypes = [];
  customDefaultThresholds = [];
  currentUser: string;
  userConnected;
  roleUserConnected;

  roleCustomer = env.roleCustomer;

  displayCustomThreshold = true;

  expandCustomDefaultThresholds = true;
  expandDefaultThresholds = true;
  noCustomDefaultThreshold = true;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private defaultThresholdService: DefaultThresholdService,
    private measurementTypeService: MeasurementTypeService,
    private connectorService: ConnectorService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.connectorService.getFullUser.subscribe(user => {
      this.userConnected = user;
      this.roleUserConnected = this.connectorService.roleId;
    });
  }

  ngOnInit(): void {
    this.getDefaultThresholds();
  }

  getMeasurementType(): void {
    this.measurementTypeService.getCustomerMeasurementType(this.userConnected.CustomerId).then((arg) => {
      this.measurementTypes = arg
    });
  }

  getDefaultThresholds(): void {
    this.measurementTypeService.getCustomerMeasurementType(this.userConnected.CustomerId).then((arg) => {
      arg.map(data => {
        if (data.displayable == true) {
          this.AllMeasurementTypes.push(data)
        }
      });
      this.defaultThresholdService.getPhysicianDefaultThresholds()
        .then(defaultThresholds => {
          this.AllMeasurementTypes.map((measurementType) => {
            let tmpDefaultThresholds = [];
            defaultThresholds.map(defaultThreshold => {
              if (defaultThreshold.DefaultThreshold.MeasurementTypeId == measurementType.id && defaultThreshold.DefaultThreshold.custom == true) {
                tmpDefaultThresholds.push(defaultThreshold)
              }
            })
            if (tmpDefaultThresholds.length > 0) {
              this.customDefaultThresholds.push({
                measurementType: measurementType,
                defaultThresholds: tmpDefaultThresholds
              })
              this.noCustomDefaultThreshold = false;
            } else {
              this.measurementTypes.push(measurementType);
            }
          })
        });

    });
  }

}
