import { LogService } from '../../../service/log.service';
import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../service/local-storage.service';
import { FuseUtils } from '../../../../../@fuse/utils';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-log-formatter',
  templateUrl: './log-formatter.component.html',
  styleUrls: ['./log-formatter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0px' })),
      state('expanded', style({ height: 'auto', minHeight: '48px' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ]),
    fuseAnimations]
})
export class LogFormatterComponent implements OnInit, OnChanges  {
  currentLogList: any[] = [];
  globalLogList: any[] = [];
  allRowsExpanded = false;
  displayedColumns = [
    'id',
    'env',
    'hubId',
    'status',
    'message',
    'createdAt',
    'time',
    'color',
    'expand'
  ];

  @Input() logCategory: string;
  private filter: string;
  @Input()
  get filterValue(): string {
    return this.filter;
  }
  set filterValue(filter: string) {
    this.filter = filter;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  lowValue: number = 0;
  highValue: number = 100;
  pageSize: number;
  pageIndex: number = 0;

  constructor(
    private logService: LogService,
    private router: Router,
    private _matSnackBar: MatSnackBar,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getLogForCategory();

    this.logService.pageSize.subscribe((pageSizeVal) => {
      this.pageSize = pageSizeVal;
      this.lowValue = this.pageIndex * this.pageSize;
      this.highValue = this.lowValue + this.pageSize;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const logFilter = changes.filterValue.currentValue;
    if (logFilter) {
      this.currentLogList = FuseUtils.filterArrayByString(this.globalLogList , logFilter);
    } else {
      this.currentLogList = this.globalLogList;
    }
  }

  /**
   * Expand / Collapse
   */
  expand(): void {
    this.allRowsExpanded = !this.allRowsExpanded;
  }

  /**
   * get column header for log tab
   */
  getLogForCategory(): void {
    this.logService
      .getLogs(this.logCategory)
      .then((response) => {
        this.globalLogList = response;
        this.currentLogList = this.globalLogList;
        this.highValue = this.localStorageService.get('pageSize');
      })
      .catch((error) => {
        this._matSnackBar.open('Cannot get logs: ' + error, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.pageIndex = event.pageIndex;
    this.setPageSize(event);
    return event;
  }

  setPageSize(pageEvent): void {
    const newPageSize = pageEvent.pageSize;
    this.localStorageService.set('pageSize', newPageSize );
    this.logService.pageSize.next(newPageSize);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!data.active || data.direction === '') {
      return data;
    }

    return this.currentLogList.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (data.active) {
        case 'env':
          [propertyA, propertyB] = [a.env, b.env];
          break;
        case 'hubId':
          [propertyA, propertyB] = [a.hubId, b.hubId];
          break;
        case 'status':
          [propertyA, propertyB] = [a.status, b.status];
          break;
        case 'message':
          [propertyA, propertyB] = [a.message, b.message];
          break;
        case 'createdAt':
          [propertyA, propertyB] = [a.createdAt, b.createdAt];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (data.direction === 'asc' ? 1 : -1)
      );
    });
  }

}
