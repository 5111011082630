import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PredefinedNote} from '../model/predefined-note';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {environment as env} from '../../../environments/environment';
import {ConnectorService} from '../auth/connector.service';

@Injectable({
  providedIn: 'root'
})
export class PredefinedNotesService {
  predefinedNote: PredefinedNote;
  predefinedNotes: PredefinedNote[];
  routeParams: any;
  onPredefinedNoteChanged: BehaviorSubject<any>;
  onPredefinedNotesChanged: BehaviorSubject<any>;
  roleUser;
  UserId;

  constructor(private http: HttpClient, private connectorService: ConnectorService) {
    this.onPredefinedNoteChanged = new BehaviorSubject({});
    this.onPredefinedNotesChanged = new BehaviorSubject({});
    this.roleUser = connectorService.getRole();
    this.UserId = connectorService.getUser();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
   resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPredefinedNote()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (this.roleUser === env.roleEdevice){
          Promise.all([this.getPredefinedNotes()]).then((response) => {
            resolve(response);
          }, reject);
        } else {
          Promise.all([this.getPredefinedNotesByUser(this.UserId)]).then((response) => {
            resolve(response);
          }, reject);
        }
      });
    }
  }

  // ------------------------------------------------ ActionNotes ---------------------------
  /**
   * Get predefinedNote
   *
   * @returns {Promise<any>}
   */
  getPredefinedNotes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'predefinedNotes').subscribe((response: any) => {
        this.predefinedNotes = response;
        this.onPredefinedNotesChanged.next(this.predefinedNotes);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ ActionNote ---------------------------
  /**
   * Get predefinedNote
   *
   * @returns {Promise<any>}
   */
  getPredefinedNote(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onPredefinedNoteChanged.next(false);
        resolve(false);
      }
      else {
        this.http.get(env.apiUrl + 'predefinedNotes/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.predefinedNote = response;
            this.onPredefinedNoteChanged.next(this.predefinedNote);
            resolve(response);
          }, reject);
      }
    });
  }

  getPredefinedNotesByUser(Userid): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'predefinedNotes/user/' + Userid)
        .subscribe((response: any) => {
          this.predefinedNotes = response;
          this.onPredefinedNotesChanged.next(this.predefinedNotes);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Save predefinedNote
   *
   * @param predefinedNote
   * @returns {Promise<any>}
   */
  savePredefinedNote(predefinedNote, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'predefinedNotes/edit/' + id, predefinedNote)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Add predefinedNote
   *
   * @param predefinedNote
   * @returns {Promise<any>}
   */
  addPredefinedNotes(predefinedNote): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'predefinedNotes/add', predefinedNote)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }
}
