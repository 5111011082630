import { TranslationPipe } from './translation.pipe';
import { PluralPipe } from './plural.pipe';
import { YesNo } from './yesNo.pipe';
import { DurationPipe } from './duration.pipe';
import { doubleDotPipe } from './doubleDot.pipe';
import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { CapitalizePipe} from './capitalize.pipe';

@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    DurationPipe,
    PluralPipe,
    YesNo,
    doubleDotPipe,
    TranslationPipe,
    CapitalizePipe
  ],
  imports: [],
  exports: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    DurationPipe,
    PluralPipe,
    YesNo,
    doubleDotPipe,
    TranslationPipe,
    CapitalizePipe
  ]
})
export class FusePipesModule {}
