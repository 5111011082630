import { ConnectorService } from './../../../../shared/auth/connector.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import { environment as env } from '../../../../../environments/environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  badCredential: boolean;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: UntypedFormBuilder,
    private connectorService: ConnectorService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    localStorage.clear(); 
    this.badCredential = false;
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    localStorage.removeItem('auth');
    this.connectorService.login(this.loginForm.value).subscribe((data) => {
      if (data.token != null || data.token !== undefined) {
        const dataPush = data.user.dataPush;
        localStorage.setItem('auth', data.token);
        localStorage.setItem('refresh', data.refresh); 
        const target = data.user.Role.role.replace(/ /g, '');
        if (
          env.twofactor === true &&
          data.user.RoleId != env.roleEdevice &&
          data.user.RoleId != env.rolePatient &&
          data.user.RoleId != env.roleGuest
        ) {
          this.connectorService
            .getTwofactorInfo(data.user.id)
            .then((twofactorInfo) => {
              if (twofactorInfo.twoFactor === true) {
                this.router.navigate(['/twofactor/']);
                
              } else if (dataPush === true && data.user.Role.id === 2 ) {
                this.router.navigate(['/dashboard/dataPush']);
              } else {
                this.router.navigate(['/dashboard/' + target]);
              }
            });
        } else if (dataPush === true && data.user.RoleId === 2  ) {
          this.router.navigate(['/dashboard/dataPush']);
        } else {
          this.router.navigate(['/dashboard/' + target]);
        }
        this.initPageSize();
      } else {
        this.badCredential = true;
        //this.router.navigate(['/login']);
      }
    });
  }

  initPageSize(): void {
    const pageSize = this.localStorageService.get('pageSize');
    if (pageSize === null) {
      this.localStorageService.set('pageSize', 10);
    }
  }

}
