import { StatService } from './../../../service/stat.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-stat-by-severity',
  templateUrl: './stat-by-severity.component.html',
  styleUrls: ['./stat-by-severity.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class StatBySeverityComponent implements OnInit {
  stats: any[];
  id: string;

  // options
  gradient = true;
  showLegend = true;
  showLabels = false;
  isDoughnut = false;
  legendPosition = 'below';

  domain = [];
  colorScheme = {};

  /**
   * Constructor
   *
   * @param {StatService} statService
   */
  constructor(private statService: StatService,
              private route: ActivatedRoute,
              private _matSnackBar: MatSnackBar )
  {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.getStatsSeverityByPatient(this.id);
  }

  ngOnInit(): void {}

  getStatsSeverityByPatient(id): void {
    this.statService
      .getStatsSeverityByPatient(id)
      .then((response) => {
        this.stats = [];
        response.forEach((stat) => {
          this.stats.push({
            name: stat.severity,
            value: stat.count
          });
          this.domain.push(stat.color);
        });
        this.colorScheme = {
          domain: this.domain
        };
      })
      .catch((error) => {
        this._matSnackBar.open('Cannot retrieve statistics : ' + error.statusText, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  onSelect(data): void {}

  onActivate(data): void {}

  onDeactivate(data): void {}
}
