import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThresholdService } from 'app/shared/service/threshold.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { LocalStorageService } from '../../../service/local-storage.service';

@Component({
  selector: 'app-thresholds',
  templateUrl: './thresholds.component.html',
  styleUrls: ['./thresholds.component.scss'],

  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ThresholdsComponent implements OnInit {
  thresholdsList = [];
  measurementTypes: string[] = [];

  currentUser: string;
  patientId: string;

  expandDefaultThresholds = true;
  expandThresholds = true;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private thresholdService: ThresholdService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.route.params.subscribe((params) => {
      this.patientId = params.id;
    });

    this.thresholdsList = this.thresholdService.thresholds
    this.currentUser = this.localStorageService.get('currentUser');
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.getThresholdList();
  }

  getThresholdList(): void {
    this.thresholdService.onThresholdsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(thresholds => {
        this.thresholdService.getThresholdsAndDefaultThresholds(this.patientId)
          .then(thresholdsList => { this.thresholdsList = thresholdsList })
      })
  }

}

