import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../model/user';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class UserService implements Resolve<any> {
  user: User;
  users: User[];
  routeParams: any;
  onUserChanged: BehaviorSubject<any>;
  onUsersChanged: BehaviorSubject<any>;

  constructor( private http: HttpClient ) {
    this.onUsersChanged = new BehaviorSubject({});
    this.onUserChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getUser(this.routeParams.id)]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getUsers()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Users ---------------------------
  /**
   * Get users
   *
   * @returns {Promise<any>}
   */
  getUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'users/')
        .subscribe((response: any) => {
          this.users = response;
          this.onUsersChanged.next(this.users);
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ User ---------------------------
  /**
   * Get user
   *
   * @returns {Promise<any>}
   */
  getUser(id): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onUserChanged.next(false);
        resolve(false);
      } else {
        this.http.get(env.apiUrl + 'users/' + id).subscribe((response: any) => {
          this.user = response;
          this.onUserChanged.next(this.user);
          resolve(response);
        }, reject);
      }
    });
  }

  /**
   * Save user
   *
   * @param user
   * @returns {Promise<any>}
   */
  saveUser(user, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'users/edit/' + id, user)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Add user
   *
   * @param user
   * @returns {Promise<any>}
   */
  addUser(user): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'users/add', user)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Change Specific right
   *
   * @returns {Promise<any>}
   */
  changeSpecificRight(state): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'users/specificRight/' + this.routeParams.id, {
          specificRight: state
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

/**
   * Invalidated token on logout
   *
   * @returns {Promise<any>}
   */
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'users/logout',{})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
    }
  }

