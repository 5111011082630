import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { StatService } from '../../../service/stat.service';
import { fuseAnimations } from '@fuse/animations';
import { SeverityService } from 'app/shared/service/severity.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-stat-pie-chart-dashboard',
  templateUrl: './stat-pie-chart-dashboard.component.html',
  styleUrls: ['./stat-pie-chart-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class StatPieChartDashboardComponent implements OnInit {
  stats: any[];
  @Input() pieChartName: string;

  // options
  gradient = true;
  showLegend = true;
  showLabels = false;
  isDoughnut = true;
  legendPosition = 'below';
  colorScheme = {};
  domain = [];
  displayMessage: boolean;

  constructor(
    private statService: StatService,
    private severityService: SeverityService,
    private _matSnackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.displayMessage = false;
    switch (this.pieChartName) {
      case 'Daily patients measurements':
        this.getstatsPatientsWithMeasurementsTodayByPhysician();
        this.colorScheme = { domain: ['#C43E3D', '#C4C316'] };
        break;
      case 'Hub Allocation':
        this.getStatsHubAllocation();
        this.colorScheme = { domain: ['#C4C316', '#C43E3D'] };
        break;
      case 'Device Allocation':
        this.getDevicesAllocation();
        this.colorScheme = { domain: ['#C4C316', '#C43E3D'] };
        break;
      case 'Hub Communication':
        this.getHubCommunication();
        this.colorScheme = { domain: ['#C4C316', '#C43E3D'] };
        break;
      default:
        this._matSnackBar.open('stat pie chart component: case not defined', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
    }
  }

  getHubCommunication(): void{
    this.stats = [];
    this.statService
      .getHubCommunication()
      .then((response) => {
        this.stats = response.map((stat) => ({
          name: stat.name,
          value: stat.value
        }));
        if (this.stats[0].value === null && this.stats[1].value === null) {
          this.displayMessage = true;
        }
      })
      .catch((err) => {
        this._matSnackBar.open('Hub Communication Count : ' + err.statusText, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  getDevicesAllocation(): void{
    this.stats = [];
    this.statService
      .getDevicesAllocation()
      .then((response) => {
        this.stats = response.map((stat) => ({
          name: stat.name,
          value: stat.value
        }));
        if (this.stats[0].value === 0 && this.stats[1].value === 0) {
          this.displayMessage = true;
        }
      })
      .catch((err) => {
        this._matSnackBar.open('Device Allocation Count : ' + err.statusText, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  getstatsPatientsWithMeasurementsTodayByPhysician(): void {
    this.stats = [];
    this.statService
      .getstatsPatientMeasurementByPhysician()
      .then((response) => {
        this.stats = response.map((stat) => ({
          name: stat.patients,
          value: stat.count
        }));
        if (this.stats[0].value === 0 && this.stats[1].value === 0) {
          this.displayMessage = true;
        }
      })
      .catch((error) => {
        this._matSnackBar.open('Daily patients measurements : ' + error.statusText, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  getStatsHubAllocation(): void {
    this.stats = [];
    this.statService
      .getHubAllocation()
      .then((response) => {
        this.stats = response.map((stat) => ({
          name: stat.name,
          value: stat.value
        }));
        if (this.stats[0].value === null && this.stats[1].value === null) {
          this.displayMessage = true;
        }
      })
      .catch((error) => {
        this._matSnackBar.open('Hub allocation : ' + error.statusText, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  onSelect(data): void {}

  onActivate(data): void {}

  onDeactivate(data): void {}
}
