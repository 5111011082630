import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ErrorService } from './error.service';
import { User } from 'app/shared/model/user';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class StatService {
  stat;
  stats: any[];
  routeParams: any;
  id;
  currentUser: User;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id')) {
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getStatsSeverityByPatient(this.routeParams.id),
          this.getStatsMeasurementByPatient(this.routeParams.id, this.routeParams.days)
        ]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Stat Severity By Patient ---------------------------
  /**
   * Get stat for one patient
   *
   * @returns {Promise<any>}
   */
  getStatsSeverityByPatient(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/statsBySeverity/patient/' + id)
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ Stat Measurement By Patient ---------------------------
  /**
   * Get stat for one patient
   *
   * @returns {Promise<any>}
   */
  getStatsMeasurementByPatient(id, days): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/statsMeasurement/patient/' + id + '/' + days)
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ Stat for physician user ---------------------------


  /**
   * Get stat Patients Measurements of the day for physician user
   *
   * @returns {Promise<any>}
   */
  getstatsPatientMeasurementByPhysician(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/statsPatientsMeasurementsTaken/physician')
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get stat on time on each patient for month for physician user
   *
   * @returns {Promise<any>}
   */
  getstatsTimeByPatientForAllPhysicians(days: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/averagePatientTimeForAllPhysicians/' + days)
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get distribution of users in database
   *
   * @returns {Promise<any>}
   */
  getStatsGlobalUsersDistributions(userTypeToCount: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          env.apiUrl + 'stats/statsGlobalUsersDistributions/' + userTypeToCount
        )
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get Hubs usage for admin
   *
   * @returns {Promise<any>}
   */
  getStatsGlobalHubsUsage(days: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/kitPercentageUsagePerDay/' + days)
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  getHubAllocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/statsHubsAllocation/')
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  getDevicesAllocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/statsDevicesAllocation/')
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }

  getHubCommunication(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'stats/statsHubCommunication/')
        .subscribe((response: any) => {
          this.stats = response;
          resolve(response);
        }, reject);
    });
  }
}
