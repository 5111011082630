import { Injectable } from '@angular/core';
import {Action} from '../model/action';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment as env} from '../../../environments/environment';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ConnectorService} from '../auth/connector.service';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  action: Action;
  actions: Action[];
  routeParams: any;
  onActionChanged: BehaviorSubject<any>;
  onActionsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  filterBy: any;
  currentUser: any;
  count = 0;
  countOutOfNorm: BehaviorSubject<any>;
  countTransmissionMissing: BehaviorSubject<any>;
  countTouchTime: BehaviorSubject<any>;
  countMedicalStaff: BehaviorSubject<any>;
  countNormal: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private connectorService: ConnectorService,
    private errorService: ErrorService,
  ) {
    this.onActionChanged = new BehaviorSubject({});
    this.onActionsChanged = new BehaviorSubject({});
    this.onFilterChanged = new Subject();
    this.currentUser = connectorService.getUser();
    this.countOutOfNorm = new BehaviorSubject({});
    this.countTransmissionMissing = new BehaviorSubject({});
    this.countTouchTime = new BehaviorSubject({});
    this.countMedicalStaff = new BehaviorSubject({});
    this.countNormal = new BehaviorSubject({});
  }


  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;

    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getAction()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }

    else if (state.url.includes('dashboard/physician')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getActionsDashboard()]).then((response) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getActionsDashboard();
          });
          resolve(null);
        }, reject);
      });
    } 
    
    else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getActions()]).then((response) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getActionsDashboard();
          });
          resolve(null);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Actions ---------------------------
  /**
   * Get action
   *
   * @returns {Promise<any>}
   */
   getActionsDashboard(): Promise<any> {
     this.count ++; // Reload and regenerate data
     const filter = JSON.stringify(this.filterBy);
     let url = env.apiUrl + 'actions/dashboard';
     if (this.filterBy != undefined && this.count > 2){
      url = env.apiUrl + 'actions/dashboard/' + encodeURIComponent(JSON.stringify(this.filterBy));
    }
     return new Promise((resolve, reject) => {
      this.http.get(url).subscribe((response: any) => {
        this.actions = response;
        const actionsAbnormal = this.actions.filter( (action) => action.ActionCategoryId == env.action_out_of_norm);
        this.countOutOfNorm.next(actionsAbnormal.length);
        const actionsTransmissionMissing = this.actions.filter( (action) => action.ActionCategoryId == env.action_transmission_missing);
        this.countTransmissionMissing.next(actionsTransmissionMissing.length);
        const actionsTouchTime = this.actions.filter( (action) => action.ActionCategoryId == env.action_touch_time);
        this.countTouchTime.next(actionsTouchTime.length);
        const actionsMedicalStaff = this.actions.filter( (action) => action.ActionCategoryId == env.action_medical_staff);
        this.countMedicalStaff.next(actionsMedicalStaff.length);
        const actionsNormal = this.actions.filter( (action) => action.ActionCategoryId == env.action_normal);
        this.countNormal.next(actionsNormal.length);
        this.onActionsChanged.next(this.actions);
        resolve(response);
      }, reject);
    });
  }

  /**
   * Get action
   *
   * @returns {Promise<any>}
   */
   getActions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'actions').subscribe((response: any) => {
        if (!response.message) {
          this.actions = response.filter(
            (data) => data.ActionStatusId != 4
          );
        }
        if (this.filterBy && this.filterBy === 'new') {
          this.actions = this.actions.filter(
            (data) =>  data.AssignToId === null && data.ActionCategoryId != 4
          );
        } else if (this.filterBy && this.filterBy !== 'all') {
          this.actions = this.actions.filter(
            (data) =>  data.AssignToId === this.filterBy && data.ActionCategoryId != 4
          );
        }
        this.onActionsChanged.next(this.actions);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Action ---------------------------

  /**
   * Get action
   *
   * @returns {Promise<any>}
   */
  getAction(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onActionChanged.next(false);
        resolve(false);
      }
      else {
        this.http.get(env.apiUrl + 'actions/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.action = response;
            this.onActionChanged.next(this.action);
            resolve(response);
          }, reject);
      }
    });
  }


  /**
   * Save action
   *
   * @param action
   * @param id
   * @returns {Promise<any>}
   */
  saveAction(action, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'actions/edit/' + id, action)
        .subscribe((response: any) => {
          this.getActionsDashboard()
            .then((actions) => {
              this.onActionsChanged.next(actions);
              resolve(response);
            })
            .catch((error) => {
              catchError(this.errorService.handleError('edit_action'));
            });
        }, reject);
    });
  }

  /**
   * Add action
   *
   * @param action
   * @returns {Promise<any>}
   */
  addAction(action): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'actions/add', action)
        .subscribe((response: any) => {
          this.getActionsDashboard()
            .then((actions) => {
              this.onActionsChanged.next(actions);
              resolve(response);
            })
            .catch((error) => {
              catchError(this.errorService.handleError('add_action'));
            });
        }, reject);
    });
  }
}
