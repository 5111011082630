import { Pipe, PipeTransform } from '@angular/core';
import { TranslationGlobalService} from '../../app/shared/service/translationGlobal.service';

@Pipe({
  name: 'translation'
})
export class TranslationPipe implements PipeTransform {

  constructor(private _translationService: TranslationGlobalService){

  }

  transform(value): string {
    const result = this._translationService.getTranslation(value.toLowerCase().trim());
    return result;
  }

}
