import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { HistoryService } from 'app/shared/service/history.service';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { DurationPipe } from '../../../../../@fuse/pipes/duration.pipe';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { MatDialog } from '@angular/material/dialog';
import { ModalHistoryComponent } from '../modal/history/history.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {LocalStorageService} from '../../../service/local-storage.service';

@Component({
  selector: 'app-histories',
  templateUrl: './histories.component.html',
  styleUrls: ['./histories.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  providers: [DurationPipe]
})
export class HistoriesComponent implements OnInit {
  dataSource: FilesDataSource | null;
  totalHistories: string;
  totalHistoriesThisMonth: string;
  pageSize;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  displayedColumns = [
    'date',
    'timeStart',
    'timeEnd',
    'MedicalStaff',
    'Duration',
    'mode'
  ];

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private historyService: HistoryService,
    private route: ActivatedRoute,
    private _matSnackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private durationPipe: DurationPipe,
    private localStorageService: LocalStorageService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource = new FilesDataSource(
      this.historyService,
      this.paginator,
      this.sort
    );

    this.getPageSize();

    if (this.filter != null) {
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(150),
          distinctUntilChanged()
        )
        .subscribe(() => {
          if (!this.dataSource) {
            return;
          }

          this.filter = this.filter.nativeElement.value;
        });
    }

    this.historyService.onHistoryChanged.subscribe((histories) => {
      const date = new Date();
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
      if (histories.length > 0) {
        let totalHistoriesSum = 0;
        let totalHistoriesThisMonthSum = 0;
        histories.forEach((history) => {
          totalHistoriesSum = totalHistoriesSum + history.duration;
          if (history.start >= startDate){
            totalHistoriesThisMonthSum = totalHistoriesThisMonthSum + history.duration;
          }
        });
        
        this.totalHistories = this.durationPipe.transform(totalHistoriesSum);
        this.totalHistoriesThisMonth = this.durationPipe.transform(totalHistoriesThisMonthSum)
      }
    });

  }

  actionModal(history: any): void {
    if (history.mode === 'manual') {
      this._matDialog.open(ModalHistoryComponent, {
        panelClass: 'description-dialog',
        data: {
          pageType: 'edit',
          history: history
        }
      });
    } else {
      this._matSnackBar.open('You cannot edit automatic history', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }

  getPageSize(): void {
    this.pageSize = this.localStorageService.get('pageSize');
  }

  setPageSize(pageEvent): void {
    const newPageSize = pageEvent.pageSize;
    this.localStorageService.set('pageSize', newPageSize );
  }
}

export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {HistoryService} historyService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private historyService: HistoryService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    this.filteredData = this.historyService.histories;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.historyService.onHistoriesChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = [];
        if (this.historyService.histories.length > 0) {
          data = this.historyService.histories.slice();

          data = this.filterData(data);

          this.filteredData = [...data];

          data = this.sortData(data);
        }

        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'date':
          [propertyA, propertyB] = [a.start, b.start];
          break;
        case 'timeStart':
          [propertyA, propertyB] = [a.start, b.start];
          break;
        case 'timeEnd':
          [propertyA, propertyB] = [a.end, b.end];
          break;
        case 'MedicalStaff':
          let aName = a.Medical.lastName + ' ' + a.Medical.firstName;
          let bName = b.Medical.lastName + ' ' + b.Medical.firstName;
          [propertyA, propertyB] = [aName.toLowerCase().trim(), bName.toLowerCase().trim()];
          break;
        case 'Duration':
          [propertyA, propertyB] = [a.duration, b.duration];
          break;
        case 'mode':
          [propertyA, propertyB] = [a.mode, b.mode];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void { }
}
