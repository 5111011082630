import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { DefaultThreshold } from '../model/default-threshold';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DefaultThresholdService implements Resolve<any> {
  defaultThreshold: DefaultThreshold;
  defaultThresholds: any[];
  routeParams: any;
  onDefaultThresholdChanged: BehaviorSubject<any>;
  onDefaultThresholdsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  id;
  filterBy: any;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {
    this.onDefaultThresholdsChanged = new BehaviorSubject({});
    this.onDefaultThresholdChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.onFilterChanged = new Subject();

  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (
      this.routeParams.hasOwnProperty('id') &&
      state.url.includes('defaultThresholds') &&
      this.routeParams.id != 'new'
    ) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getOneDefaultThreshold()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else if ( state.url.includes('profile')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPhysicianDefaultThresholds()]).then(([]) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getPhysicianDefaultThresholds();
          });
          resolve(null);
        }, reject);
      });
    }else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getDefaultThresholds()]).then(([]) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getDefaultThresholds();
          });
          resolve(null);
        }, reject);
      });
    }
  }


  // ------------------------------------------------ Default Thresholds ---------------------------

  /**
   * Get default thresholds
   *
   * @returns {Promise<any>}
   */
  getDefaultThresholds(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'defaultThresholds')
      .subscribe((response: any) => {
        this.defaultThresholds = response;
        this.onDefaultThresholdsChanged.next(this.defaultThresholds);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Default Threshold ---------------------------
  /**
   * Get One default threshold
   *
   * @returns {Promise<any>}
   */
  getOneDefaultThreshold(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'defaultThresholds/' + this.routeParams.id)
        .subscribe((response: any) => {
          this.defaultThreshold = response;
          this.onDefaultThresholdChanged.next(this.defaultThreshold);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get default threshold list for physician
   *
   * @returns {Promise<any>}
   */
  getPhysicianDefaultThresholds(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'defaultThresholds/physician')
        .subscribe((response: any) => {
          this.defaultThresholds = response;
          this.onDefaultThresholdsChanged.next(this.defaultThresholds);
          resolve(response);
        }, reject);
    })
  }

  /**
   * Save default threshold
   *
   * @param defaultThreshold
   * @returns {Promise<any>}
   */
  saveDefaultThreshold(defaultThreshold, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'defaultThresholds/edit/' + id, defaultThreshold)
        .subscribe((response: any) => {
          this.getDefaultThresholds().then((thresholds) => {
            this.onDefaultThresholdsChanged.next(thresholds);
            resolve(response);
          })
            .catch((error) => {
              catchError(this.errorService.handleError('save_default_threshold'));
            });
        }, reject);
    });
  }

  /**
   * Add default threshold
   *
   * @param defaultThreshold
   * @returns {Promise<any>}
   */
  addDefaultThreshold(defaultThreshold): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'defaultThresholds/add', defaultThreshold)
        .subscribe((response: any) => {
          this.getDefaultThresholds()
            .then((defaultThreshold) => {
              this.onDefaultThresholdsChanged.next(defaultThreshold);
              resolve(response);
            })
            .catch((error) => {
              catchError(this.errorService.handleError('add_default_threshold'));
            });
        }, reject);
    });
  }
  
  /**
   * Reset custom default threshold to default threshold
   */
   resetCustomDefaultThreshold(measurementTypeId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'defaultThresholds/resetDefaultThreshold/' + measurementTypeId)
        .subscribe((response: any) => {
          this.getDefaultThresholds()
            .then((defaultThreshold) => {
              this.onDefaultThresholdsChanged.next(defaultThreshold);
              resolve(response);
            })
            .catch((error) => {
              catchError(this.errorService.handleError('add_default_threshold'));
            });
        }, reject); 
      });
  }

  /**
   * delete default threshold
   */
  deleteDefaultThreshold(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'defaultThresholds/delete/' + id)
        .subscribe((response: any) => {
          this.defaultThresholds = this.defaultThresholds.filter(
            (defaultThreshold) => defaultThreshold.id != id
          );
          this.onDefaultThresholdsChanged.next(this.defaultThresholds);
          resolve(response);
        }, reject); 
      });
  }
}


