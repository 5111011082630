import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector     : 'description',
    templateUrl  : './description.component.html',
    styleUrls    : ['./description.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DescriptionComponent
{
    /**
     * Constructor
     *
     * @param {MatDialogRef<DescriptionComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<DescriptionComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any
    )
    {
    }
}
