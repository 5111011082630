import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor() {}

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError({ message: 'operation not permitted' });
      //return throwError({ message: error });
    };
  }
}
