import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Note } from '../model/note';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoteService implements Resolve<any> {
  note: Note;
  notes: any[] = [];
  routeParams: any;
  onNoteChanged: BehaviorSubject<any>;
  onNotesChanged: BehaviorSubject<any>;
  id;
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {
    this.onNotesChanged = new BehaviorSubject({});
    this.onNoteChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getNote(this.routeParams.id)]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getNotes()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Notes ---------------------------
  /**
   * Get notes
   *
   * @returns {Promise<any>}
   */
  getNotes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'notes')
      .subscribe((response: any) => {
        this.notes = response;
        this.onNotesChanged.next(this.notes);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Note ---------------------------
  /**
   * Get note for one patient
   *
   * @returns {Promise<any>}
   */
  getNote(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'notes/user/' + id)
        .subscribe((response: any) => {
          this.notes = response;
          this.onNoteChanged.next(this.notes);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get One note
   *
   * @returns {Promise<any>}
   */
  getOneNote(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'notes/' + id).subscribe((response: any) => {
        this.notes = response;
        this.onNoteChanged.next(this.notes);
        resolve(response);
      }, reject);
    });
  }

  /**
   * Save note
   *
   * @param note
   * @returns {Promise<any>}
   */
  saveNote(note, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'notes/edit/' + id, note)
        .subscribe((response: any) => {
          this.getNote(note.UserId)
            .then((notes) => {
              this.onNotesChanged.next(notes);
              resolve(response);
            })
            .catch((err) => {
              catchError(this.errorService.handleError('save_note'));
            });
        }, reject);
    });
  }

  /**
   * Add note
   *
   * @param note
   * @returns {Promise<any>}
   */
  addNote(note): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'notes/add', note)
        .subscribe((response: any) => {
          this.getNote(note.UserId)
            .then((newNote) => {
              this.onNotesChanged.next(newNote);
              resolve(response);
            })
            .catch((err) => {
              catchError(this.errorService.handleError('add_note'));
            });
        }, reject);
    });
  }
}
