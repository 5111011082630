import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MeasurementService } from 'app/shared/service/measurement.service';

@Component({
  selector: 'app-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ModalMeasurementComponent implements OnInit {
  measurementForm: UntypedFormGroup;
  patternToDisplay;
  imageLoading: boolean;

  constructor(
    public matDialogRef: MatDialogRef<ModalMeasurementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private measurementService: MeasurementService,
    private _formBuilder: UntypedFormBuilder,
    private _location: Location,
    private _matSnackBar: MatSnackBar,
  ) {
    if (data.measurement.JsonMeasurements[0]) {
      this.patternToDisplay = data.measurement.JsonMeasurements[0].jsonValue;
      this.imageLoading = false;
    }
    if (data.measurement.PictureMeasurements[0]) {
      this.patternToDisplay = data.measurement.PictureMeasurements[0].picture;
      this.imageLoading = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit(): void {
    this.measurementForm = this.createMeasurementForm();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create note form
   *
   * @returns {FormGroup}
   */
  createMeasurementForm(): UntypedFormGroup {
    return this._formBuilder.group({
      measurement: [this.patternToDisplay]
    });
  }
}
