import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HealthCareProvider } from '../model/health-care-provider';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HealthCareProviderService implements Resolve<any>{

  healthCareProvider: HealthCareProvider;
  healthCareProviders: HealthCareProvider[];
  onHealthCareProviderChanged: BehaviorSubject<any>;
  onHealthCareProvidersChanged: BehaviorSubject<any>;
  routeParams: any;

  constructor(private http: HttpClient) {
    this.onHealthCareProviderChanged = new BehaviorSubject({});
    this.onHealthCareProvidersChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getHealthCareProvider()
        ]).then(
          (response) => {
            resolve(response);
          },
          reject
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getHealthCareProviders()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }


  // ------------------------------------------------ HealthCareProviders ---------------------------
  /**
   * Get healthCareProviders
   *
   * @returns {Promise<any>}
   */
  getHealthCareProviders(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'healthCareProviders')
        .subscribe((response: any) => {
          this.healthCareProviders = response;
          this.onHealthCareProvidersChanged.next(this.healthCareProviders);
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get healthCareProviders by Customer
   *
   * @returns {Promise<any>}
   */
  getHealthCareProvidersByCustomer(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'healthCareProviders/customer/' + id)
        .subscribe((response: any) => {
          // If data is empty, clean the select dropdown
          if (response.message) {
            response = [];
          }
          this.healthCareProviders = response;
          this.onHealthCareProvidersChanged.next(this.healthCareProviders);
          resolve(response);
        }, reject);
    });
  }
  // ------------------------------------------------ HealthCareProvider ---------------------------

  /**
   * Get healthCareProvider
   *
   * @returns {Promise<any>}
   */
  getHealthCareProvider(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onHealthCareProviderChanged.next(false);
        resolve(false);
      }
      else {
        this.http.get(env.apiUrl + 'healthCareProviders/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.healthCareProviders = response;
            this.onHealthCareProviderChanged.next(this.healthCareProviders);
            resolve(response);
          }, reject);
      }
    });
  }

  getHealthCareProviderById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'healthCareProviders/' + id)
        .subscribe((response: any) => {
          this.healthCareProvider = response;
          this.onHealthCareProviderChanged.next(this.healthCareProvider);
          resolve(response);
        }, reject);
    });
  }


  /**
   * Save healthCareProvider
   *
   * @param healthCareProvider
   * @returns {Promise<any>}
   */
  saveHealthCareProvider(healthCareProvider, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(env.apiUrl + 'healthCareProviders/edit/' + id, healthCareProvider)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Add healthCareProvider
   *
   * @param healthCareProvider
   * @returns {Promise<any>}
   */
  addHealthCareProvider(healthCareProvider): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(env.apiUrl + 'healthCareProviders/add', healthCareProvider)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

}
