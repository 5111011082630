import { Injectable } from '@angular/core';
import {ActionCategory} from '../model/action-category';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {environment as env} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionCategoryService {
  actionCategory: ActionCategory;
  actionCategories: ActionCategory[];
  routeParams: any;
  onActionCategoryChanged: BehaviorSubject<any>;
  onActionCategoriesChanged: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.onActionCategoryChanged = new BehaviorSubject({});
    this.onActionCategoriesChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getActionCategory()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getActionCategories()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ ActionCategories ---------------------------
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getActionCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'actionCategories').subscribe((response: any) => {
        this.actionCategories = response;
        this.onActionCategoriesChanged.next(this.actionCategories);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ ActionCategory ---------------------------

  /**
   * Get actionCategory
   *
   * @returns {Promise<any>}
   */
  getActionCategory(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onActionCategoryChanged.next(false);
        resolve(false);
      }
      else {
        this.http.get(env.apiUrl + 'actionCategories/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.actionCategory = response;
            this.onActionCategoryChanged.next(this.actionCategory);
            resolve(response);
          }, reject);
      }
    });
  }

  /**
   * Save actionCategory
   *
   * @param actionCategory
   * @returns {Promise<any>}
   */
  saveActionCategory(actionCategory, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'actionCategories/edit/' + id, actionCategory)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Add actionCategory
   *
   * @param actionCategory
   * @returns {Promise<any>}
   */
  addActionCategory(actionCategory): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'actionCategories/add', actionCategory)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }
}
