import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PhysicianService } from '../../../../../shared/service/physician.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { KittingService } from '../../../../../shared/service/kitting.service';
import { HubService } from '../../../../../shared/service/hub.service';
import { DeviceService } from '../../../../../shared/service/device.service';
import { CustomerService } from '../../../../../shared/service/customer.service';
import { ActionService } from '../../../../../shared/service/action.service';
import { DefaultThresholdService } from '../../../../../shared/service/default-threshold.service';
import { ThresholdService } from '../../../../../shared/service/threshold.service';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent {
  id: string;
  page: string;

  constructor(
    public matDialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private physicianService: PhysicianService,
    private kittingService: KittingService,
    private hubService: HubService,
    private deviceService: DeviceService,
    private customerService: CustomerService,
    private defaultThresholdService: DefaultThresholdService,
    private thresholdService : ThresholdService,
    private actionService: ActionService,
    private _matSnackBar: MatSnackBar,
    private router: Router
  ) {
    this.id = _data.id;

  }

  deletePhysician(): void {
    this.physicianService.archivePhysician(this.id).then(() => {
      this.matDialogRef.close();
      this._matSnackBar.open('Physician deleted', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.router.navigate(['/apps/physicians']);
    });
  }

  /**
   * handle remove
   */
  removeKitting(): void {
    this.kittingService.deleteKitting(this.id).then(() => {
      this.matDialogRef.close();
      this._matSnackBar.open('Authorized Device deleted', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    });
  }

  removeHub(): void {
    this.hubService.deleteHub(this.id).then(() => {
      this.matDialogRef.close();
      this._matSnackBar.open('Hub deleted', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.router.navigate(['/apps/hubs']);
    });
  }

  removeDevice(): void {
    this.deviceService.deleteDevice(this.id).then((result) => {
      this.matDialogRef.close();
      this._matSnackBar.open(result.message, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.router.navigate(['/apps/devices']);
    });
  }

  removeCustomer(): void {
    this.customerService.deleteCustomer(this.id).then((result) => {
      this.matDialogRef.close();
      this._matSnackBar.open(result.message, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.router.navigate(['/apps/customers']);
    });
  }

  regenerateToken(): void {
    this.customerService.generateApiToken(this.id).then(response => {
      this.matDialogRef.close();
      this._matSnackBar.open('Token regenerate', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    });
  }

  deleteThresholds() : void {
    this._data.thresholds.Thresholds.map(threshold => {
      this.thresholdService.deleteThreshold(threshold.id)
    })
    this.matDialogRef.close();
    this._matSnackBar.open('Threshold deleted', 'OK', {
      verticalPosition: 'top',
      duration: 2000
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  resetDefaultThresholds(): void {
    this.defaultThresholdService.resetCustomDefaultThreshold(this.id).then(() => {
      this.matDialogRef.close();
      this._matSnackBar.open('Default threshold deleted', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  }
}
