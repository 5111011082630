import { ConnectorService } from '../../../../shared/auth/connector.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'twofactor',
  templateUrl: './twofactor.component.html',
  styleUrls: ['./twofactor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class TwofactorComponent implements OnInit {
  twofactorForm: UntypedFormGroup;
  currentUserConnectedId;
  defaultMethodUsed;
  lastMethodUsed;
  userPhoneMobile;
  UserEmail;
  status = false;
  codeExpired = false;
  wrongCode = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: UntypedFormBuilder,
    private connectorService: ConnectorService,
    private router: Router
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.twofactorForm = this._formBuilder.group({
      twofactorCode: ['', Validators.required]
    });
    this.currentUserConnectedId = this.connectorService.getUser();
    // init method in case resent is done
    this.connectorService
      .getTwofactorInfo(this.currentUserConnectedId)
      .then((twofactorInfo) => {
        this.defaultMethodUsed = twofactorInfo.twoFactorDefaultMethod;
        this.lastMethodUsed = twofactorInfo.twoFactorLatestMethod;
        this.userPhoneMobile = twofactorInfo.User.phoneMobile;
        this.UserEmail = twofactorInfo.User.email;
      });
  }

  onSubmit(): void {
    // retrieve data during the submit to be sure to have the last code
    this.connectorService
      .getTwofactorInfo(this.currentUserConnectedId)
      .then((twofactorInfo) => {
        const nowDate = new Date();
        const expireTimeout = new Date(twofactorInfo.expireTimeout);
        // date not expired and same code
        if (
          this.twofactorForm.value.twofactorCode ==
            twofactorInfo.twoFactorCode &&
          expireTimeout > nowDate
        ) {
          this.router.navigate([
            '/dashboard/' + twofactorInfo.User.Role.role.replace(/ /g, '')
          ]);
        }
        // date expired
        if (
          this.twofactorForm.value.twofactorCode ==
            twofactorInfo.twoFactorCode &&
          expireTimeout < nowDate
        ) {
          this.codeExpired = true;
        }
        // code different
        if (
          this.twofactorForm.value.twofactorCode != twofactorInfo.twoFactorCode
        ) {
          this.wrongCode = true;
        }
      });
  }

  resendCodeUsingAlternateMethod(): void {
    // check default method and use the other method to send the twofactor
    this.status = true;
    this.defaultMethodUsed = 'sms' //add to hide sms possibility - resend always an email
    switch (this.defaultMethodUsed) {
      case 'sms':
        this.connectorService.twofactorGenerateCode('email').then(() => {
          setTimeout(() => {
            this.status = false;
          }, 3000);
        });
        this.lastMethodUsed = 'email';
        break;
      case 'email':
        this.connectorService.twofactorGenerateCode('sms').then(() => {
          setTimeout(() => {
            this.status = false;
          }, 3000);
        });
        this.lastMethodUsed = 'sms';
        break;
    }
  }

  redirectToLogin(): void {
    this.router.navigate(['/login']);
  }

  obfuscate(userPhoneOrEmail: string, method: string): string {
    let result = '';
    const split = userPhoneOrEmail.split('');
    const numberOfStartCharacters = 4;
    let numberOfEndCharacters = 2;
    if (method === 'email') {
      numberOfEndCharacters = 4;
    }

    split.forEach(function (item, index) {
      if (
        numberOfStartCharacters > index ||
        split.length - numberOfEndCharacters <= index ||
        '@' == item
      ) {
        result += item;
      } else {
        result += '*';
      }
    });

    return result;
  }
}
