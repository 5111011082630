export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://back.edeviceconnectedcare.com/',
  appUrl: 'https://edeviceconnectedcare.com',
  logUrl: 'https://04e8x24wbg.execute-api.us-east-1.amazonaws.com/production/',
  env: 'PROD',
  roleEdevice: 1,
  roleCustomer: 2,
  roleHealthCareProvider: 3,
  rolePhysician: 4,
  roleKitManager: 5,
  rolePatient: 6,
  roleGuest: 7,
  roleApi: 8,
  severityHard: 4,
  severityMedium: 3,
  severityOk: 2,
  severityNotDefined: 1,
  twofactor: true,
  action_out_of_norm: 1,
  action_transmission_missing: 2,
  action_touch_time: 3,
  action_medical_staff: 4,
  action_normal: 5,
  action_status_new: 1,
  action_status_inprogress: 2,
  action_status_done: 3,
  action_status_canceled: 4,
  title: 'edevice CCA Prod'

};
