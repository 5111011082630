import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment as env } from '../../../environments/environment';
import { ConnectorService } from '../auth/connector.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicianService {
  physician: User;
  physicians: User[];
  routeParams: any;
  id: string;
  onPhysicianChanged: BehaviorSubject<any>;
  onPhysiciansChanged: BehaviorSubject<any>;
  currentUser: any;
  roleUser;


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private connectorService: ConnectorService
  ) {
    this.onPhysiciansChanged = new BehaviorSubject({});
    this.onPhysicianChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.currentUser = connectorService.getUser();
    this.roleUser = connectorService.getRole();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPhysician()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPhysicians()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Physicians ---------------------------
  /**
   * Get physicians
   *
   * @returns {Promise<any>}
   */
  getPhysicians(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'physicians').subscribe((response: any) => {
        if (this.roleUser === env.rolePhysician) {
          this.physicians = response.filter( physician => physician.id !== this.currentUser);
        } else {
          this.physicians = response;
        }
        this.onPhysiciansChanged.next(this.physicians);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Physician ---------------------------
  /**
   * Get physician
   *
   * @returns {Promise<any>}
   */
  getPhysician(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onPhysicianChanged.next(false);
        resolve(false);
      } else {
        this.http
          .get(env.apiUrl + 'physicians/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.physician = response;
            this.onPhysicianChanged.next(this.physician);
            resolve(response);
          }, reject);
      }
    });
  }

  /**
   * Get physicians by HealthCareProvider
   *
   * @returns {Promise<any>}
   */
  getPhysiciansByHealthCareProvider(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'physicians/healthCareProvider/' + id)
        .subscribe((response: any) => {
          if (response.message) {
            response = [];
          }
          this.physicians = response;
          this.onPhysiciansChanged.next(this.physicians);
          resolve(response);
        }, reject);
    });
  }

  /**
   *
   * Archive physician
   *
   * @returns {Promise<any>}
   */
  archivePhysician(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'physicians/archived/' + id)
        .subscribe((response: any) => {
          this.physicians = this.physicians.filter(
            (physician) => physician.id != id
          );
          this.onPhysiciansChanged.next(this.physicians);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Change Specific right
   *
   * @returns {Promise<any>}
   */
  changeSpecificRight(state): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'users/specificRight/' + this.routeParams.id, {
          specificRight: state
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
