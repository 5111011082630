import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { PatientService } from '../../../service/patient.service';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { map} from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { FuseUtils } from '../../../../../@fuse/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dash-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PatientComponent implements OnInit {
  dataSource: FilesDataSource | null;
  displayedColumns = [
    'name',
    'frequency',
    'adhesion',
    'measurementType',
    'severity',
    'focus',
    'full'
  ];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource = new FilesDataSource(
      this.patientService,
      this.paginator,
      this.sort
    );
  }

  /**
   *  AttentionPatient
   *
   */
  attentionPatient(patient, status): void {
    let attention;
    if (status == true) {
      attention = false;
    } else {
      attention = true;
    }
    this.patientService
      .attentionPatient({ attention: attention }, patient)
      .then(() => {});
  }
}
export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {PatientService} patientService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private patientService: PatientService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    this.filteredData = this.patientService.patients;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.patientService.onPatientsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = [];
        if (this.patientService.patients) {
          if (this.patientService.patients?.length > 0) {
            data = this.patientService.patients.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);
          }
        }

        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      /*    {
      for (let i=0; i <data.length-1; i++){

      }*/
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'name':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'severity':
          [propertyA, propertyB] = [a.SeverityId, b.SeverityId];
          break;
        case 'lastMeasurement':
          [propertyA, propertyB] = [a.dateTimeTaken, b.dateTimeTaken];
          break;
        case 'focus':
          [propertyA, propertyB] = [
            a.User.PatientDetail.attention,
            b.User.PatientDetail.attention
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {}
}
