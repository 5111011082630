import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { ThresholdService } from 'app/shared/service/threshold.service';
import { fuseAnimations } from '@fuse/animations';
import { SeverityService } from '../../../service/severity.service';
import { DefaultThresholdService } from '../../../service/default-threshold.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MeasurementTypeService } from '../../../service/measurement-type.service';
import { ActionCategoryService } from '../../../service/action-category.service';
import { PredefinedActionsService } from '../../../service/predefined-actions.service';
import { LocalStorageService } from '../../../service/local-storage.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { DefaultThreshold } from 'app/shared/model/default-threshold';
import { MatDialogRef } from '@angular/material/dialog';

import { environment as env } from '../../../../../environments/environment';

@Component({
  selector: 'app-patient-default-threshold',
  templateUrl: './default-threshold.component.html',
  styleUrls: ['./default-threshold.component.scss'],

  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DefaultThresholdComponent implements OnInit {
  @Input () defaultThresholdsList;
  @Input () currentUser: string;
  @Input () patientId : string;

  severities = [];
  actionCategories = [];
  predefinedActions: [];

  defaultThreshold: DefaultThreshold;
  defaultThresholdForm: FormGroup;

  displaySaveBtn = false;
  displayEditBtn = true;
  displayCollapseBtn = true;
  isExpanded = true;
  displayDeleteBtn = false;
  displayAddThresholdBtn = false;


  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _matDialog: MatDialog,
    private thresholdService: ThresholdService,
    private measurementTypeService: MeasurementTypeService,
    private severityService: SeverityService,
    private actionCategoryService: ActionCategoryService,
    private predefinedActionsService: PredefinedActionsService,
    private _formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private _matSnackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    
  }

  ngOnInit() : void {
    this.getSeverities();
    this.getActionCategory();
    this.getPredefinedAction(this.currentUser);
    this.defaultThresholdForm = this.createDefaultThresholdForm();
    this.displayDefaultThreshold();

    this.defaultThresholdForm.disable();
  }
  
  createDefaultThresholdForm(): FormGroup {
    return this._formBuilder.group({
      defaultThresholds: this._formBuilder.array([])
    });
  }

  displayDefaultThreshold() {
    this.defaultThresholdsList.Threshold.map(threshold => {
      this.defaultThresholds().push(this.prepareForDisplayDefaultThreshold(threshold.limitLow, threshold.limitHigh, threshold.SeverityId, threshold.action))
    })
  }

  getSeverities(): void {
    this.severityService.getSeverities().then((arg) => (this.severities = arg));
  }

  getActionCategory(): void {
    this.actionCategoryService.getActionCategories()
      .then(arg => {
        this.actionCategories = arg;
      });
  }

  getPredefinedAction(UserId): void {
    this.predefinedActionsService.getPredefinedActionsByUser(UserId).then((arg) => {
      this.predefinedActions = arg;
    });
  }

  defaultThresholds(): FormArray {
    return this.defaultThresholdForm.get('defaultThresholds') as FormArray;
  }

  prepareForDisplayDefaultThreshold(limitLow, limitHigh, SeverityId, action) {
    return this._formBuilder.group({
      limitLow: limitLow,
      limitHigh: limitHigh,
      SeverityId: SeverityId,
      action: action
    });
  }

  newDefaultThreshold(): FormGroup {
    return this._formBuilder.group({
      limitLow: '',
      limitHigh: '',
      SeverityId: '',
      action: '',
    });
  }

  allowEdit() : void {
    this.defaultThresholdForm.enable();
    this.displaySaveBtn = true;
    this.displayEditBtn = false;
    this.displayDeleteBtn = true;
    this.toggleDisplayAddThresholdBtn();
  }

  addNewDefaultThreshold(): void {
    this.defaultThresholds().push(this.newDefaultThreshold());
    this.toggleDisplayAddThresholdBtn();
  }

  removeNewDefaultThreshold(i: number): void {
    this.defaultThresholds().removeAt(i);
    this.toggleDisplayAddThresholdBtn();
  }

  toggleDisplayAddThresholdBtn(): void {
    if (this.defaultThresholds().length == 5) {
      this.displayAddThresholdBtn = false;
    } else {
      this.displayAddThresholdBtn = true;
    }
  }


  /**
   * Add threshold
   */
   addThreshold(): void {
    const data = this.defaultThresholdForm.getRawValue();

    let i = 0;
    data.defaultThresholds.forEach((threshold) => {
      if (threshold.action == '') {
        threshold.action = null;
      }
      threshold.ActionCategoryId = env.action_out_of_norm;
      if (threshold.SeverityId == env.severityOk) {
        threshold.ActionCategoryId = env.action_normal;
      }

      threshold.MeasurementTypeId = this.defaultThresholdsList.id;
      threshold.UserId = this.patientId;

      this.thresholdService
        .addThreshold(threshold)
        .then(() => {
          // Trigger the subscription with new data
          // this.thresholdService.onThresholdChanged.next(data);

          i++;
          if (i === data.defaultThresholds.length) {
            this.thresholdService.onThresholdsChanged.next(data);

            // Show the success message
            this._matSnackBar.open('Threshold added', 'OK', {
              verticalPosition: 'top',
              duration: 2000
            });
          }
        })
        .catch((error) => {
          // Show the success message
          this._matSnackBar.open('Threshold error', error.error.message, {
            verticalPosition: 'top',
            duration: 2000
          });
        });
    });
  }
}