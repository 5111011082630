// Models
import { ModalMeasurementComponent } from '../modal/measurement/measurement.component';

// Services
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { LocalStorageService } from '../../../service/local-storage.service';
import { MeasurementService } from 'app/shared/service/measurement.service';

// Others
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'app-patient-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss'],

  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class MeasurementsComponent implements OnInit {
  dataSource: FilesDataSource | null;
  length: number;
  pageSize;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  displayedColumns = [
    'measurementType',
    'date',
    'value'
  ];

  durations = [
    { name: 'Filter by date', value: 365 },
    { name: 'Today', value: 1 },
    { name: 'Last 7 days', value: 7 },
    { name: 'Last 15 days', value: 15 },
    { name: 'Last 30 days', value: 30 },
    { name: 'Current month', value: 99 }
  ];

  selectedDuration = { name: 'Filter by date', value: 365 };
  form: UntypedFormGroup;
  sensorType: string = null;
  sensorTypes = [];
  filterBy = [];
  filterActive: any = 'all'

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private measurementService: MeasurementService,
    private _matDialog: MatDialog,
    private _fuseSidebarService: FuseSidebarService,
    private localStorageService: LocalStorageService
  ) {
    this.form = new UntypedFormGroup({
      selectedDuration: new UntypedFormControl(this.selectedDuration)
    });
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource = new FilesDataSource(
      this.measurementService,
      this.paginator,
      this.sort
    );

    this.getPageSize();

    if (this.filter != null) {
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(150),
          distinctUntilChanged()
        )
        .subscribe(() => {
          if (!this.dataSource) {
            return;
          }

          this.filter = this.filter.nativeElement.value;
        });
    }
    this.getSensorType();
  }

  ngOnDestroy(): void {
    // reset filter to default value
    this.filterBy = [];
    this.measurementService.onFilterChanged.next(this.filterBy);
  }

  getDuration(duration: any): void {
    this.measurementService.getMeasurementFilteredByDate(duration.value);
  }

  actionModal(measurement: any): void {
    this._matDialog.open(ModalMeasurementComponent, {
      panelClass: 'description-dialog',
      data: {
        measurement: measurement
      }
    });
  }

  compareWith(item: any, selected: any): boolean {
    if (item.name === '') {
      return false;
    }
    return item.name == selected.name;
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }


  /**
   * Change the filter Side
   *
   * @param filter
   */
  changeFilter(filter): void {
    if (filter == 'all') {
      this.filterBy = [];
      this.filterActive = filter;
    } else if (typeof filter == 'object') {
      this.filterBy = filter;
      this.filterActive = filter;
    } else {
      this.filterBy = [filter];
      this.filterActive = filter;
    }

    this.measurementService.onFilterChanged.next(this.filterBy);
    this.paginator.pageIndex = 0; // Reset paginator when we change filter
  }

  getPageSize(): void {
    this.pageSize = this.localStorageService.get('pageSize');
  }

  setPageSize(pageEvent): void {
    const newPageSize = pageEvent.pageSize;
    this.localStorageService.set('pageSize', newPageSize);
  }

  getSensorType(): void {
    let sensorTypeId = [];

    this.dataSource.filteredData.map(data => {
      if (data.MeasurementTypeId === 0 && !sensorTypeId.includes('blood pressure / pulse')) {
        sensorTypeId.push('blood pressure / pulse');
        let sensorType = {
          "name": "blood pressure monitor",
          "id": [2, 3, 4]
        }
        this.sensorTypes.push(sensorType);
      } else if (data.MeasurementTypeId === -1 && !sensorTypeId.includes('spo2 / pulse')) {
        sensorTypeId.push('spo2 / pulse');
        let sensorType = {
          "name": "oximeter",
          "id": [4, 6]
        }
        this.sensorTypes.push(sensorType);
      } else if (data.MeasurementTypeId === 8 && !sensorTypeId.includes('thermometer')) {
        sensorTypeId.push('thermometer');
        let sensorType = {
          "name": "thermometer",
          "id": [8]
        }
        this.sensorTypes.push(sensorType);
      } else if (data.MeasurementTypeId !== 0 && data.MeasurementTypeId !== 8 && data.MeasurementTypeId !== -1 && !sensorTypeId.includes(data?.Device?.SensorType?.name)) {
        sensorTypeId.push(data?.Device?.SensorType?.name);
        let sensorType = {
          "name": data.MeasurementType.measurementType,
          "id": data.MeasurementTypeId
        }
        this.sensorTypes.push(sensorType);
      }
    });
  }
}

export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');
  /**
   * Constructor
   *
   * @param {MeasurementService} measurementService
   * @param {MeasurementTypeService} measurementTypeService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private measurementService: MeasurementService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    this.filteredData = this.measurementService.measurements;
  }
  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.measurementService.onMeasurementsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = this.measurementService.measurements.slice();

        data = this.filterData(data);

        this.filteredData = [...data];

        data = this.sortData(data);

        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Length data
   *
   * @param data
   * @returns {any[]}
   */
  lengthData(data): any {
    return data.length;
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'measurementType':
          [propertyA, propertyB] = [a.MeasurementTypeId, b.MeasurementTypeId];
          break;
        case 'date':
          [propertyA, propertyB] = [a.dateTime, b.dateTime];
          break;
        case 'value':
          [propertyA, propertyB] = [a.SeverityId, b.SeverityId];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void { }
}
