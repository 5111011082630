import { TranslationGlobalService } from 'app/shared/service/translationGlobal.service';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorTranslate extends MatPaginatorIntl {

  constructor(private _translationService: TranslationGlobalService){
    super()
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  itemsPerPageLabel = this.capitalize(this._translationService.getTranslation('items per page :'));
  nextPageLabel = this._translationService.getTranslation('next page');
  previousPageLabel = this._translationService.getTranslation('previous page');

  getRangeLabel = (page: number, pageSize: number, length: number) => {

    const linkWord = this._translationService.getTranslation('of');

    if (length === 0 || pageSize === 0){ return `0 ${linkWord} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex} ${linkWord} ${length}`;
  };

}