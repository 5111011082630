import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Customer } from '../model/customer';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerService implements Resolve<any> {
  customer: Customer;
  customers: Customer[];
  routeParams: any;
  onCustomerChanged: BehaviorSubject<any>;
  onCustomersChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  filterBy: any;

  constructor(private http: HttpClient) {
    this.onCustomersChanged = new BehaviorSubject({});
    this.onCustomerChanged = new BehaviorSubject({});
    this.onFilterChanged = new Subject();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getCustomer()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getCustomers()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Customers ---------------------------
  /**
   * Get customers
   *
   * @returns {Promise<any>}
   */
  getCustomers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'customers').subscribe((response: any) => {
        this.customers = response;
        this.onCustomersChanged.next(this.customers);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Customer ---------------------------
  /**
   * Get customer
   *
   * @returns {Promise<any>}
   */
  getCustomer(id?): Promise<any> {
    // get customer details in device
    if (id) {
      return new Promise((resolve, reject) => {
        this.http
          .get(env.apiUrl + 'customers/' + id)
          .subscribe((response: any) => {
            this.customer = response;
            this.onCustomerChanged.next(this.customer);
            resolve(response);
          }, reject);
      });
    }
    // get customer details
    else {
      return new Promise((resolve, reject) => {
        if (this.routeParams.id === 'new') {
          this.onCustomerChanged.next(false);
          resolve(false);
        } else {
          this.http
            .get(env.apiUrl + 'customers/' + this.routeParams.id)
            .subscribe((response: any) => {
              this.customer = response;
              this.onCustomerChanged.next(this.customer);
              resolve(response);
            }, reject);
        }
      });
    }
  }

  /**
   * Save customer
   *
   * @param customer
   * @returns {Promise<any>}
   */
  saveCustomer(customer, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'customers/edit/' + id, customer)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => { // Error callback
          resolve({
            error: error.error
          })
        });
    });
  }

  /**
 * Save customer
 *
 * @param customer
 * @returns {Promise<any>}
 */
  saveCustomerFirstDeviceModel(customerId, deviceModelFirst): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'customers/editFirst/' + customerId, deviceModelFirst)
        .subscribe((response: any) => {
          this.getCustomer(customerId)
            .then(customer => {
              this.onCustomerChanged.next(customer);
            });
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add customer
   *
   * @param customer
   * @returns {Promise<any>}
   */
  addCustomer(customer): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'customers/add', customer)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => { // Error callback
          resolve({
            error: error.error
          })
        });
    });
  }

  /**
   * delete customer
   *
   *  @returns {Promise<any>}
   */
  deleteCustomer(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'customers/delete/' + id)
        .subscribe((response: any) => {
          if(response.message == "Customer deleted") {
            this.customers = this.customers.filter(
              (customer) => customer.id != id
            );
          }
         
          this.onCustomersChanged.next(this.customers);
          resolve(response);
        }, reject);
    });
  }

  /**
   * generate APIToken
   * 
   * @returns {Promise<any>}
   */
  generateApiToken(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'api/auth/token/' + id, {})
        .subscribe((response: any) => {
          this.getCustomer(id)
            .then((customer) => {
              this.onCustomerChanged.next(customer);
            });
          resolve(response);
        }, reject);
    });
  }

  /**
 * delete customer
 *
 *  @returns {Promise<any>}
 */
  deleteDeviceModelFirstMode(id, customerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'customers/deleteDeviceModelFirstMode/' + id)
        .subscribe((response: any) => {
          this.getCustomer(customerId)
            .then(customer => {
              this.onCustomerChanged.next(customer);
            });
          resolve(response);
        }, reject);
    });
  }
}
