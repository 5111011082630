import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MeasurementType } from '../model/measurement-type';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeasurementTypeService {
  measurementType: MeasurementType;
  measurementTypes: MeasurementType[];
  routeParams: any;
  onMeasurementTypeChanged: BehaviorSubject<any>;
  onMeasurementTypesChanged: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.onMeasurementTypeChanged = new BehaviorSubject({});
    this.onMeasurementTypesChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getMeasurementType()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getMeasurementTypes()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ MeasurementTypes ---------------------------
  /**
   * Get measurementTypes
   *
   * @returns {Promise<any>}
   */
  getMeasurementTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'measurementTypes')
        .subscribe((response: any) => {
          this.measurementTypes = response;
          this.onMeasurementTypesChanged.next(this.measurementTypes);
          resolve(response);
        }, reject);
    });
  }

  getCustomerMeasurementType(CustomerId) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'measurementTypes/customer/' + CustomerId)
        .subscribe((response: any) => {
          this.measurementTypes = response;
          this.onMeasurementTypesChanged.next(this.measurementTypes);
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ MeasurementType ---------------------------
  /**
   * Get measurementType
   *
   * @returns {Promise<any>}
   */
  getMeasurementType(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onMeasurementTypeChanged.next(false);
        resolve(false);
      } else {
        this.http
          .get(env.apiUrl + 'measurementTypes/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.measurementType = response;
            this.onMeasurementTypeChanged.next(this.measurementType);
            resolve(response);
          }, reject);
      }
    });
  }

  /**
   * Save measurementType
   *
   * @param measurementType
   * @returns {Promise<any>}
   */
  saveMeasurementType(measurementType, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'measurementTypes/edit/' + id, measurementType)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Add measurementType
   *
   * @param measurementType
   * @returns {Promise<any>}
   */
  addMeasurementType(measurementType): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'measurementTypes/add', measurementType)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }
}
