import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { PatientService } from 'app/shared/service/patient.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'app-patient-logs',
  templateUrl: './patient-logs.component.html',
  styleUrls: ['./patient-logs.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class PatientLogsComponent implements OnInit {
  dataSource: FilesDataSource | null;
  length: number;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  displayedColumns = ['date', 'action', 'createdBy'];

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor( private patientService: PatientService ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource = new FilesDataSource(
      this.patientService,
      this.paginator,
      this.sort
    );

    if (this.filter != null) {
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(150),
          distinctUntilChanged()
        )
        .subscribe(() => {
          if (!this.dataSource) {
            return;
          }

          this.filter = this.filter.nativeElement.value;
        });
    }
  }
}

export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {PatientService} patientService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private patientService: PatientService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    this.filteredData = this.patientService.patientLogs;
  }
  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.patientService.onPatientsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data;

        if(this.patientService.patientLogs[0] != undefined){
          data = this.patientService.patientLogs.slice();

          data = this.filterData(data);
  
          this.filteredData = [...data];
  
          data = this.sortData(data);
        } else {
          data = [];
        }
        

        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Length data
   *
   * @param data
   * @returns {any[]}
   */
  lengthData(data): any {
    return data.length;
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'message':
          [propertyA, propertyB] = [a.message, b.message];
          break;
        case 'date':
          [propertyA, propertyB] = [a.date, b.date];
          break;
        case 'createdby':
          let aName = a.CreatedBy.title+' '+a.CreatedBy.firstName+' '+ a.CreatedBy.lastName;
          let bName = b.CreatedBy.title+' '+b.CreatedBy.firstName+' '+ b.CreatedBy.lastName;
          [propertyA, propertyB] = [aName.toLowerCase().trim(), bName.toLowerCase().trim()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {}
}
