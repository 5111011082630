import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '../../../../../service/action.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PatientService } from '../../../../../service/patient.service';
import { PhysicianService } from '../../../../../service/physician.service';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupName, Validators } from '@angular/forms';
import { Action } from '../../../../../model/action';
import { ConnectorService } from '../../../../../auth/connector.service';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { PredefinedActionsService } from '../../../../../service/predefined-actions.service';
import { PredefinedNotesService } from '../../../../../service/predefined-notes.service';
import { NoteService } from '../../../../../service/note.service';
import { ActionCategoryService } from '../../../../../service/action-category.service';
import { HistoryService } from '../../../../../service/history.service';
import * as moment from "moment";

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ActionModalComponent implements OnInit {
  actionType: string;
  patients: [];
  physicians: [];
  actionForm: UntypedFormGroup;
  noteForm: UntypedFormGroup;
  assignationForm: UntypedFormGroup;
  actionCompletedForm: UntypedFormGroup;
  action: Action;
  assignedTo: string;
  id: string;
  patientId: string;
  currentUser: string;
  predefinedActions: [];
  predefinedActionSelected: boolean;
  predefinedNotes: [];
  predefinedNoteSelected: boolean;
  actionCategories: [];
  userConnected;

  constructor(
    public matDialogRef: MatDialogRef<ActionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private actionService: ActionService,
    private _matSnackBar: MatSnackBar,
    private router: Router,
    private patientService: PatientService,
    private physicianService: PhysicianService,
    private _formBuilder: UntypedFormBuilder,
    private connectorService: ConnectorService,
    private predefinedActionsService: PredefinedActionsService,
    private predefinedNotesService: PredefinedNotesService,
    private noteService: NoteService,
    private actionCategoryService: ActionCategoryService,
    private historyService: HistoryService
  ) {
    this.actionType = _data.actionType;
    this.assignedTo = _data.assignTo;
    this.id = _data.id;
    // console.log(`------------------------------------ _data ------------------------------------`);
    // console.log(_data);
    if (this.router.url === '/apps/patients') {
      
      this.patientId = _data.patient.UserId;
    } else {
      this.patientId = _data.patientId;
    }
    this.connectorService.getFullUser.subscribe(user => {
      this.userConnected = user;
      this.currentUser = user.id;
      if (this.userConnected.HealthCareProviderId !== null) {
        this.physicianService.getPhysiciansByHealthCareProvider(this.userConnected.HealthCareProviderId).then((arg) => {
          this.physicians = arg;
        });
      } else {
        this.physicianService.getPhysicians().then((arg) => {
          this.physicians = arg;
        });
      }
    });
  }

  ngOnInit(): void {


    this.getPatients();
    switch (this._data.actionType) {
      case 'actionCreation':
        this.actionForm = this.createActionForm();
        this.getActionCategories();
        this.getPredefinedAction(this.currentUser);
        break;
      case 'noteCreation':
        this.noteForm = this.createNoteForm();
        this.getPredefinedNote(this.currentUser);
        break;
      case 'actionAssignation':
        this.assignationForm = this.createAssignationForm();
        break;
      case 'actionCompleted':
        this.actionCompletedForm = this.createCompletedTaskForm();
        this.getPredefinedNote(this.currentUser);
        break;
      case 'createHistory':
        this.actionCompletedForm = this.createCompletedTaskForm();
        this.getPredefinedNote(this.currentUser);
        break;
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  createActionForm(): UntypedFormGroup {
    return this._formBuilder.group(
      {
        AssignToId: ['', Validators.required],
        action: [''],
        predefinedAction: [''],
        actionCategory: ['', Validators.required],
        dueDate: [new Date(), Validators.required]
      }
    );
  }

  createNoteForm(): UntypedFormGroup {
    return this._formBuilder.group({
      predefinedNote: [''],
      note: ['']
    });
  }

  createAssignationForm(): UntypedFormGroup {
    return this._formBuilder.group({
      AssignToId: [this.assignedTo, Validators.required]
    });
  }

  createCompletedTaskForm(): UntypedFormGroup {
    return this._formBuilder.group({
      minutes: ['2'],
      seconde: ['00'],
      predefinedNote: [''],
      note: ['']
    });
  }

  addAction(): void {
    const dueDate = moment(this.actionForm.value.dueDate).format("YYYY-MM-DD, hh:mm:ss");
    const dataform = {
      PatientId: this.patientId,
      AssignById: this.currentUser,
      AssignToId: this.actionForm.value.AssignToId,
      action: '',
      dueDate: dueDate,
      HealthCareProviderId: this.userConnected.HealthCareProviderId,
      ActionStatusId: 1, // === new
      ActionCategoryId: this.actionForm.value.actionCategory
    };
    if (this.predefinedActionSelected === true) {
      dataform.action = this.actionForm.value.predefinedAction;
    } else {
      dataform.action = this.actionForm.value.action;
    }

    this.actionService.addAction(dataform)
      .then(() => {
        // Trigger the subscription with new data
        this.actionService.onActionChanged.next(dataform);
        window.location.reload(); 
        // Show the success message
        this._matSnackBar.open('Action created', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
        this.matDialogRef.close();
        this.reloadCurrentRoute();
      })
      .catch((error) => {
        // Show the success message
        this._matSnackBar.open('Action create failed', error.error.message, {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  saveAssignation(): void {
    const data = {
      AssignById: this.currentUser,
      AssignToId: this.assignationForm.getRawValue().AssignToId,
    };
    // AssignById: this.currentUser
    this.actionService.saveAction(data, this.id)
      .then(() => {
        // Trigger the subscription with new data
        this.actionService.onActionChanged.next(data);

        // Show the success message
        this._matSnackBar.open('Assignation saved', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });

        this.matDialogRef.close();
      })
      .catch((error) => {
        // Show the success message
        this._matSnackBar.open('Assignation error', error.error.message, {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }

  createNote(): void {
    const dataForm = {
      MedicalStaffId: this.currentUser,
      UserId: this.patientId,
      note: '',
      createdAt: new Date(),
      updatedAt: new Date()
    };

    if (this.predefinedNoteSelected === true) {
      dataForm.note = this.noteForm.value.predefinedNote;
    } else {
      dataForm.note = this.noteForm.value.note;
    }

    if (dataForm.note !== '') {
      this.noteService.addNote(dataForm).then(() => {
        // Trigger the subscription with new data
        this.noteService.onNoteChanged.next(dataForm);
        // Show the success message
        this._matSnackBar.open('Note saved', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
        this.matDialogRef.close();
      })
        .catch((error) => {
          // Show the success message
          this._matSnackBar.open('Note error', error.error.message, {
            verticalPosition: 'top',
            duration: 2000
          });
        });
    } else {
      this.noteService.onNoteChanged.next(dataForm);
      // Show the success message
      this._matSnackBar.open('Note unsaved because empty', 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.matDialogRef.close();
    }
  }

  completeTask(): void {
    const actionToSave = {
      ActionStatusId: 4
    };

    const formValue = this.actionCompletedForm.getRawValue();
    let duration;
    const date = new Date();
    let start;

    const minutes = formValue.minutes !== null ? formValue.minutes * 60 : 0;
    duration = Number(minutes) + Number(formValue.seconde);
    date.setMinutes(date.getMinutes() - formValue.minutes);
    start = date.setSeconds(date.getSeconds() - formValue.seconde);

    const end = new Date();
    const newHistory = {
      MedicalStaffId: this.currentUser,
      UserId: this.patientId,
      start: start,
      end: end,
      duration: duration,
      mode: 'manual'
    };

    if (formValue.predefinedNote !== '') {
      formValue.note = formValue.predefinedNote
    } else {
      formValue.note = formValue.note
    }

    this.historyService.stopHistory(newHistory).then(response => {
      if (this.actionType === 'actionCompleted') {
        this.actionService.saveAction(actionToSave, this.id)
          .then(response => {
            if (formValue.note.length > 0) {
              const newNote = {
                MedicalStaffId: this.currentUser,
                UserId: this.patientId,
                note: formValue.note,
              };
              this.noteService.addNote(newNote).then(() => {
                // Trigger the subscription with new data
                this.noteService.onNoteChanged.next(newNote);
                // Show the success message
                this._matSnackBar.open('Actions completed & Note saved', 'OK', {
                  verticalPosition: 'top',
                  duration: 2000
                });
                this.matDialogRef.close();
              })
                .catch((error) => {
                  // Show the success message
                  this._matSnackBar.open('Note error', error.error.message, {
                    verticalPosition: 'top',
                    duration: 2000
                  });
                });
            } else {
              this._matSnackBar.open('Actions completed', 'OK', {
                verticalPosition: 'top',
                duration: 2000
              });
              this.matDialogRef.close();
              this.reloadCurrentRoute();
            }
          })
          .catch((error) => {
            // Show the success message
            this._matSnackBar.open('Actions error', error.error.message, {
              verticalPosition: 'top',
              duration: 2000
            });
          });
      } else {
        if (formValue.note.length > 0) {
          const newNote = {
            MedicalStaffId: this.currentUser,
            UserId: this.patientId,
            note: formValue.note,
          };
          this.noteService.addNote(newNote).then(() => {
            // Trigger the subscription with new data
            this.noteService.onNoteChanged.next(newNote);
            // Show the success message
            this._matSnackBar.open('History saved & Note saved', 'OK', {
              verticalPosition: 'top',
              duration: 2000
            });
            this.matDialogRef.close();
          })
            .catch((error) => {
              // Show the success message
              this._matSnackBar.open('Note error', error.error.message, {
                verticalPosition: 'top',
                duration: 2000
              });
            });
        } else {
          this._matSnackBar.open('History saved', 'OK', {
            verticalPosition: 'top',
            duration: 2000
          });
          this.matDialogRef.close();
        }
      }
    });
  }

  getPatients(): void {
    this.patientService.getPatients().then((arg) => {
      this.patients = arg;
    });
  }

  getPredefinedAction(UserId): void {
    this.predefinedActionsService.getPredefinedActionsByUser(UserId).then((arg) => {
      this.predefinedActions = arg;
    });
  }

  getPredefinedNote(UserId): void {
    this.predefinedNotesService.getPredefinedNotesByUser(UserId).then((arg) => {
      this.predefinedNotes = arg;
    });
  }

  getActionCategories(): void {
    this.actionCategoryService.getActionCategories().then((arg) => {
      this.actionCategories = arg;
    });
  }

  isPredefinedActionSelected({ value }) {
    if (this.actionForm.get('predefinedAction').value !== '') {
      this.predefinedActionSelected = true;
      this.actionForm.get('action').disable();
    } else if (this.actionForm.get('action').value !== '' && this.actionForm.get('predefinedAction').value === '') {
      this.predefinedActionSelected = false;
      this.actionForm.get('predefinedAction').disable();
      this.actionForm.get('action').enable();
      this.actionForm.get('action').validator;
    } else {
      this.actionForm.get('action').enable();
      this.actionForm.get('predefinedAction').enable();
    }
  }


  isPredefinedNoteSelected({ value }) {
    if (this.noteForm.get('predefinedNote').value !== '') {
      this.predefinedNoteSelected = true;
      this.noteForm.get('note').disable();
    } else if (this.noteForm.get('note').value !== '' && this.noteForm.get('predefinedNote').value === '') {
      this.predefinedNoteSelected = false;
      this.noteForm.get('predefinedNote').disable();
      this.noteForm.get('note').enable();
    } else {
      this.noteForm.get('note').enable();
      this.noteForm.get('predefinedNote').enable();
    }
  }

  isPredefinedNoteinCompleteTaskSelected({ value }) {
    if (this.actionCompletedForm.get('predefinedNote').value !== '') {
      this.predefinedNoteSelected = true;
      this.actionCompletedForm.get('note').disable();
    } else if (this.actionCompletedForm.get('note').value !== '' && this.actionCompletedForm.get('predefinedNote').value === '') {
      this.predefinedNoteSelected = false;
      this.actionCompletedForm.get('note').enable();
      this.actionCompletedForm.get('predefinedNote').disable();
    } else {
      this.actionCompletedForm.get('note').enable();
      this.actionCompletedForm.get('predefinedNote').enable();
    }
  }

}
