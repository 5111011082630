import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

           
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                // translate: 'NAV.CUSTOMER',
                type     : 'item',
                icon     : 'receipt',
                url      : 'apps/'
            },
        ];
