export class Note {
    id: number;
    MedicalStaffId: number;
    UserId: number;
    note: string;
    medical: boolean;
    createdAt: any;
    updatedAt: any;

/**
 * Constructor
 *
 * @param note
 */
constructor(note?) {
    note = note || {};
    this.MedicalStaffId = note.MedicalStaffId || '';
    this.UserId = note.UserId || '';
    this.note = note.note || '';
    this.medical = note.medical || true;
}
}
