import { Component, forwardRef, OnInit, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  ControlContainer,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';

export interface TwofactorConfigFormValues {
  twoFactor: boolean;
  twoFactorDefaultMethod: string;
  timeoutMinutes: number;
}
@Component({
  selector: 'app-twofactor-config-form',
  templateUrl: './twofactor-config.component.html',
  styleUrls: ['./twofactor-config.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TwofactorConfigComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TwofactorConfigComponent),
      multi: true
    }
  ]
})
export class TwofactorConfigComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  form: UntypedFormGroup;
  parentForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    this.parentForm = <UntypedFormGroup> this.controlContainer.control;

    this.form = this.createTwoFactorForm();
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  /**
   * Create form
   *
   * @returns {FormGroup}
   */
  createTwoFactorForm(): UntypedFormGroup {
    return this.formBuilder.group({
      twoFactor: false,
      twoFactorDefaultMethod: ['email'],
      timeoutMinutes: ['', Validators.required]
    });
  }

  get value(): TwofactorConfigFormValues {
    return this.form.value;
  }

  set value(value: TwofactorConfigFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { twofactor: { valid: false } };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
