import { SpecificRightComponent } from './../app/shared/component/specific-right/specific-right.component';
import { NotesComponent } from './../app/shared/component/patient/notes/notes.component';
import { StatMeasurementByPatientComponent } from './../app/shared/component/stat/stat-measurement-by-patient/stat-measurement-by-patient.component';
import { StatsComponent } from './../app/shared/component/patient/stats/stats.component';
import { StatBySeverityComponent } from './../app/shared/component/stat/stat-by-severity/stat-by-severity.component';
import { StatFlatChartDashboardComponent } from './../app/shared/component/stat/stat-flat-chart-dashboard/stat-flat-chart-dashboard.component';
import { StatUsersCountDashboardComponent } from './../app/shared/component/stat/stat-users-count-dashboard/stat-users-count-dashboard.component';
import { StatPieChartDashboardComponent } from './../app/shared/component/stat/stat-pie-chart-dashboard/stat-pie-chart-dashboard.component';
import { StatHistoChartDashboardComponent } from './../app/shared/component/stat/stat-histo-chart-dashboard/stat-histo-chart-dashboard.component';
import { LogFormatterComponent } from './../app/shared/component/log/log-formatter/log-formatter.component';
import { DevicesComponent } from './../app/shared/component/patient/devices/devices.component';
import { HubsComponent } from './../app/shared/component/patient/hubs/hubs.component';
import { ReportsComponent } from './../app/shared/component/patient/reports/reports.component';
import { ThresholdsComponent } from './../app/shared/component/patient/thresholds/thresholds.component';
import { ThresholdComponent } from './../app/shared/component/patient/threshold/threshold.component';
import { DefaultThresholdComponent } from './../app/shared/component/patient/default-threshold/default-threshold.component';
import { HistoriesComponent } from './../app/shared/component/patient/histories/histories.component';
import { MeasurementsComponent } from 'app/shared/component/patient/measurements/measurements.component';
import { PatientLogsComponent } from './../app/shared/component/patient/patient-logs/patient-logs.component';
import { AddressComponent } from 'app/shared/component/address/address.component';
import { TwofactorConfigComponent } from 'app/shared/component/twofactor-config/twofactor-config.component';
import { PhysicianDefaultThresholdsComponent } from 'app/shared/component/physician-default-thresholds/physician-default-thresholds/physician-default-thresholds.component'
import { PhysicianDefaultThresholdComponent } from 'app/shared/component/physician-default-thresholds/physician-default-threshold/physician-default-threshold.component'
import { PhysicianCustomDefaultThresholdComponent} from 'app/shared/component/physician-default-thresholds/physician-custom-default-threshold/physician-custom-default-threshold.component'
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { MaterialModule } from 'app/shared/material/material.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PatientComponent } from '../app/shared/component/dashboard/patient/patient.component';
import { PhysicianComponent } from '../app/shared/component/dashboard/physician/physician.component';
import { CustomerComponent } from '../app/shared/component/dashboard/customer/customer.component';
import { RouterModule } from '@angular/router';
import { HealthCareProviderComponent } from '../app/shared/component/dashboard/health-care-provider/health-care-provider.component';
import {HubComponent} from '../app/shared/component/dashboard/hub/hub.component';
import {FuseSidebarModule} from './components';
import {ActionComponent} from '../app/shared/component/dashboard/action/action/action.component';
import {ActionModalComponent} from '../app/shared/component/dashboard/action/modal/action-modal/action-modal.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime-ex';

@NgModule({
  declarations: [
    MeasurementsComponent,
    MeasurementsComponent,
    HistoriesComponent,
    AddressComponent,
    TwofactorConfigComponent,
    ThresholdsComponent,
    ThresholdComponent,
    DefaultThresholdComponent,
    DevicesComponent,
    HubsComponent,
    ReportsComponent,
    NotesComponent,
    PatientLogsComponent,
    StatsComponent,
    StatBySeverityComponent,
    StatMeasurementByPatientComponent,
    StatFlatChartDashboardComponent,
    StatUsersCountDashboardComponent,
    StatPieChartDashboardComponent,
    StatHistoChartDashboardComponent,
    LogFormatterComponent,
    PatientComponent,
    PhysicianComponent,
    CustomerComponent,
    SpecificRightComponent,
    HealthCareProviderComponent,
    HubComponent,
    ActionComponent,
    ActionModalComponent,
    ActionComponent,
    PhysicianDefaultThresholdsComponent,
    PhysicianDefaultThresholdComponent,
    PhysicianCustomDefaultThresholdComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    NgxChartsModule,
    RouterModule,
    FuseSidebarModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    FuseDirectivesModule,
    FusePipesModule,
    MeasurementsComponent,
    HistoriesComponent,
    ThresholdsComponent,
    ThresholdComponent,
    DefaultThresholdComponent,
    HubsComponent,
    ReportsComponent,
    DevicesComponent,
    AddressComponent,
    TwofactorConfigComponent,
    NotesComponent,
    PatientLogsComponent,
    StatsComponent,
    StatBySeverityComponent,
    StatMeasurementByPatientComponent,
    StatFlatChartDashboardComponent,
    StatUsersCountDashboardComponent,
    StatPieChartDashboardComponent,
    StatHistoChartDashboardComponent,
    LogFormatterComponent,
    PatientComponent, // dashboard patient list
    PhysicianComponent, // dashboard patient list
    CustomerComponent,
    SpecificRightComponent,
    HealthCareProviderComponent,
    HubComponent,
    ActionComponent,
    ActionModalComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ActionComponent,
    PhysicianDefaultThresholdsComponent,
    PhysicianDefaultThresholdComponent,
    PhysicianCustomDefaultThresholdComponent
  ]
})
export class FuseSharedModule {}
