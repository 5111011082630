import { HubService } from 'app/shared/service/hub.service';
import { DeviceService } from 'app/shared/service/device.service';
import { PatientService } from 'app/shared/service/patient.service';
import { Hub } from '../../../../model/hub';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalHubComponent implements OnInit {
  hubs: Hub[];
  hub: Hub;
  pageType = '';
  hubForm: UntypedFormGroup;
  UserId: string;
  today = new Date();
  roleUserConnected;

  constructor(
    public matDialogRef: MatDialogRef<ModalHubComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hubService: HubService,
    private deviceService: DeviceService,
    private patientService: PatientService,
    private _formBuilder: UntypedFormBuilder,
    private _location: Location,
    private _matSnackBar: MatSnackBar
  ) {
    this.UserId = data.UserId;
    this.roleUserConnected = data.roleUserConnected
    this.pageType = data.pageType;
    this.hubs = data.hubs;
    if (this.pageType === 'new') {
      this.hub = new Hub();
    } else {
      this.hub = this.data.hub;
    }
  }

  ngOnInit(): void {
    if (this.pageType === 'new') {
      this.hubForm = this.createHubForm();
    } else {
      this.hubForm = this.createEditHubForm();
      this.hubForm.controls['dateStart'].disable();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  createHubForm(): UntypedFormGroup {
    return this._formBuilder.group({
      hubId: [this.hub.id, Validators.required]
    });
  }

  createEditHubForm(): UntypedFormGroup {
    return this._formBuilder.group({
      dateStart: [this.hub.dateStart, Validators.required],
      dateEnd: [this.hub.dateEnd, Validators.required]
    });
  }

  /**
   * Save Patient's Hub
   */
  saveHub(): void {
    const data = this.hubForm.getRawValue();
    data.UserId = this.UserId;
    data.dateStart = new Date();
    this.hubService.saveHub(data, data.hubId).then((hub) => {
      this.hubService.onHubChanged.next(data);

      this.deviceService.getDevice().then((device) => {
        this.deviceService.onDevicesChanged.next(device);

        this.patientService.setUnfollow(false, true)
          .then(() => {
            this.patientService.getPatient(this.UserId)
              .then(patient => {
                this.patientService.onPatientChanged.next(patient);
              })

            // Show the success message
            this._matSnackBar.open('Hub assigned', 'OK', {
              verticalPosition: 'top',
              duration: 2000
            });

            this.matDialogRef.close();
          })
          .catch(err => console.log(err))
      });
    });
  }

  editHub(): void {
    const data = {
      serialNumber: this.hub.serialNumber,
      id: this.hub.id,
      PatientToTerminate: this.hub.UserId,
      dateStart: this.hubForm.value.dateStart,
      dateEnd: this.hubForm.value.dateEnd
    };
    if (data.dateStart > data.dateEnd) {
      this._matSnackBar.open(
        'Please select a date end posterior to date start',
        'OK',
        {
          verticalPosition: 'top',
          duration: 2000
        }
      );
    } else if (data.dateEnd > this.today) {
      this._matSnackBar.open(
        'You cannot select a date end posterior to today',
        'OK',
        {
          verticalPosition: 'top',
          duration: 2000
        }
      );
    } else {
      this.hubService.saveHub(data, data.id).then(() => {
        this.hubService.onHubChanged.next(data);

        this.deviceService.getDevice().then((device) => {
          this.deviceService.onDevicesChanged.next(device);

          // Show the success message
          this._matSnackBar.open('Hub deallocated', 'OK', {
            verticalPosition: 'top',
            duration: 2000
          });

          this.matDialogRef.close();
        });
      });
    }
  }

  // Get the list of available hubs for the select drop down
  getFreeHubs(UserId: string): void {
    this.patientService.getPatient(UserId).then(patient => {
      this.hubService.getHubsFree().then((arg) => {
        this.hubs = arg.filter(hub => hub.HealthCareProviderId === patient.HealthCareProviderId);
      });
    });
  }

}
