import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Threshold } from '../model/threshold';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThresholdService implements Resolve<any> {
  threshold: Threshold;
  thresholds: any[];
  routeParams: any;
  onThresholdChanged: BehaviorSubject<any>;
  onThresholdsChanged: BehaviorSubject<any>;
  id;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {
    this.onThresholdsChanged = new BehaviorSubject({});
    this.onThresholdChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if ( state.url.includes('patients') && this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new' ) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getThresholdsAndDefaultThresholds(this.routeParams.id)]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else if ( state.url.includes('threshold') && state.url.includes(this.routeParams.id)) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getOneThreshold(this.routeParams.id)]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getThresholds()]).then((response) => {
          resolve(response);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Thresholds ---------------------------
  /**
   * Get thresholds
   *
   * @returns {Promise<any>}
   */
  getThresholds(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'thresholds').subscribe((response: any) => {
        this.thresholds = response;
        this.onThresholdsChanged.next(this.thresholds);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Threshold ---------------------------
  /**
   * Get threshold for one patient
   *
   * @returns {Promise<any>}
   */
  getThreshold(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'thresholds/user/' + id)
        .subscribe((response: any) => {
          this.thresholds = response;
          this.onThresholdChanged.next(this.thresholds);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get One threshold
   *
   * @returns {Promise<any>}
   */
  getOneThreshold(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'thresholds/' + id)
        .subscribe((response: any) => {
          this.thresholds = response;
          this.onThresholdChanged.next(this.thresholds);
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ Thresholds & Default Thresholds ---------------------------
  /**
   * Get thresholds & default thresholds
   *
   * @returns {Promise<any>}
   */
   getThresholdsAndDefaultThresholds(UserId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'thresholds/thresholdsAndDefaultThreshold/user/' + UserId).subscribe((response: any) => {
        this.thresholds = response;
        // this.onThresholdsChanged.next(this.thresholds);
        resolve(response);
      }, reject);
    });
  }

  /**
   * Save threshold
   *
   * @param threshold
   * @returns {Promise<any>}
   */
  saveThreshold(threshold, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'thresholds/edit/' + id, threshold)
        .subscribe((response: any) => {
          this.getThreshold(threshold.UserId).then((thresholds) => {
            this.onThresholdsChanged.next(thresholds);
            resolve(response);
          })
          .catch((error) => {
            catchError(this.errorService.handleError('save_threshold'));
          });
        }, reject);
    });
  }

  /**
   * Add threshold
   *
   * @param threshold
   * @returns {Promise<any>}
   */
  addThreshold(threshold): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'thresholds/add', threshold)
        .subscribe((response: any) => {
          this.getThreshold(threshold.UserId)
          .then((thresholds) => {
            this.onThresholdsChanged.next(thresholds);
            resolve(response);
          })
          .catch((error) => {
            catchError(this.errorService.handleError('add_threshold'));
          });
        }, reject);
    });
  }

  /**
   * delete threshold
   */
  deleteThreshold(id: any): void {
    this.http.delete(env.apiUrl + 'thresholds/delete/' + id).subscribe(() => {
      this.thresholds = this.thresholds.filter(
        (threshold) => threshold.id != id
      );
      this.onThresholdsChanged.next(this.thresholds);
    });
  }
}
