import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { HubService } from 'app/shared/service/hub.service';
import { ActivatedRoute } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { MatDialog } from '@angular/material/dialog';
import { ModalHubComponent } from '../modal/hub/hub.component';
import {ConnectorService} from '../../../auth/connector.service';
import {LocalStorageService} from '../../../service/local-storage.service';

@Component({
  selector: 'app-hubs',
  templateUrl: './hubs.component.html',
  styleUrls: ['./hubs.component.scss'],

  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class HubsComponent implements OnInit {
  dataSource: FilesDataSource | null;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  displayedColumns;
  authorization;
  pageSize;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private hubService: HubService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private connectorService: ConnectorService,
    private localStorageService: LocalStorageService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.authorization = this.connectorService.getAuthorization('hubs');

    
  }

  /**
   * On init
   */
  ngOnInit(): void {
    if (this.authorization.length < 1) {
      this.authorization = null;
      this.displayedColumns = ['serialNumber', 'dateStart', 'dateEnd', 'kit'];
    } else {
      this.displayedColumns = ['serialNumber', 'dateStart', 'dateEnd', 'kit', 'Action'];
    }
    this.dataSource = new FilesDataSource(
      this.hubService,
      this.paginator,
      this.sort
    );

    this.getPageSize();

    if (this.filter != null) {
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(150),
          distinctUntilChanged()
        )
        .subscribe(() => {
          if (!this.dataSource) {
            return;
          }

          this.filter = this.filter.nativeElement.value;
        });
    }
  }

  actionModal(hub: any): void {
    this._matDialog.open(ModalHubComponent, {
      panelClass: 'description-dialog',
      data: {
        pageType: 'edit',
        hub: hub
      }
    });
  }

  getPageSize(): void {
    this.pageSize = this.localStorageService.get('pageSize');
  }

  setPageSize(pageEvent): void {
    const newPageSize = pageEvent.pageSize;
    this.localStorageService.set('pageSize', newPageSize );
  }
}

export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {HubService} hubService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private hubService: HubService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();
    this.filteredData = this.hubService.hubs;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.hubService.onHubsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = [];
          data = this.hubService.hubs.slice();

          data = this.filterData(data);

          this.filteredData = [...data];

          data = this.sortData(data);
        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'serialNumber':
          [propertyA, propertyB] = [a.serialNumber, b.serialNumber];
          break;
        case 'dateStart':
          [propertyA, propertyB] = [a.dateStart, b.dateStart];
          break;
        case 'dateEnd':
          [propertyA, propertyB] = [a.dateEnd, b.dateEnd];
          break;
      }
      
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {}
}
