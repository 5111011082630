import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Hub } from '../model/hub';
import { ErrorService } from './error.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HubService {
  hub: Hub;
  hubs: any[];
  routeParams: any;
  onHubChanged: BehaviorSubject<any>;
  onHubsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  id;
  freeHubs;
  filterBy: any;
  public pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private route: ActivatedRoute,
  ) {
    this.onHubsChanged = new BehaviorSubject({});
    this.onHubChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.onFilterChanged = new Subject();
  }
  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (
      this.routeParams.hasOwnProperty('id') &&
      this.routeParams.id != 'new' &&
      state.url.includes('hubs')
    ) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getHubDetail()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else if (state.url.includes('patients') && this.routeParams.hasOwnProperty('id')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getHub()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else if (state.url.includes('dashboard/kitManager')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getAssignedHub()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getHubs()]).then(([]) => {
          this.onFilterChanged.subscribe((filter) => {
            this.filterBy = filter;
            this.getHubs();
          });
          resolve(null);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Hubs ---------------------------
  /**
   * Get hubs
   *
   * @returns {Promise<any>}
   */
  getHubs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'hubs').subscribe((response: any) => {
        this.freeHubs = response.filter(hub => hub.kit == false).length;
        this.hubs = response;
        if (this.filterBy && this.filterBy !== 'all') {
          this.hubs = this.hubs.filter(
            (data) => data.CustomerId === this.filterBy
          );
        }
        this.onHubsChanged.next(this.hubs);
        resolve(response);
      }, reject);
    });
  }

  /**
   * Get hubs for Select Drop Down
   *
   *
   * @returns {Promise<any>}
   */
  getHubsFree(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'hubs/unassignedHubs')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  // ------------------------------------------------ Hub ---------------------------

  /**
   * Get hubs for one patient
   *
   * @returns {Promise<any>}
   */
  getHub(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'hubs/user/' + this.routeParams.id)
        .subscribe((response: any) => {
          this.hubs = response;
          this.onHubChanged.next(this.hubs);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get assigned hub
   *
   * @returns {Promise<any>}
   */
  getAssignedHub(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'hubs/assignedHubs')
        .subscribe((response: any) => {
          this.hubs = response;
          this.onHubChanged.next(this.hubs);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Export hub
   *
   * @param hub
   * @returns {Promise<any>}
   */
  export(): Promise<any> {
    return new Promise((resolve, reject) =>
      this.http
        .get(env.apiUrl + 'hubs/export/all')
        .subscribe((response: any) => {
          resolve(response);
        }, reject)
    );
  }

  /**
   * Get hub detail
   *
   * @returns {Promise<any>}
   */
  getHubDetail(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'hubs/' + this.routeParams.id)
        .subscribe((response: any) => {
          this.hub = response;
          this.onHubChanged.next(this.hub);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Save hub
   *
   * @param hub
   * @returns {Promise<any>}
   */
  saveHub(hub, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'hubs/edit/' + id, hub)
        .subscribe((response: any) => {
          this.getHub()
            .then(hubs => {
              this.onHubsChanged.next(hubs);
              resolve(response);
            })
            .catch((err) => {
              catchError(this.errorService.handleError('save_hub'));
            });
        }, reject);
    });
  }

  /**
   * Add hub
   *
   * @param hub
   * @returns {Promise<any>}
   */
  addHub(hub): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'hubs/add', hub)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * delete hub
   *
   *  @returns {Promise<any>}
   */
  deleteHub(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(env.apiUrl + 'hubs/delete/' + id)
        .subscribe((response: any) => {
          this.hubs = this.hubs.filter((hub) => hub.id != id);
          this.onHubsChanged.next(this.hubs);
          resolve(response);
        }, reject);
    });
  }

  /**
   * setUnfollowHubs
   *
   *  @returns {Promise<any>}
   */
  setUnfollowHubs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hubs = [];
      this.onHubsChanged.next(this.hubs);
      resolve(true);
    });
  }

  /**
 * setFactoryReset
 *
 *  @returns {Promise<any>}
 */
  factoryReset(hub, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'hubs/factoryReset/' + id, hub)
        .subscribe((response: any) => {
          this.getHub()
            .then(hub => {
              this.onHubChanged.next(hub);
              resolve(response);
            })
            .catch((err) => {
              catchError(this.errorService.handleError('factoryReset_hub'));
            });
        }, reject);
    });
  }

  /**
 * setResetAutoKitting
 *
 *  @returns {Promise<any>}
 */
  resetAutoKitting(hub, id): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http
          .put(env.apiUrl + 'hubs/resetAutoKitting/' + id, hub)
          .subscribe((response: any) => {
              if (response && response.serialNumber) {
                  this.onHubChanged.next(response);
              }
              resolve(response);
          }, reject);
      });
  }

  /**
 * checkResetAKConsumption
 *
 *  @returns {Promise<any>}
 */
  checkResetAKConsumption(hub, hubId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'hubs/checkResetAKConsumption/' + hubId, hub)
        .subscribe((response: any) => {
          if (response && response.serialNumber) {
              this.onHubChanged.next(response);
          }
          resolve(response);
        }, reject);
    });
  }


  /**
 * resetAkDevices
 *
 *  @returns {Promise<any>}
 */
  resetAkDevices(hubId, deviceModelList): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'hubs/resetAkDevices/' + hubId + '/' + deviceModelList, deviceModelList)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

}
