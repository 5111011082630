import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { NoteService } from 'app/shared/service/note.service';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { ModalNoteComponent } from '../modal/note/note.component';
import {LocalStorageService} from '../../../service/local-storage.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],

  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements OnInit {
  dataSource: FilesDataSource | null;
  pageSize;

  @ViewChild(MatPaginator, {static: true})
  paginator: MatPaginator;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild('filter', {static: true})
  filter: ElementRef;

  displayedColumns = ['staff', 'note', 'date', 'time'];

    // Private
    private _unsubscribeAll: Subject<any>;

  constructor(
    private noteService: NoteService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private localStorageService: LocalStorageService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

    /**
     * On init
     */
    ngOnInit(): void
    {
      this.dataSource = new FilesDataSource(this.noteService, this.paginator, this.sort);
      this.getPageSize();
      if (this.filter != null) {
            fromEvent(this.filter.nativeElement, 'keyup')
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    debounceTime(150),
                    distinctUntilChanged()
                )
                .subscribe(() => {
                    if ( !this.dataSource ) {
                        return;
                    }

                    this.filter = this.filter.nativeElement.value;
            });
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    actionModal(note): void{
        this._matDialog.open(ModalNoteComponent, {
            panelClass: 'description-dialog',
            data : {
                pageType: 'edit',
                note: note
            }
        });
    }

  getPageSize(): void {
    this.pageSize = this.localStorageService.get('pageSize');
  }

  setPageSize(pageEvent): void {
    const newPageSize = pageEvent.pageSize;
    this.localStorageService.set('pageSize', newPageSize );
  }
}
  

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {NoteService} noteService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private noteService: NoteService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    )
    {
        super();
        this.filteredData = this.noteService.notes;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this.noteService.onNotesChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                        let data = this.noteService.notes.slice();
                        data = this.filterData(data);

                        this.filteredData = [...data];

                        data = this.sortData(data);

                        // Grab the page's slice of data.
                        const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                        return data.splice(startIndex, this._matPaginator.pageSize);
                    }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[]
    {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                case 'staff':
                    let aName = a.Medical.title+' '+a.Medical.firstName+' '+ a.Medical.lastName;
                    let bName = b.Medical.title+' '+b.Medical.firstName+' '+ b.Medical.lastName;
                    [propertyA, propertyB] = [aName.toLowerCase().trim(), bName.toLowerCase().trim()];
                    break;
                case 'note':
                    [propertyA, propertyB] = [a.note, b.note];
                    break;
                case 'date':
                    [propertyA, propertyB] = [a.createdAt, b.createdAt];
                    break;
                case 'time':
                    [propertyA, propertyB] = [a.createdAt, b.createdAt];
                    break;
                }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}
