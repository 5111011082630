import { BehaviorSubject, Observable, Subject} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class TranslationGlobalService {
  translations;
  translation;
  routeParams: any;
  onTranslationChanged: BehaviorSubject<any>;
  onTranslationsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  id;
  filterBy: any;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.onTranslationChanged = new BehaviorSubject({});
    this.onTranslationsChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.onFilterChanged = new Subject();
  }

  public setTranslation( translations ): void{
    this.translations = translations;
  }

  public getTranslations(): Observable<any>{
    return this.translations;
  }
  public getTranslation(translation): string{
    const result = this.translations.find(element => element.key == translation);
    return result.value;
  }
}
