import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PredefinedAction} from '../model/predefined-action';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {environment as env} from '../../../environments/environment';
import {ConnectorService} from '../auth/connector.service';

@Injectable({
  providedIn: 'root'
})

export class PredefinedActionsService {
  predefinedAction: PredefinedAction;
  predefinedActions: PredefinedAction[];
  routeParams: any;
  onPredefinedActionChanged: BehaviorSubject<any>;
  onPredefinedActionsChanged: BehaviorSubject<any>;
  roleUser;
  UserId;

  constructor(private http: HttpClient, private connectorService: ConnectorService) {
    this.onPredefinedActionChanged = new BehaviorSubject({});
    this.onPredefinedActionsChanged = new BehaviorSubject({});
    this.roleUser = connectorService.getRole();
    this.UserId = connectorService.getUser();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
   resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<any> | Promise<any> | any {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPredefinedAction()]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (this.roleUser === env.roleEdevice){
          Promise.all([this.getPredefinedActions()]).then((response) => {
            resolve(response);
          }, reject);
        } else {
          Promise.all([this.getPredefinedActionsByUser(this.UserId)]).then((response) => {
            resolve(response);
          }, reject);
        }
      });
    }
  }

  // ------------------------------------------------ ActionNotes ---------------------------
  /**
   * Get predefinedAction
   *
   * @returns {Promise<any>}
   */
  getPredefinedActions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'predefinedActions').subscribe((response: any) => {
        this.predefinedActions = response;
        this.onPredefinedActionsChanged.next(this.predefinedActions);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ ActionNote ---------------------------
  /**
   * Get predefinedAction
   *
   * @returns {Promise<any>}
   */
  getPredefinedAction(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onPredefinedActionChanged.next(false);
        resolve(false);
      }
      else {
        this.http.get(env.apiUrl + 'predefinedActions/' + this.routeParams.id)
          .subscribe((response: any) => {
            this.predefinedAction = response;
            this.onPredefinedActionChanged.next(this.predefinedAction);
            resolve(response);
          }, reject);
      }
    });
  }

  getPredefinedActionsByUser(Userid): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'predefinedActions/user/' + Userid)
        .subscribe((response: any) => {
          this.predefinedActions = response;
          this.onPredefinedActionsChanged.next(this.predefinedActions);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Save predefinedAction
   *
   * @param predefinedAction
   * @returns {Promise<any>}
   */
  savePredefinedAction(predefinedAction, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'predefinedActions/edit/' + id, predefinedAction)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }

  /**
   * Add predefinedAction
   *
   * @param predefinedAction
   * @returns {Promise<any>}
   */
  addPredefinedActions(predefinedAction): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'predefinedActions/add', predefinedAction)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({ 
            error: error.error
          })
        });
    });
  }
}
