import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ActionService } from '../../../../service/action.service';
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { FuseUtils } from '../../../../../../@fuse/utils';
import { ConnectorService } from '../../../../auth/connector.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionModalComponent } from '../modal/action-modal/action-modal.component';
import { ConfirmComponent } from '../../../../../main/apps/physicians/physician/confirm/confirm.component';

@Component({
  selector: 'app-dash-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ActionComponent implements OnInit, OnChanges {
  dataSource: FilesDataSource | null;
  displayedColumns = [
    'finish',
    'new',
    'patient',
    'birthday',
    'action',
    'actionCategory',
    'primaryPhysician',
    'actions'
  ];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @Input()
  filterByToAction: any;

  tasks: boolean;
  task: boolean;
  outOfNorm: boolean;
  touchTime: boolean;
  normal: boolean;
  transmissionMissing: boolean;
  medicalStaff: boolean;

  currentUser: any;
  filterBy: any;
  newFilterBy: any;

  @Output() new = new EventEmitter<string>();
  @Output() myTasks = new EventEmitter<boolean>();
  @Output() isNew = new EventEmitter<boolean>();

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private actionService: ActionService,
    private connectorService: ConnectorService,
    private _matDialog: MatDialog,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.currentUser = connectorService.getUser();
  }

  ngOnInit(): void {
    this.dataSource = new FilesDataSource(
      this.actionService,
      this.paginator,
      this.sort
    );
    this.tasks = false;
    this.task = false;
    this.outOfNorm = true;
    this.normal = true;
    this.touchTime = true;
    this.transmissionMissing = true;
    this.medicalStaff = true;
    this.filterBy = {
      outOfNorm: this.outOfNorm,
      touchTime: this.touchTime,
      transmissionMissing: this.transmissionMissing,
      medicalStaff: this.medicalStaff,
      normal: this.normal,
      new: this.task,
      myTask: this.tasks,
    }
    this.changeFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterBy = changes.filterByToAction.currentValue;
    this.tasks = this.filterBy.tasks;
    this.task = this.filterBy.task;
    this.outOfNorm = this.filterBy.outOfNorm;
    this.transmissionMissing = this.filterBy.transmissionMissing;
    this.touchTime = this.filterBy.touchTime;
    this.normal = this.filterBy.normal;
    this.medicalStaff = this.filterBy.medicalStaff;
    this.actionService.onFilterChanged.next(this.filterBy);
  }

  changeFilter(value?: boolean): void {
    this.filterBy = {
      outOfNorm: this.outOfNorm,
      touchTime: this.touchTime,
      transmissionMissing: this.transmissionMissing,
      medicalStaff: this.medicalStaff,
      normal: this.normal,
      new: this.task,
      myTask: this.tasks,
    }
    if (this.tasks === true) {
      this.myTasks.emit(value);
    }
    else if (this.task === true || this.outOfNorm === true || this.touchTime === true || this.transmissionMissing === true || this.medicalStaff === true || this.normal === true) {
      this.isNew.emit(true);
    }
    else {
      this.filterBy = 'all';
      this.new.emit('new');
      this.myTasks.emit(value);
      this.isNew.emit(false);
    }
    this.actionService.onFilterChanged.next(this.filterBy);
  }

  /**
   * Modal actionModal
   */
  actionModal(actionType, action): void {
    event.stopPropagation();
    this._matDialog.open(ActionModalComponent, {
      panelClass: 'description-dialog',
      data: {
        actionType: actionType,
        assignTo: action.AssignToId,
        id: action.id,
        patientId: action.PatientId
      }
    });
  }

  /**
   * Modal Confirmation Tack completed
   */
  confirmation(id): void {
    event.stopPropagation();
    this._matDialog.open(ConfirmComponent, {
      panelClass: 'description-dialog',
      data: { id: id, page: 'task-dashboard' }
    });
  }

  ngOnDestroy(): void {
    // reset filter to default value
    this.tasks = false;
    this.task = false;
    this.outOfNorm = true;
    this.normal = true;
    this.touchTime = true;
    this.transmissionMissing = true;
    this.medicalStaff = true;
    this.filterBy = {
      outOfNorm: this.outOfNorm,
      touchTime: this.touchTime,
      transmissionMissing: this.transmissionMissing,
      medicalStaff: this.medicalStaff,
      normal: this.normal,
      new: this.task,
      myTask: this.tasks,
    }
    this.actionService.onFilterChanged.next(this.filterBy);
  }

}

export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {ActionService} actionService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private actionService: ActionService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    this.filteredData = this.actionService.actions;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.actionService.onActionsChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = this.actionService.actions.slice();

        data = this.filterData(data);

        this.filteredData = [...data];

        data = this.sortData(data);

        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';
      let propertyC: number | string = '';
      let propertyD: number | string = '';

      switch (this._matSort.active) {
        case 'new':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'patient':
          const aPatient = a.Patient.firstName + ' ' + a.Patient.lastName;
          const bPatient = b.Patient.firstName + ' ' + b.Patient.lastName;
          [propertyA, propertyB] = [
            aPatient.toLowerCase().trim(),
            bPatient.toLowerCase().trim()
          ];
          break;
        case 'birthday':
          [propertyA, propertyB] = [a.Patient.birthday, b.Patient.birthday];
          break;
        case 'action':
          const aAction = a.action;
          const bAction = b.action;
          [propertyA, propertyB] = [
            aAction.toLowerCase().trim(),
            bAction.toLowerCase().trim()
          ];
          break;
        case 'actionCategory':
          [propertyA, propertyB] = [a.RedFlags, b.RedFlags];
          if (propertyA === propertyB) {
            [propertyC, propertyD] = [a.YellowFlags, b.YellowFlags];
          }
          break;
        case 'primaryPhysician':
          const aPhysician = a.Patient.PatientDetail.PrimaryPhysician.title + ' ' + a.Patient.PatientDetail.PrimaryPhysician.lastName;
          const bPhysician = b.Patient.PatientDetail.PrimaryPhysician.title + ' ' + b.Patient.PatientDetail.PrimaryPhysician.lastName;
          [propertyA, propertyB] = [
            aPhysician.toLowerCase().trim(),
            bPhysician.toLowerCase().trim()
          ];
          break;
        case 'assignedTo':
          const aAssignedTo = a.action.AssignTo?.title + ' ' + a.action.AssignTo?.lastName;
          const bAssignedTo = b.action.AssignTo?.title + ' ' + b.action.AssignTo?.lastName;
          [propertyA, propertyB] = [
            aAssignedTo.toLowerCase().trim(),
            bAssignedTo.toLowerCase().trim()
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      const valueC = isNaN(+propertyC) ? propertyC : +propertyC;
      const valueD = isNaN(+propertyD) ? propertyD : +propertyD;

      return (
        (valueA < valueB ? -1 : 1) *
        (valueC < valueD ? -1 : 1) *
        (this._matSort.direction === 'asc' ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void { }

}