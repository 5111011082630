import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SpecificRight} from '../model/specific-right';
import {BehaviorSubject, Observable } from 'rxjs';
import {environment as env} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SpecificRightService implements Resolve<any>{
  specificRight: SpecificRight;
  specificRights: SpecificRight[] = [];
  routeParams: any;
  onSpecificRightChanged: BehaviorSubject<any>;
  onSpecificRightsChanged: BehaviorSubject<any>;
  id: string;

  constructor(private http: HttpClient, private route: ActivatedRoute ) {
    this.onSpecificRightsChanged = new BehaviorSubject({});
    this.onSpecificRightChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    this.routeParams = '';
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([ this.getSpecificRights() ]).then((response) => {
            resolve(response);
          }, reject );
    });
  }

  // ------------------------------------------------ SpecificRights ---------------------------
  /**
   * Get specificRights
   *
   * @returns {Promise<any>}
   */
  getSpecificRights(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      const url = env.apiUrl + 'specificRights/' + this.routeParams.id;
      this.http.get(url)
      .subscribe((response: any) => {
      // Check if user has specific Right
      if (response.length > 0) {
        this.filterData(response);
      }
      this.onSpecificRightsChanged.next(this.specificRights);
      resolve(this.specificRights);
      }, reject);
    });
  }

  // ------------------------------------------------ SpecificRight ---------------------------
  /**
   * Get specificRight
   *
   * @returns {Promise<any>}
   */
  getSpecificRight(): Promise<any>
  {
    return new Promise((resolve, reject) => {
        this.http.get(env.apiUrl + 'specificRights/' + this.routeParams.id)
            .subscribe((response: any) => {
              this.specificRight = response;
              this.onSpecificRightChanged.next(this.specificRight);
              resolve(response);
            }, reject);
    });
  }
  
  /**
   * Save specificRight
   *
   * @param specificRight
   * @returns {Promise<any>}
   */
  saveSpecificRight(specificRight): Promise<any>
  {
      return new Promise((resolve, reject) => {
      this.http.put(env.apiUrl + 'specificRights/edit/', specificRight)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
    });
  }
  /**
   * Add specificRight
   *
   * @param specificRight
   * @returns {Promise<any>}
   */
  addSpecificRight(specificRight): Promise<any>
  {
    return new Promise((resolve, reject) => {
      this.http.post(env.apiUrl + 'specificRights/add', specificRight)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
    });
  }

  /**
   * Transform the data and filter by customer and by role 
   */
  filterData(specificRights): void{
    // Transform data to be readable in component
    this.specificRights =  specificRights.map( specificRight => (
      {
        route: specificRight.route,
        description: specificRight.Route.description,
        UserId: specificRight.UserId,
        authorization: JSON.parse(specificRight.authorization)
      })
      
    );
  }

}
