// Angular
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

// Models
import { Note } from 'app/shared/model/note';

// Services
import { NoteService } from 'app/shared/service/note.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})

export class ModalNoteComponent implements OnInit, OnDestroy {

  note: Note;
  pageType: string = '';
  noteForm: UntypedFormGroup;
  id: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<ModalNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noteService: NoteService,
    private _formBuilder: UntypedFormBuilder,
    private _matSnackBar: MatSnackBar,
  ) {

    // Set the default
    this.note = new Note();

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.id = data.UserId;
    this.pageType = data.pageType;
    if (this.pageType === 'edit') {
      this.note = data.note;
    }
  }

// -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit(): void
  {
    this.noteForm = this.createNoteForm();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    delete this.note;
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create note form
   *
   * @returns {FormGroup}
   */
  createNoteForm(): UntypedFormGroup
  {
    return this._formBuilder.group({
      note : [this.note.note, [Validators.required, Validators.pattern('[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð \',.-]*')]],
      medical: [this.note.medical]
    });
  }
  /**
   * Save note
   */
  saveNote(): void
  {
    const data = this.noteForm.getRawValue();

    data.UserId = this.note.UserId;
    this.noteService.saveNote(data, this.note.id)
        .then(() => {

          // Trigger the subscription with new data
          this.noteService.onNoteChanged.next(data);

          // Show the success message
          this._matSnackBar.open('Note saved', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });

          this.matDialogRef.close();

        });
  }
  /**
   * Add note
   */
  addNote(): void
  {
    const data = this.noteForm.getRawValue();
    data.UserId = this.id;
    this.noteService.addNote(data)
        .then(() => {

          // Trigger the subscription with new data
          this.noteService.onNoteChanged.next(data);

          // Show the success message
          this._matSnackBar.open('Note added', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });
          this.matDialogRef.close();
        });
  }

  get notee() {
    return this.noteForm.get('note');
  }

}
