import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ThresholdService } from 'app/shared/service/threshold.service';
import { fuseAnimations } from '@fuse/animations';
import { SeverityService } from '../../../service/severity.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionCategoryService } from '../../../service/action-category.service';
import { PredefinedActionsService } from '../../../service/predefined-actions.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { DefaultThreshold } from 'app/shared/model/default-threshold';
import { environment as env } from '../../../../../environments/environment';
import { ConfirmComponent } from '../../../../main/apps/physicians/physician/confirm/confirm.component';


@Component({
  selector: 'app-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.scss'],

  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ThresholdComponent {
  @Input() thresholdsList;
  @Input() currentUser: string;
  @Input() patientId: string;

  severities = [];
  actionCategories = [];
  predefinedActions: [];

  threshold: DefaultThreshold;
  thresholdForm: FormGroup;

  displaySaveBtn = false;
  displayEditBtn = false;
  displayCreateBtn = false;
  displayCollapseBtn = false;
  displayAddThresholdBtn = false;
  isExpanded = false;
  displayDeleteBtn = false;
  displayAddBtn = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _matDialog: MatDialog,
    private thresholdService: ThresholdService,
    private severityService: SeverityService,
    private actionCategoryService: ActionCategoryService,
    private predefinedActionsService: PredefinedActionsService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getSeverities();
    this.getActionCategory();
    this.getPredefinedAction(this.currentUser);
    this.thresholdForm = this.createDefaultThresholdForm();
    this.displayDefaultThreshold();

    this.thresholdForm.disable();
  }

  createDefaultThresholdForm(): FormGroup {
    return this._formBuilder.group({
      thresholds: this._formBuilder.array([])
    });
  }

  displayDefaultThreshold() {
    if (this.thresholdsList.Thresholds.length != 0) {
      this.thresholdsList.Thresholds.map(threshold => {
        this.thresholds().push(this.prepareForDisplayThreshold(threshold.limitLow, threshold.limitHigh, threshold.SeverityId, threshold.action, threshold.id))
      })
      this.isExpanded = true;
      this.displayEditBtn = true;
      this.displayCollapseBtn = true;

    } else {
      this.createThreshold();
      this.displayCreateBtn = true;
      this.displayCollapseBtn = false;
    }
  }

  enableThresholdCreation(): void {
    this.thresholdForm.enable();
    this.isExpanded = true;
    this.displayCreateBtn = false;
    this.displayAddBtn = true;
    this.displayDeleteBtn = true;
  }

  getSeverities(): void {
    this.severityService.getSeverities().then((arg) => (this.severities = arg));
  }

  getActionCategory(): void {
    this.actionCategoryService.getActionCategories()
      .then(arg => {
        this.actionCategories = arg;
      });
  }

  getPredefinedAction(UserId): void {
    this.predefinedActionsService.getPredefinedActionsByUser(UserId).then((arg) => {
      this.predefinedActions = arg;
    });
  }

  thresholds(): FormArray {
    return this.thresholdForm.get('thresholds') as FormArray;
  }

  prepareForDisplayThreshold(limitLow, limitHigh, SeverityId, action, id) {
    return this._formBuilder.group({
      limitLow: limitLow,
      limitHigh: limitHigh,
      SeverityId: SeverityId,
      action: action,
      id: id
    });
  }

  newThreshold(): FormGroup {
    return this._formBuilder.group({
      limitLow: '',
      limitHigh: '',
      SeverityId: '',
      action: '',
      id: ''
    });
  }

  createThreshold(): void {
    const nbThreshold = 5;
    for (let i = 0; i < nbThreshold; i++) {
      this.thresholds().push(this._formBuilder.group({
        limitLow: '',
        limitHigh: '',
        SeverityId: '',
        action: '',
      }));
    }

    this.thresholds().controls[0].get('SeverityId').setValue(4)
    this.thresholds().controls[1].get('SeverityId').setValue(3)
    this.thresholds().controls[2].get('SeverityId').setValue(2)
    this.thresholds().controls[3].get('SeverityId').setValue(3)
    this.thresholds().controls[4].get('SeverityId').setValue(4)

    // this.isExpanded = false;

    this.toggleDisplayAddThresholdBtn();
  }

  allowEdit(): void {
    this.thresholdForm.enable();
    this.displaySaveBtn = true;
    this.displayEditBtn = false;
    this.displayDeleteBtn = true;
    this.toggleDisplayAddThresholdBtn();

  }

  /**
  * Add threshold
  */
  addThreshold(): void {
    const data = this.thresholdForm.getRawValue();

    let i = 0;
    data.thresholds.forEach((threshold) => {
      if (threshold.action == '') {
        threshold.action = null;
      }
      threshold.ActionCategoryId = env.action_out_of_norm;
      if (threshold.SeverityId == env.severityOk) {
        threshold.ActionCategoryId = env.action_normal;
      }

      threshold.MeasurementTypeId = this.thresholdsList.id;
      threshold.UserId = this.patientId;

      delete threshold.id;

      this.thresholdService
        .addThreshold(threshold)
        .then(() => {
          // Trigger the subscription with new data
          this.thresholdService.onThresholdChanged.next(data);

          i++;
          if (i === data.thresholds.length) {
            // Show the success message
            this._matSnackBar.open('Threshold added', 'OK', {
              verticalPosition: 'top',
              duration: 2000
            });
          }

          // Change the location with new one
          // this.matDialogRef.close();
        })
        .catch((error) => {
          // Show the success message
          this._matSnackBar.open('Threshold error', error.error.message, {
            verticalPosition: 'top',
            duration: 2000
          });
        });
    });
  }

  /**
  * Save Threshold
  */
  saveThreshold(): void {
    const data = this.thresholdForm.getRawValue();

    let i = 0;
    data.thresholds.map(threshold => {
      if (threshold.action == '') {
        threshold.action = null;
      }
      threshold.ActionCategoryId = env.action_out_of_norm;
      if (threshold.SeverityId == env.severityOk) {
        threshold.ActionCategoryId = env.action_normal;
      }

      threshold.MeasurementTypeId = this.thresholdsList.id;
      threshold.UserId = this.patientId;

      this.thresholdService
        .saveThreshold(threshold, threshold.id)
        .then(() => {
          // Trigger the subscription with new data
          // this.thresholdService.onThresholdChanged.next(data);
          i++

          if (i === data.length) {
            this.thresholdService.onThresholdsChanged.next(data);

            // Show the success message
            this._matSnackBar.open('Threshold saved', 'OK', {
              verticalPosition: 'top',
              duration: 2000
            });
          }
          // this.matDialogRef.close();
        })
        .catch((error) => {
          // Show the success message
          this._matSnackBar.open('Threshold error', error.error.message, {
            verticalPosition: 'top',
            duration: 2000
          });
        });
    })

  }


  addNewThreshold(): void {
    this.thresholds().push(this.newThreshold());
    this.toggleDisplayAddThresholdBtn();
  }

  removeNewThreshold(i: number): void {
    this.thresholds().removeAt(i);
    this.toggleDisplayAddThresholdBtn();
  }

  toggleDisplayAddThresholdBtn(): void {
    if (this.thresholds().length == 5) {
      this.displayAddThresholdBtn = false;
    } else {
      this.displayAddThresholdBtn = true;
    }
  }

  /**
 * Modal Confirmation Delete default threshold
 */
  deleteThreshold(): void {
    event.stopPropagation();
    this._matDialog.open(ConfirmComponent, {
      panelClass: 'description-dialog',
      data: { id: this.thresholdsList.id, page: 'threshold', thresholds: this.thresholdsList }
    });
  }

}