import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StatService } from '../../../service/stat.service';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-stat-histo-chart-dashboard',
  templateUrl: './stat-histo-chart-dashboard.component.html',
  styleUrls: ['./stat-histo-chart-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class StatHistoChartDashboardComponent implements OnInit {
  @Input() barPattern: string;
  stats: [];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Days';
  showYAxisLabel = false;
  yAxisLabel = 'Time';
  colorScheme = {};
  displayMessage: boolean;

  durations = [
    { name: 'today', value: 1 },
    { name: 'last 7 days', value: 7 },
    { name: 'last 15 days', value: 15 },
    { name: 'last 30 days', value: 30 },
    { name: 'current month', value: 99 }
  ];
  selectedDuration = { name: 'today', value: 1 };
  form: UntypedFormGroup;

  constructor(private statService: StatService, private _matSnackBar: MatSnackBar) {
    this.form = new UntypedFormGroup({
      selectedDuration: new UntypedFormControl(this.selectedDuration)
    });
  }
  ngOnInit(): void {
    this.displayMessage = false;
    this.getstatsTimeByPatient(this.selectedDuration.value);
  }

  getstatsTimeByPatient(days: number): void {
    switch (this.barPattern) { 
      case 'Cumulative time spent with patients/day for all physicians':
        this.statService
          .getstatsTimeByPatientForAllPhysicians(days)
          .then((response) => {
            this.stats = response.map((stat) => ({
              name: stat.dateTime,
              value: stat.count
            }));
            const domainItems = [];
            this.stats.forEach(() => {
              domainItems.push('#2986C4');
            });
            this.colorScheme = { domain: domainItems };
          })
          .catch((error) => {
              this._matSnackBar.open('Cumulative time spent with patients/day for all physicians : ' + error.statusText, 'OK', {
                  verticalPosition: 'top',
                  duration: 2000
              });
          });
        break;
      case 'Hub usage':
        this.statService
          .getStatsGlobalHubsUsage(days)
          .then((response) => {
            this.stats = response;
            this.colorScheme = { domain: ['#4646d2', '#d6d6f5'] };
          })
          .catch((error) => {
              this._matSnackBar.open('Hub usage : ' + error.statusText, 'OK', {
                  verticalPosition: 'top',
                  duration: 2000
              });
          });
        break;
      default:
    }
  }

  getDuration(duration: any): void {
    this.getstatsTimeByPatient(duration.value);
  }

  compareWith(item: any, selected: any): boolean {
    if (item.name === '') {
      return false;
    }
    return item.name == selected.name;
  }

  onSelect(data): void {}
}
