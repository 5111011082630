import { Injectable } from '@angular/core';
import {Severity} from '../model/severity';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {environment as env} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SeverityService implements Resolve<any>{
  severity: Severity;
  severities: Severity[];
  routeParams: any;
  onSeverityChanged: BehaviorSubject<any>;
  onSeveritiesChanged: BehaviorSubject<any>;

  constructor( private http: HttpClient ) {
    this.onSeveritiesChanged = new BehaviorSubject({});
    this.onSeverityChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new'){
      return new Promise((resolve, reject) => {
        Promise.all([this.getSeverity()]).then((response) => {
              resolve(response);
            }, reject );
      });
    } else {
      return new Promise((resolve, reject) => {
        Promise.all([this.getSeverities()]).then((response) => {
              resolve(response);
            }, reject );
      });
    }
  }

  // ------------------------------------------------ Severities ---------------------------
  /**a
   * Get severities
   *
   * @returns {Promise<any>}
   */
  getSeverities(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'severities')
          .subscribe((response: any) => {
            this.severities = response;
            this.onSeveritiesChanged.next(this.severities);
            resolve(response);
          }, reject);
    });
  }

  // ------------------------------------------------ Severity ---------------------------
  /**
   * Get severity
   *
   * @returns {Promise<any>}
   */
  getSeverity(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      if ( this.routeParams.id === 'new' ) {
        this.onSeverityChanged.next(false);
        resolve(false);
      } else {
        this.http.get(env.apiUrl + 'severities/' + this.routeParams.id)
            .subscribe((response: any) => {
              this.severity = response;
              this.onSeverityChanged.next(this.severity);
              resolve(response);
            }, reject);
      }
    });
  }

  /**
   * Save severity
   *
   * @param severity
   * @param id
   * @returns {Promise<any>}
   */
  saveSeverity(severity, id): Promise<any>
  {
    return new Promise((resolve, reject) => {
      this.http.put(env.apiUrl + 'severities/edit/' + id, severity)
          .subscribe((response: any) => {
            resolve(response);
          }, (error) => {                              //Error callback
            resolve({ 
              error: error.error
            })
          });
    });
  }

  /**
   * Add severity
   *
   * @param severity
   * @returns {Promise<any>}
   */
  addSeverity(severity): Promise<any>
  {
    return new Promise((resolve, reject) => {
      this.http.post(env.apiUrl + 'severities/add', severity)
          .subscribe((response: any) => {
            resolve(response);
          }, (error) => {                              //Error callback
            resolve({ 
              error: error.error
            })
          });
    });
  }

}
