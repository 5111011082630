import { TranslationGlobalService } from '../service/translationGlobal.service';
import { FuseNavigationService } from './../../../@fuse/components/navigation/navigation.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap, filter } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ErrorService } from '../../shared/service/error.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import {UserService} from '../service/user.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {
  private _connected: Subject<boolean> = new Subject<boolean>();
  private _user;
  _fullUser = new BehaviorSubject<any>({});
  getFullUser = this._fullUser.asObservable();
  private _authorization;
  private _navigation;
  public userName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  roleId;

  private _customFunctionNavItem = {
    id: 'customize',
    title: 'Customize',
    type: 'item',
    icon: 'settings',
    function: () => {
      this.toggleSidebarOpen('themeOptionsPanel');
    }
  };

  constructor(
    private http: HttpClient,
    private _fuseSidebarService: FuseSidebarService,
    private errorService: ErrorService,
    private router: Router,
    private _fuseNavigationService: FuseNavigationService,
    private _translationService: TranslationGlobalService,
    private userService: UserService
  ) {
    this.isLoggedIn();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public login(params): Observable<any> {
    return this.http
      .post(env.apiUrl + 'users/login', params, { responseType: 'json' })
      .pipe(
        tap((data) => {
          const result: any = data;
          if (result.token != null || result.token != undefined) {
            this._user = result.user;
            this._fullUser.next(result.user);
            this.roleId = result.user.Role.id;
            this.userName.next(
              result.user.firstName + ' ' + result.user.lastName
            );
            localStorage.setItem('currentUser', this._user.id);
            localStorage.setItem('role', this._user.Role.id);
            localStorage.setItem('weight', this._user.prefWeightUnit);
            localStorage.setItem('temperature', this._user.prefTempUnit);
            localStorage.setItem('customer', this._user.CustomerId);
            this._authorization = result.authorization;
            this._navigation = result.navigation;
            this._navigation.push(this._customFunctionNavItem);
            //  Register the navigation to the service
            this._fuseNavigationService.register('main', this._navigation);
            
            // Fill the translation
            this._translationService.setTranslation(result.translation);
            this._fuseNavigationService.setCurrentNavigation('main');
            this._connected.next(true);
          }
        }),
        catchError(err => err.message)
      );
  }

  public postRequest(url, params): Observable<any> {
    return this.http.post(url, {
      params: params
    });
  }

  public isLoggedIn() {
    if (localStorage.getItem('auth')) {
      this.getUserConnected().subscribe((user) => {
        this._user = user;
        this._fullUser.next(user.user);
        this.roleId = user.user.Role.id;
        this.userName.next(user.user.firstName + ' ' + user.user.lastName);
        localStorage.setItem('currentUser', user.user.id);

        localStorage.setItem('role', user.user.Role.id);
        localStorage.setItem('weight', this._user.user.prefWeightUnit);
        localStorage.setItem('temperature', this._user.user.prefTempUnit);
        localStorage.setItem('customer', this._user.user.CustomerId);

        this._authorization = user.authorization;
        this._navigation = user.navigation;
        this._navigation.push(this._customFunctionNavItem);
        this._fuseNavigationService.register('main', this._navigation);

        // Fill the translation
        this._translationService.setTranslation(user.translation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');
        this._connected.next(true);
        return true;
      });
      this._connected.next(false);
      return false;
    }
  }

  public refreshToken() {
    return this.http.get<any>(env.apiUrl + 'users/renew-token').pipe(
      tap((user) => {
        this._user = user;
        this._fullUser.next(user.user);
        this.roleId = user.user.RoleId;
        this.userName.next(user.user.firstName + ' ' + user.user.lastName);
        localStorage.setItem('currentUser', user.user.id);
        localStorage.setItem('role', user.user.RoleId);
        localStorage.setItem('auth', this._user.token);
        localStorage.setItem('refresh', this._user.refresh); 
        localStorage.setItem('weight', this._user.user.prefWeightUnit);
        localStorage.setItem('temperature', this._user.user.prefTempUnit);
        localStorage.setItem('customer', this._user.CustomerId);

        return user;
      }),
      catchError(this.errorService.handleError('renew_token()'))
    );
  } 
  
  public logout() {
    this.userService.logout();
    this._fuseNavigationService.unregister('main');
    localStorage.removeItem('auth');
    localStorage.removeItem('refresh'); 
    localStorage.removeItem('pageSize'); 
    localStorage.removeItem('nav');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('role');
    localStorage.removeItem('weight');
    localStorage.removeItem('temperature');
    localStorage.removeItem('customer');
    this._connected.next(false);
    this._fullUser.next({});
    return false;
  }

  // To get all information about user log on
  public getUserConnected(): Observable<any> {
    return this.http.get<any>(env.apiUrl + 'users/token').pipe(
      tap(data => {
        return data;
      }),
      catchError(this.errorService.handleError('getUserConnected()'))
    );
  }

  public getUser(): string {
    return localStorage.getItem('currentUser');
  }
  
  public getRole(): number {
    return parseInt(localStorage.getItem('role'));
  }
  
  public getTemperature(): string {
    return localStorage.getItem('temperature');
  }

  public getWeight(): string {
    return localStorage.getItem('weight');
  }

  // Get the nav with the specific route to this user
  public getNav(): void {
    return this._authorization;
  }

  public asRefreshToken() { 
    if(localStorage.getItem('refresh')){ 
      return true 
    } else { 
      return false 
    } 
  } 

  public refreshUserToken() { 
    const refreshToken = localStorage.getItem('refresh'); 
    return this.http.post<any>(env.apiUrl + 'users/refreshToken', {'refresh': refreshToken}).pipe( 
      tap((token) => { 
        localStorage.setItem('auth', token.token); 
        localStorage.setItem('refresh', token.refresh); 
      }) 
    ) 
  } 

  // Get the authorization about one route and allow to hide /show delete button
  public getAuthorization(route): Object {
    let singleAuthorization = this._authorization.filter(authorization => authorization.id == route)
    if (singleAuthorization.length > 0) {
      return JSON.parse(singleAuthorization[0].rules);
    } else {
      singleAuthorization = [];
      return singleAuthorization;
    }
  }

  // Get the right with the specific route to this user
  public getRight(route): Observable<any> {
    return this.http
      .get<any>(env.apiUrl + 'authorizations/specific/' + route)
      .pipe(
        tap(
          (data) => data,
          catchError(this.errorService.handleError<any>('getRight'))
        )
      );
  }

  /**
   * Send email to user after loosing his password
   *
   * @params email
   * @returns {Observable<any>}
   */
  sendPassword(email): Observable<any> {
    return this.http
      .post<any>(env.apiUrl + 'users/forgot-password', { email: email })
      .pipe(
        tap((data) => {
          return data;
        }),
        catchError(this.errorService.handleError('sendPassword()'))
      );
  }

  /**
   * Send new password
   * Sign in
   * @params user id
   * @returns {Observable<any>}
   */
  newPassword(id, password): Observable<any> {
    return this.http
      .put<any>(env.apiUrl + 'users/password/' + id, password)
      .pipe(
        tap((data) => {
          return data;
        }),
        catchError(this.errorService.handleError('sendPassword()'))
      );
  }

  /**
   * Get twofactor Info for a user
   *
   * @returns {Promise<any>}
   */
  getTwofactorInfo(userId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'twofactors/user/' + userId)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Generate a code for a user
   *
   * @returns {Promise<any>}
   */
  twofactorGenerateCode(method: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'twofactors/generateCode/' + method)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Verify if resetting password is authorized
   *
   * @returns {Promise<any>}
   */
  checkResetPassword(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'users/verifyReset/' + id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

}

interface Result {
  message: string;
  token: string;
}
