import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { History } from '../../../../model/history';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryService } from '../../../../service/history.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { ConnectorService } from '../../../../auth/connector.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ModalHistoryComponent implements OnInit {
  history: History;
  pageType = '';
  historyForm: UntypedFormGroup;
  id: string;
  duration;
  MedicalStaffId: string;

  constructor(
    public matDialogRef: MatDialogRef<ModalHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private historyService: HistoryService,
    private _formBuilder: UntypedFormBuilder,
    private _matSnackBar: MatSnackBar,
    private _location: Location,
    private connectorService: ConnectorService,
    private route: ActivatedRoute,
  ) {
    this.history = new History();
    this.pageType = data.pageType;
    if (this.pageType === 'edit') {
      this.history = data.history;
    }

    this.MedicalStaffId = this.connectorService.getUser();
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }



  ngOnInit(): void {
    this.historyForm = this.createHistoryForm();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  createHistoryForm(): UntypedFormGroup {
    return this._formBuilder.group({
      start: this.history.start,
      end: this.history.end

    });
  }
  calculateDiff(): void {
    const date1 = this.historyForm.value.start;
    const date2 = this.historyForm.value.end;
    const datetime1: any = new Date(date1);
    const datetime2: any = new Date(date2);
    const diffInSeconds: any = Math.round(
      (datetime2.getTime() - datetime1.getTime()) / 1000
    );
    return diffInSeconds;
  }

  /**
   * Add Manual History
   */
  addHistory(): void {
    this.duration = this.calculateDiff();
    const dataform = {
      MedicalStaffId: this.MedicalStaffId,
      UserId: this.data.UserId,
      duration: this.duration,
      mode: 'manual',
      start: new Date(this.historyForm.value.start),
      end: new Date(this.historyForm.value.end)
    };
    if (this.historyForm.value.start < this.historyForm.value.end) {
      this.historyService.stopHistory(dataform).then(() => {
        this.matDialogRef.close();

        this._matSnackBar.open('History added', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
    } else {
      this._matSnackBar.open('Please select a Date end posterior to the Date start', 'KO', {
        verticalPosition: 'top',
        duration: 3000
      });
    }
  }

  /**
   * Save Manual History
   */
  saveHistory(): void {
    this.duration = this.calculateDiff();
    const dataform = {
      MedicalStaffId: this.MedicalStaffId,
      UserId: this.history.UserId,
      duration: this.duration,
      mode: 'manual',
      start: new Date(this.historyForm.value.start),
      end: new Date(this.historyForm.value.end)
    };
    if (this.historyForm.value.start < this.historyForm.value.end) {
      this.historyService.saveHistory(dataform, this.history.id).then(() => {
        this.matDialogRef.close();

        this._matSnackBar.open('History saved', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
    } else {
      this._matSnackBar.open('Please select a Date end posterior to the Date start', 'KO', {
        verticalPosition: 'top',
        duration: 3000
      });
    }
  }


  /**
   * handle remove
   */
  onClickRemoveHistory(historyId: number): void {
    this.historyService.deleteHistory(historyId);
    this.matDialogRef.close();
    this._matSnackBar.open('The history has been deleted', 'OK', {
      verticalPosition: 'top',
      duration: 2000
    });
  }

}
