import { Pipe, PipeTransform } from '@angular/core';

/**
 * used to transform text in plural upon value
 *
 */
@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {
  transform(value: any, text: string): any {
    if (value === 1) {
      return value + ' ' + text;
    } else {
      return value + ' ' + text + 's';
    }
  }
}
