import { HubService } from './hub.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Patient } from '../model/patient';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ConnectorService } from '../auth/connector.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class PatientService implements Resolve<any> {
  patient: Patient;
  patients: any[];
  hubs: any[];
  routeParams: any;
  onPatientChanged: BehaviorSubject<any>;
  onPatientsChanged: BehaviorSubject<any>;
  id;
  onSeverityFilterChanged: Subject<any>;
  onMissingMeasurementFilterChanged: Subject<any>;
  onClinicFilterChanged: Subject<any>;
  filterBySeverity: any;
  filterByMissingMeasurement: any;
  filterByClinic: any;
  userConnected;

  patientLogs: any[] = [];
  onPatientLogsChanged: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private hubService: HubService,
    private connectorService: ConnectorService,
  ) {
    this.onPatientsChanged = new BehaviorSubject({});
    this.onPatientChanged = new BehaviorSubject({});
    this.onPatientLogsChanged = new BehaviorSubject({});
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.userConnected = this.connectorService.getUser();
    this.onSeverityFilterChanged = new Subject();
    this.onMissingMeasurementFilterChanged = new Subject();
    this.onClinicFilterChanged = new Subject();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    // USE state TO HAVE THE CURRENT ROUTE
    this.routeParams = '';
    this.routeParams = route.params;
    if (this.routeParams.hasOwnProperty('id') && this.routeParams.id != 'new') {
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getPatient(this.routeParams.id),
          this.getPatientLogs(this.routeParams.id)
        ]).then((response) => {
          resolve(response);
        }, reject);
      });
    } else if (state.url.includes('patients')) {
      return new Promise((resolve, reject) => {
        Promise.all([this.getPatients()]).then(([files]) => {
          this.onSeverityFilterChanged.subscribe((filter) => {
            this.filterBySeverity = filter;
            this.getPatients();
          });
          this.onClinicFilterChanged.subscribe((filter) => {
            this.filterByClinic = filter;
            this.getPatients();
          });
          this.onMissingMeasurementFilterChanged.subscribe((filter) => {
            this.filterByMissingMeasurement = filter;
            this.getPatients();
          });
          resolve(null);
        }, reject);
      });
    }
  }

  // ------------------------------------------------ Patients ---------------------------
  /**
   * Get patients
   *
   * @returns {Promise<any>}
   */
  getPatients(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(env.apiUrl + 'patients').subscribe((response: any) => {
        this.patients = response;
        // Make the sum of histories duration for the month
        this.patients.map(patient => {
          if (patient.patient.User.Histories.length > 0) {
            const date = new Date();
            const startOfMonthDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
            let totalHistoriesThisMonthSum = 0;
            const lastSeen = patient.patient.User?.Histories[patient.patient.User?.Histories.length - 1]; // complete last history object

            patient.patient.lastHistory = lastSeen;

            patient.patient.User.Histories.forEach((history) => {
              if (history.end >= startOfMonthDate) {
                totalHistoriesThisMonthSum = totalHistoriesThisMonthSum + history.duration;
              }
            });
            patient.patient.User.Histories = totalHistoriesThisMonthSum;
          } else {
            patient.patient.User.Histories = 0;
          }

          patient.lastMeasurements.map(measurement => {
            measurement.dateTimeTaken = moment.utc(measurement.dateTimeTaken).format('MM/DD/YY hh:mm:ss A');
          })
        });
        // Filter the data if we click on authorization on side left
        this.patientFilter();
        this.onPatientsChanged.next(this.patients);
        resolve(response);
      }, reject);
    });
  }

  // ------------------------------------------------ Patient ---------------------------
  /**
   * Get patient
   *
   * @returns {Promise<any>}
   */
  getPatient(id): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onPatientChanged.next(false);
        resolve(false);
      } else {
        this.http
          .get(env.apiUrl + 'patients/' + id)
          .subscribe((response: any) => {
            this.patient = response;
            this.onPatientChanged.next(this.patient);
            resolve(response);
          }, reject);
      }
    });
  }

  /**
   * Save patient
   *
   * @param patient
   * @returns {Promise<any>}
   */
  savePatient(patient, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'patients/edit/' + id, patient)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({
            error: error.error
          })
        });
    });
  }

  /**
   * Attention patient
   *
   * Change the status of patient about FOCUS On him or not
   *
   * @param patient
   * @returns {Promise<any>}
   */
  attentionPatient(patient, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'patients/attention/' + id, patient)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Add patient
   *
   * @param patient
   * @returns {Promise<any>}
   */
  addPatient(patient): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(env.apiUrl + 'patients/add', patient)
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {                              //Error callback
          resolve({
            error: error.error
          })
        });
    });
  }

  /**
   * Get patient logs
   *
   * @returns {Promise<any>}
   */
  getPatientLogs(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'patients/patientLogs/' + id)
        .subscribe((response: any) => {
          this.patientLogs = response;
          this.onPatientLogsChanged.next(this.patientLogs);
          resolve(response);
        }, reject);
    });
  }

  /**
    * Reset demo data
    *
    * @returns {Promise<any>}
    */
  resetDemoData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
      .get(env.apiUrl + 'users/resetDemoData')
      .subscribe((response: any) => {
        this.onPatientsChanged.next(response);
        resolve(response);
      }, reject);
    })
  }

  /**
    * Create demo data
    *
    * @returns {Promise<any>}
    */
   createDemoPatient(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
      .get(env.apiUrl + 'users/createDemoPatient')
      .subscribe((response: any) => {
        this.onPatientsChanged.next(response);
        resolve(response);
      }, reject);
    })
  }

  patientFilter(): void {

    if (this.filterByMissingMeasurement && typeof this.filterByMissingMeasurement === 'string') {
      switch (this.filterByMissingMeasurement) {
        case 'no miss':
          this.patients = this.patients.filter((data) => data?.nbTxOnHubPeriod === (30 - data?.patient?.daysLeft));
          break;
        case 'today':
          this.patients = this.patients.filter((data) => moment.utc(new Date()).format('MM/DD/YY') != moment.utc(data?.patient?.Measurement?.dateTimeTaken).format('MM/DD/YY'));
          break;
        case 'more two':
          this.patients = this.patients.filter((data) => data?.nbTxOnHubPeriod <= 14);
          break;
        case 'less than 16tx':
          this.patients = this.patients.filter((data) => data?.nbTxOnHubPeriod < 16);
          break;
      }
    }

    if (this.filterByClinic && this.filterByClinic === true) {
      this.patients = this.patients.filter((data) => data.patient.PrimaryPhysicianId === this.userConnected);
    }
  }

  unfollowPatient(patient, id, unfollow): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'patients/unfollow/' + id, patient, unfollow)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  resumefollow(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(env.apiUrl + 'patients/follow/' + data, data)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  setUnfollow(status, unfollow): Promise<any> {
    return new Promise((resolve, reject) => {
      this.patient.unfollow = status;
      if (unfollow === 'stop') {
        this.patient.startHubPeriod = null;
        this.patient.endHubPeriod = null;
      }
      if (unfollow === 'break') {
        this.patient.startHubPeriod = new Date();
      }
      this.onPatientChanged.next(this.patient);
      resolve(true);
    });
  };

  getPatientsFollowedByPhysician(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(env.apiUrl + 'patients/today/physician')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
