import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { StatService } from '../../../service/stat.service';
import { fuseAnimations } from '@fuse/animations';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-stat-users-count-dashboard',
  templateUrl: './stat-users-count-dashboard.component.html',
  styleUrls: ['./stat-users-count-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class StatUsersCountDashboardComponent implements OnInit {
  @Input() chartPattern: string;
  numberToDisplay: number;
  constructor(private statService: StatService, private _matSnackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getStatsGlobalUsersDistributions();
  }

  getStatsGlobalUsersDistributions(): void {
    this.statService
      .getStatsGlobalUsersDistributions(this.chartPattern)
      .then((response) => {
        this.numberToDisplay = response.value;
      })
      .catch((error) => {
        this._matSnackBar.open('Cannot retrieve users count', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }
}
