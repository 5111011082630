import { AutofillMonitor } from '@angular/cdk/text-field';

export class History {
  id: number;
  MedicalStaffId: number;
  UserId: number;
  start: any;
  end: any;
  duration: number;
  mode: string;
  createdAt: any;
  updatedAt: any;

  /**
   * Constructor
   *
   * @param history
   */
  constructor(history?) {
    history = history || {};
    this.MedicalStaffId = history.MedicalStaffId || '';
    this.UserId = history.UserId || '';
    this.start = history.modelId || new Date();
    this.end = history.dateStart || null;
    this.duration = history.duration || '';
    this.mode = history.mode || 'auto';
  }
}
